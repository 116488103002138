import {
    Injectable,
    Injector,
    inject,
    signal
} from "@angular/core";
import { EnvironmentInfoService } from "./environment-info.service";
import { WaitDialogService } from "src/app/dialogs";
import { $state } from "app/ajs-upgraded-providers";
import { Subject, map } from "rxjs";

type CoreError = {
    type: string,
    needsRestart: boolean
}

@Injectable({
    providedIn: "root"
})
export class CoreStateService {
    private readonly injector = inject(Injector);

    public static lockUIActions = signal(false);

    public coreError$ = new Subject<CoreError>();

    public coreErrorSubscription = this.coreError$.pipe(
        map((error) => this.coreError(error))
    ).subscribe();

    private coreError (error: CoreError) {
        const waitDialogService = this.injector.get(WaitDialogService);
        const state = this.injector.get($state);

        if(error.needsRestart) {
            localStorage.setItem("resumeState", state.current.name);
            localStorage.setItem("resumeStateParams", JSON.stringify(state.params));

            waitDialogService.openDialog({ data: { message: `ERROR_STATES.${error.type}`, timeout: { target: 10 } } }).finally(() => {
                this.restartApplication();
            });
        }
    }

    public restartApplication () {
        const environmentInfo = this.injector.get(EnvironmentInfoService);
        location.href = environmentInfo.getBaseUrl() + 'index.html';
    };
}