import {
    Component,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges
} from '@angular/core';

import {
    interval,
    map,
    startWith
} from 'rxjs';

import {
    SalesPriceChanges
} from "tilby-models";

import {
    Exit,
    RemovePriceChangeFromSaleItem,
    SaleItemWithMenu,
    TilbyActiveSaleHeaderInfoComponent,
    TilbyShopCartComponent
} from "@tilby/tilby-ui-lib/components/tilby-order";

import {
    ConfigurationManagerService,
    EntityManagerService,
    ScreenOrientationService
} from "src/app/core";

import {
    RootScope,
} from "app/ajs-upgraded-providers";

import {
    OpenDialogsService,
    PendingPrintsDialogService,
    RefundOrRemoveItemDialogStateService
} from "src/app/dialogs";

import {AsyncPipe} from "@angular/common";
import {IAngularEvent} from 'angular';
import {PriceChangeDescriptionObj} from "@tilby/tilby-ui-lib/pipes/tilby-price-change";
import {DevLogger} from 'src/app/shared/dev-logger';

import {
    ActiveSaleService,
    CashregisterStateService,
    SalePrintingUtilsService,
    SaleUpdatesData,
    SaleUtilsService
} from 'src/app/features/cashregister';

import {
    SalesCashregister
} from 'src/app/shared/model/cashregister.model';

import {
    TranslateModule
} from "@ngx-translate/core";

import {
    ActiveSaleHeaderInfoWrapperComponent
} from "../active-sale-header-info-wrapper/active-sale-header-info-wrapper.component";

import {
    MatButtonModule
} from "@angular/material/button";

import {
    SelectSaleDialogService,
} from 'src/app/dialogs/cashregister';

import {
    subscribeInComponent
} from '@tilby/tilby-ui-lib/utilities';

import {
    SaleItemManagerDialogService
} from 'src/app/dialogs/sale-item-manager-dialog';

import {
    TilbyDatePipe
} from '@tilby/tilby-ui-lib/pipes/tilby-date';

@Component({
    selector: 'app-shop-cart-wrapper',
    standalone: true,
    templateUrl: './shop-cart-wrapper.component.html',
    imports: [
        ActiveSaleHeaderInfoWrapperComponent,
        AsyncPipe,
        MatButtonModule,
        TilbyActiveSaleHeaderInfoComponent,
        TilbyDatePipe,
        TilbyShopCartComponent,
        TranslateModule,
    ],
    styleUrls: ['./shop-cart-wrapper.component.scss']
})
export class ShopCartWrapperComponent implements OnChanges,OnDestroy{
    private readonly refundOrRemoveItemDialogStateService = inject(RefundOrRemoveItemDialogStateService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly saleUtilsService = inject(SaleUtilsService);
    protected readonly activeSaleService = inject(ActiveSaleService);
    private readonly rootScope = inject(RootScope);
    private readonly selectSaleDialogService = inject(SelectSaleDialogService);
    protected readonly cashregisterStateService = inject(CashregisterStateService);
    private readonly saleItemManagerDialogService = inject(SaleItemManagerDialogService);
    private readonly salePrintingUtilsService = inject(SalePrintingUtilsService);
    private readonly pendingPrintsDialogService = inject(PendingPrintsDialogService);
    private readonly screenOrientationService = inject(ScreenOrientationService);

    protected exitsArray$=this.cashregisterStateService.exitsArray$;
    protected lastUuidChanged$=this.cashregisterStateService.lastUuidChanged$;
    protected lastUuidSelected$=this.cashregisterStateService.lastUuidSelected$;
    protected clickEnabled=true;
    protected disableExitStatus = false;
    protected sideKeyboard = this.cashregisterStateService.sideKeyboard;

    protected currentTime = interval(1000).pipe(
        map(() => new Date()),
        startWith(new Date())
    );

    @Input({required:true}) saleUpdates!:SaleUpdatesData;

    private destroyRootScopeArray: any[] = [];
    protected ingredientsRemovalAffectsPrice = this.configurationManagerService.getPreference('orders.ingredients_removal_affects_price') || false;
    protected isMobilePortrait = this.screenOrientationService.isMobilePortrait;

    private log(...args:any[]){
        DevLogger.log('SHOP_CART_WRAPPER',...args)
    }

    /**
     * Create exits array putting together all "default exits" and all "custom exits" taken from the sale
     */
    private completeExitsArray(sale?: SalesCashregister){
        const saleItems = sale?.sale_items || [];

        // Exits taken from the configurationManagerService.getPreference('orders.exits')
        const defaultExits = this.exitsArray$.getValue();
        const defaultExitsMap = new Map(defaultExits.map(exit => [exit.nameValue, exit]));

        //Get used exits from sale_items (not present in the default exits)
        const saleItemsExits = [...new Set(saleItems.map(({ exit }) => exit))] // Get unique exits from sale_items
            .filter(exit => exit && !defaultExitsMap.has(exit)) // Remove undefined exits and exits that are in the default exits
            .map(exit => new Exit(exit, false)); // Create exit objects with status

        // Return array of all exits (default and sale_items) sorted
        return [...defaultExits, ...saleItemsExits].sort((a, b) => (a.nameValue || 0) - (b.nameValue || 0));
    }

    private checkSentExits() {
        if(!this.saleUpdates.currentSale) {
            return;
        }

        const exitArray = this.exitsArray$.getValue();
        const sentExits = this.saleUpdates.currentSale.sent_exits || {};

        for(const exit of exitArray) {
            if(exit.nameValue) {
                exit.status = sentExits[exit.nameValue] ? 'sent' : undefined;
            }
        }
    }

    // START - LIFECYCLE
    constructor() {
        this.subscriptions();
    }
    ngOnChanges(changes: SimpleChanges) {
        if(changes.saleUpdates) {
            this.disableExitStatus = changes.saleUpdates?.currentValue.saleMode === 'bill' ? false : true;
            this.onSaleChanges(changes);
        }
    }

    private exitToSet(currentSale:SalesCashregister){
        const defaultExit = parseInt(this.configurationManagerService.getPreference('orders.default_exit') || '');
        const sentExits = Object.keys(currentSale?.sent_exits || {});
        const lastExitSent=this.exitsArray$.getValue().findIndex((exit) => exit.nameValue && exit.nameValue==(sentExits[sentExits.length-1]||0));
        return sentExits.length
            ? this.exitsArray$.getValue()[lastExitSent+1]?.nameValue || defaultExit
            : defaultExit;
    }

    private onSaleChanges(changes: SimpleChanges) {
        const currentSale = changes.saleUpdates?.currentValue.currentSale;
        const previousSale = changes.saleUpdates?.previousValue?.currentSale;

        if (currentSale?.uuid !== previousSale?.uuid) {
            this.cashregisterStateService.resetExitArray();
            const saleExits = this.completeExitsArray(currentSale);

            if (saleExits.length > this.exitsArray$.getValue().length) {
                this.exitsArray$.next(saleExits);
            }

            const exitsToSet=this.exitToSet(currentSale);
            if (exitsToSet && changes.saleUpdates?.currentValue?.currentSale.table_id) {
                this.cashregisterStateService.setExit(new Exit(exitsToSet));
            }

            this.lastUuidChanged$.next(undefined);
            this.lastUuidSelected$.next(undefined);
        }

        this.checkSentExits();
    }

    ngOnDestroy() {
        this.destroyRootScopeArray.forEach(onUnsubscribe=>onUnsubscribe?.());
        this.activeSaleService.resetGroupItemUuid();
    }
    // END - LIFECYCLE
    private subscriptions(){
        this.destroyRootScopeArray=[
            ...this.destroyRootScopeArray,
            this.rootScope.$on('active-sale:saleitem-quantity', async(_event:IAngularEvent, data:{quantity:number}) => {
                const saleItem = this.activeSaleService.getActiveSaleItem();
                this.log('SALE',this.saleUpdates.currentSale)
                if(saleItem) {
                    if(saleItem.quantity > data.quantity) {
                        await this.getItemDeletionReason(saleItem);
                    }

                    this.activeSaleService.changeSaleItemQuantity(saleItem, data.quantity);
                }
            }),
        ];

        subscribeInComponent(ActiveSaleService.activeSaleEvents$, (event) => {
            switch(event.event) {
                case 'item-added':
                    this.lastUuidChanged$.next(event.data.uuid);
                break;
                case 'item-changed':
                    this.lastUuidChanged$.next(event.data.currentSaleItem.uuid);
                break;
            }
        })
    }

    protected enableClick(){
        this.clickEnabled=true;
    }

    // TILBY_SHOP_CART
    protected priceChangeDescriptions: PriceChangeDescriptionObj = {
        discount_fix:{label:'CASHREGISTER.ACTIVE_SALE.DISCOUNT'},
        surcharge_fix:{label:'CASHREGISTER.ACTIVE_SALE.SURCHARGE'},
        discount_perc:{label:'CASHREGISTER.ACTIVE_SALE.DISCOUNT_PERCENTAGE', printParams:true},
        surcharge_perc:{label:'CASHREGISTER.ACTIVE_SALE.SURCHARGE_PERCENTAGE',printParams: true},
        gift:{label:'CASHREGISTER.ACTIVE_SALE.GIFT'},
        disc_perc_nd:{label:''},
};

    protected swipeLeftHandler(modifySaleItem:SaleItemWithMenu) {
        this.clickEnabled=false;
        this.decrementQty(modifySaleItem);
    }
    protected swipeRightHandler(modifySaleItem:SaleItemWithMenu) {
        this.clickEnabled=false;
        this.incrementQty(modifySaleItem);
    }
    protected longPressHandler(sale_item:SaleItemWithMenu) {
        this.clickEnabled=false;
        this.onSaleItemPress(sale_item);
    }
    protected removePriceChangeFromSaleItem({saleItem, priceChange}:RemovePriceChangeFromSaleItem) {
        this.activeSaleService.removePriceChangeFromSaleItem(saleItem,priceChange)
    }
    protected removePriceChangeFromSale(priceChange: SalesPriceChanges & { $partial?: number }) {
        this.activeSaleService.removePriceChangeFromSale(priceChange);
    }
    protected async clickHandler({saleItem,isSelected}:{ saleItem:SaleItemWithMenu,isSelected:boolean }) {
        if(!this.clickEnabled){
            return
        }
        //If we are dealing with a menu item simply re-enable menu mode
        if(saleItem.is_group_item) {
            //Find the related item and enable menu mode if the item doesn't split group components
            if(saleItem.item_id) {
                let relatedItem = await this.entityManagerService.items.fetchOneOffline(saleItem.item_id);

                if(relatedItem?.split_group_components) {
                    this.selectSaleItem(saleItem,isSelected);
                    return;
                }
            }

            this.selectSaleItem(saleItem,isSelected);
            return;
        }
        else this.selectSaleItem(saleItem,isSelected)
    }

    protected lastUuidChangedEmitterHandler(saleItem: SaleItemWithMenu) {
        this.lastUuidChanged$.next(saleItem?.uuid);
    }
    private selectSaleItem(saleItem: SaleItemWithMenu,isSelected:boolean) {
        const itemOfMenu = saleItem?.sale_item_parent_uuid;
        const selectedSaleItem = this.activeSaleService.selectActiveSaleItem(saleItem);
        this.lastUuidChanged$.next(selectedSaleItem?.uuid);
        if(!itemOfMenu){
            this.lastUuidSelected$.next(selectedSaleItem?.uuid);
            this.cashregisterStateService.keyboardIsOpenBySelection$.next(!!selectedSaleItem?.uuid);
        }

        if(selectedSaleItem) {
            this.rootScope.$broadcast('cashregister-showcase:show-keyboard', true);
        }
    };

    private incrementQty(saleItem: SaleItemWithMenu) {
        if (!this.activeSaleService.canChangeSaleItemQuantity(saleItem)) {
            return;
        }

        if (saleItem.quantity % 1 === 0) {
            if (saleItem.quantity === -1) {
                this.activeSaleService.cancelRefundOnSaleItem(saleItem);
            } else {
                this.activeSaleService.editSaleItem(saleItem, { quantity: saleItem.quantity + 1 });
            }
        } else {
            if (saleItem.quantity < 0) {
                this.activeSaleService.cancelRefundOnSaleItem(saleItem);
            }
        }
    };
    private async decrementQty(saleItem: SaleItemWithMenu) {
        if (!this.activeSaleService.canChangeSaleItemQuantity(saleItem)) {
            return;
        }

        //Disable decrement on sale items that are part of a menu that is not being edited
        if(saleItem.sale_item_parent_uuid && saleItem.sale_item_parent_uuid !== this.activeSaleService.groupItemUuid) {
            return;
        }

        if (saleItem.quantity === 1 || saleItem.quantity % 1) {
            const answer = await this.refundOrRemoveItemDialogStateService.openDialog({ data: { enableRefund: saleItem.price !== 0 } });

            switch (answer.action) {
                case 'remove':
                    await this.getItemDeletionReason(saleItem);
                    this.activeSaleService.removeSaleItem(saleItem);
                    break;
                case 'refund':
                    this.activeSaleService.refundSaleItem(saleItem, answer.refundCause!);
                    break;
                default:
                    break;
            }
        } else {
            await this.getItemDeletionReason(saleItem);
            this.activeSaleService.editSaleItem(saleItem, { quantity: saleItem.quantity - 1 });
        }
    };
    private async onSaleItemPress(saleItem:SaleItemWithMenu){
        if(saleItem.is_group_item) {
            if(saleItem.item_id) {
                const relatedItem = await this.entityManagerService.items.fetchOneOffline(saleItem.item_id);

                if(relatedItem?.split_group_components) {
                    return this.removeSaleItem(saleItem);
                }
            }

            this.activeSaleService.groupItemUuid = saleItem.uuid;
        } else if(['deposit_cancellation', 'coupon'].includes(saleItem.type)) {
            return this.removeSaleItem(saleItem);
        } else {
            await this.showSaleItemManager(saleItem);
        }
    };
    private async getItemDeletionReason(saleItem:SaleItemWithMenu){
        if(this.saleUpdates.currentSale && this.configurationManagerService.getSetting('cashregister.delete_item_needs_reason') && saleItem.id && !this.saleUpdates.currentSale?.deleted_items?.[saleItem.uuid]) {
            const reason = await this.saleUtilsService.askReason('delete_item');

            if(!reason) {
                throw 'NO_REASON_SELECTED';
            }

            //TODO: this doesn't work with the transactions
            if(!this.saleUpdates.currentSale.deleted_items) {
                this.saleUpdates.currentSale.deleted_items = {};
            }

            this.saleUpdates.currentSale.deleted_items[saleItem.uuid] = reason;
        }
    };
    private async removeSaleItem(saleItem:SaleItemWithMenu){
        const answer = await this.openDialogsService.openConfirmDialog({data:{messageLabel:'CASHREGISTER.ACTIVE_SALE.REMOVE_ITEM_CONFIRM',messageParams:{ itemName: saleItem.name||'' }, confirmLabel: 'DIALOG.CONFIRM.YES', cancelLabel: 'DIALOG.CONFIRM.NO'}});

        if (answer) {
            if(!['deposit_cancellation', 'coupon'].includes(saleItem.type)) {
                await this.getItemDeletionReason(saleItem);
            }

            this.activeSaleService.removeSaleItem(saleItem);
        }
    };
    private async showSaleItemManager (saleItem:SaleItemWithMenu){
        this.activeSaleService.lockPaymentButtons.set(true);

        try {

            const answer = await this.saleItemManagerDialogService.openDialog({rowItem: saleItem});

            if (!answer ) {
                return;
            }

            //If the quantity is 0 and there is not an unbundled item, consider this an item deletion
            if(!answer.rowItem.quantity && !answer.unbundledRowItem) {
                return this.removeSaleItem(saleItem);
            }

            if(answer.rowItem.quantity < saleItem.quantity) {
                await this.getItemDeletionReason(saleItem);
            }

            if (answer.unbundledRowItem) {
                this.activeSaleService.addSaleItem(answer.unbundledRowItem);
            }

            this.activeSaleService.editSaleItem(saleItem, answer.rowItem);
        } catch(err) {
        } finally {
            this.activeSaleService.lockPaymentButtons.set(false);
        }
    };

    protected exitChange(exit: Exit) {
        const nextExit = exit.isSelected ? undefined : exit;
        this.cashregisterStateService.setExit(nextExit);
    }

    protected async sendExitToPrinter(exit: Exit) {
        if(exit.status === 'sending') {
            return;
        }

        //Set status to sending (enables the spinner)
        exit.status = 'sending';

        try {
            //Send the exits to the printer
            const results = await this.activeSaleService.sendExitToPrinter(exit);

            //Set the status to the actual print result (disables the spinner)
            const printSuccessful = this.salePrintingUtilsService.getPrintErrors(results).length === 0;

            //Show errors if any
            if(!printSuccessful) {
                this.pendingPrintsDialogService.openDialog();
            } else if (this.configurationManagerService.getPreference('orders.open_tables_after_send') && this.saleUpdates.currentSale?.room_id) {
                await this.activeSaleService.goToTables(this.saleUpdates.currentSale.room_id);
            }
        } finally {
            this.checkSentExits();
        }
    }

    protected async selectSaleAction() {
        const res = await this.selectSaleDialogService.openDialog();

        if (!res || (res.saleId === '') ) {
            return;
        }

        await this.activeSaleService.loadSale(res.saleId);
    }
}
