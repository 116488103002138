@if(principalActionButton && sideKeyboard()){
    <app-cashregister-keyboard
        class="tw-block tw-border-solid tw-border tw-border-gray-200 tw-rounded-lg tw-p-2"
        [principalActionButton]="principalActionButton"
        [fastPaymentsButtons]="fastPaymentsButtons"
        [sale]="sale"
        (addNewItemToSaleEmitter)="addItemToSaleHandler($event)"
        (activateSideKeyboard)="toggleSideKeyboard($event)"
    />
} @else {
    <app-active-sale-action-buttons
        [class.tw-h-[145px]]="!isMobilePortrait() && principalActionButton && !showOnlyPayButton"
        [class.tw-h-[72px]]="!isMobilePortrait() && principalActionButton && showOnlyPayButton"
        [class.tw-h-[114px]]="!isMobilePortrait() && !principalActionButton"
        [class.tw-h-full]="isMobilePortrait()"
        [sale]="sale"
        [isLoading]="isLoading()"
        [layoutActionButtons]="layoutActionButtons"
        [principalActionButton]="principalActionButton"
        [actionButtons]="actionButtons"
        [fastPaymentsButtons]="fastPaymentsButtons"
        [showOnlyPayButton]="showOnlyPayButton"
    />
}


