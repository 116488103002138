import {Component, inject, ViewChild} from '@angular/core';
import {CashregisterKeyboardComponent} from "../components";
import {ActiveSaleService, CashregisterStateService, SaleUtilsService} from "../services";

import { 
    AddNewItemToSaleDialogService,
    AddNewItemToSaleType,
    OpenDialogsService
} from "src/app/dialogs";

import {Items} from "tilby-models";
import {OverridesType} from "../components/cashregister-showcase";
import {restManager, selectSaleItemCombination } from 'app/ajs-upgraded-providers';
import { ConfigurationManagerService, ConnectionService } from 'src/app/core';
import { StockDictionary } from '../cashregister.component';

/**
 * It is a class that has to be extended in order to use the <app-cashregister-keyboard/> features.
 * Using this class you can add new items to the sale using the keyboard associated features (e.g. change price, add discount).
 */
@Component({
  template: '',
  standalone: true
})
export class KeyboardParentToAddNewItemToSaleComponent {
    private readonly addNewItemToSaleDialogService = inject(AddNewItemToSaleDialogService);
    private readonly selectSaleItemCombinationService = inject(selectSaleItemCombination);
    private readonly restManagerService = inject(restManager);
    protected readonly cashregisterStateService = inject(CashregisterStateService);
    protected readonly openDialogsService = inject(OpenDialogsService);
    protected readonly saleUtilsService = inject(SaleUtilsService);
    protected readonly activeSaleService = inject(ActiveSaleService);
    protected readonly configurationManagerService = inject(ConfigurationManagerService);
    protected readonly connectionService= inject(ConnectionService);

    stockDictionary!: StockDictionary;

    protected priceList = this.cashregisterStateService.priceList;

    @ViewChild(CashregisterKeyboardComponent) cashregisterKeyboardComponent?: CashregisterKeyboardComponent;

    private canChangePrice = this.configurationManagerService.isUserPermitted("cashregister.use_price_changes");
    private keypadSetsQuantity = !!this.configurationManagerService.getPreference('cashregister.keyboard.set_quantity');

    private get exitSelectedValue(){
        return this.cashregisterStateService.exitsArray$.getValue().find(({isSelected})=>!!isSelected)?.nameValue || 0;
    }

    protected async addItemToSaleHandler(type: AddNewItemToSaleType){
        this.addNewItem(type);
    }

    protected async addNewItem(type: AddNewItemToSaleType, barcode?: string) {
        const res = await this.addNewItemToSaleDialogService.openDialog({ data: {
            type,
            barcode,
            price: this.cashregisterKeyboardComponent?.getKeypadValueAndReset() || undefined,
        } });

        if (!res) {
            return;
        }

        const { item, quantity } = res;

        if (item.id) {
            this.cashregisterStateService.addNewItemToItems$.next();
        }

        this.addItemToSale(item, undefined, quantity, barcode, { type: type || 'sale' });
    }

    protected async addItemToSale(item: Items, combinationId?: number, quantity?: number, barcode?: string, overrides?: OverridesType, keypadValue = this.cashregisterStateService.keyboardTotalValue(), quantityModifiers = this.cashregisterKeyboardComponent?.keypadItemModifiers) {
        const options : Partial<{addAsNew: boolean}>= {};
        if(typeof overrides !== 'object'){
            overrides = {};
        }
        const result = await this.checkSaleItemBeforeAdd(item, combinationId);

        if(result.combinationId) {
            combinationId = result.combinationId;
        }

        if (!quantity && quantityModifiers?.quantity) {
            quantity = quantityModifiers?.quantity;
        }

        if (this.canChangePrice && quantityModifiers?.price) {
            overrides.price = quantityModifiers?.price;
        }

        if(quantityModifiers?.rows) {
            options.addAsNew = true;
        }

        if (this.keypadSetsQuantity && keypadValue) {
            //Keypad value sets quantity
            quantity = keypadValue;
        } else {
            //Keypad value sets price
            const newPrice = keypadValue;

            if (newPrice && this.canChangePrice) {
                overrides.price = newPrice;
            }
        }

        this.cashregisterKeyboardComponent?.resetKeypadValue();

        if(this.exitSelectedValue){
            overrides.exit = Number(this.exitSelectedValue);
        }

        let saleItem = await this.activeSaleService.addItemToSale(item, this.priceList(), combinationId, quantity, barcode, overrides, options);
        this.saleUtilsService.updateContextMenuEvent.next();

        if(quantityModifiers?.rows) {
            this.activeSaleService.cloneSaleItem(saleItem, quantityModifiers.rows - 1);
        }

        this.cashregisterKeyboardComponent?.resetQuantityModifiers();
    };

    private async checkSaleItemBeforeAdd(item: Items, combinationId?: number) {
        const result: { combinationId?: number } = {};

        //Check item vat and department
        if (!item.vat_perc && !item.department) {
            await this.openDialogsService.openAlertDialog({ data: { messageLabel: 'CASHREGISTER.SHOWCASE.NO_VAT_FOR_ITEM' } });
            throw 'NO_VAT_FOR_ITEM';
        }

        //Ask combination if necessary
        const hasCombinations = item.combinations?.length;

        if (hasCombinations && !combinationId) {
            const comb = await this.selectSaleItemCombinationService.show(item, this.stockDictionary);
            result.combinationId = comb.id;
        }

        //Check manual production
        if (item.stock_type === 'bom' && item.manual_production && this.connectionService.isOnline()) {
            const itemStock = this.stockDictionary?.get(`item_id_${item.id || 0}`) || {};

            if (Number.isFinite(itemStock.stock_quantity) && item.quantity_alert && (itemStock.stock_quantity || 0) < 1) {
                const itemStockBackup = structuredClone(itemStock);
                const multiplier = item.quantity_alert || 1;
                const loadQuantity = Math.ceil(Math.abs(itemStock.stock_quantity || 0) / multiplier) * multiplier;

                const productionMovement = {
                    entry_type: "item",
                    type: "load",
                    quantity: loadQuantity,
                    load_cause: "bom_production",
                    date: new Date().toISOString(),
                    name: item.name,
                    item_id: item.id
                };

                if (this.configurationManagerService.getPreference('cashregister.disable_manual_production_confirm') !== true) {
                    const answer = await this.openDialogsService.openConfirmDialog({ data: { messageLabel: 'CASHREGISTER.SHOWCASE.CONFIRM_MANUAL_PRODUCTION' } });

                    if (!answer) {
                        throw 'CANCELED';
                    }
                }

                Object.assign(itemStock, {
                    available: 'available',
                    stock_quantity: (itemStock.stock_quantity || 0) + loadQuantity
                });

                this.restManagerService.post('stock_movements', productionMovement)
                    .catch((error: any) => Object.assign(itemStock, itemStockBackup));
            }
        }

        return result;
    };

    protected toggleSideKeyboard($event: boolean) {
        this.configurationManagerService.setUserPreference("cashregister.side_keyboard", $event);
        this.cashregisterStateService.sideKeyboardPreference.set($event);
    }

}
