import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  SimpleChanges,
  computed,
  inject,
  signal
} from '@angular/core';

import {
    BaseInputButton,
    GeneralInputButton,
    LongPressInputButton
} from "@tilby/tilby-ui-lib/models";

import {
    CustomForm,
    CustomFormGroup
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";

import {
    ActiveSaleService
} from 'src/app/features/cashregister/services';

import {
    Sales,
    SalesItems
} from 'tilby-models';

import { TotalFormValue } from "../tilby-sale-keypad";
import { TilbyKeypadDisplayComponent } from "../tilby-keypad-display";
import { CashregisterKeypadComponent } from "../cashregister-keypad";
import { TilbyGeneralInputButtonComponent } from "@tilby/tilby-ui-lib/components/tilby-buttons";
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { TilbyGesturesDirective } from '@tilby/tilby-ui-lib/directives/tilby-gestures';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';

import {
    TranslateModule,
    TranslateService
} from '@ngx-translate/core';

@Component({
    selector: 'app-cashregister-side-keyboard',
    standalone: true,
    imports: [
        TilbyKeypadDisplayComponent,
        CashregisterKeypadComponent,
        TilbyGeneralInputButtonComponent,
        CommonModule,
        MatButtonModule,
        TilbyGesturesDirective,
        MatProgressSpinnerModule,
        TilbyCurrencyPipe,
        TranslateModule
    ],
    templateUrl: './cashregister-side-keyboard.component.html',
    styleUrl: './cashregister-side-keyboard.component.scss'
})
export class CashregisterSideKeyboardComponent {
    protected readonly activeSaleService = inject(ActiveSaleService);
    protected readonly translateService = inject(TranslateService);
    protected readonly rendererService = inject(Renderer2);
    protected readonly relementRefService = inject(ElementRef);

    private _principalActionButton?: GeneralInputButton;

    readonly #lastSaleTotal = this.activeSaleService.lastSaleTotal;

    @Input({ required: true }) buttons: GeneralInputButton[] = [];
    @Input({ required: true }) cashValue!: string;
    @Input({ required: true }) fastPaymentsButtons: LongPressInputButton[] = [];
    @Input({ required: true }) form!: CustomFormGroup<CustomForm<TotalFormValue>>;
    @Input({ required: true }) sale: Sales | undefined;
    @Input({ required: true }) toggleMainKeyboardButton!: GeneralInputButton;
    @Input({ required: true }) set principalActionButton(baseButton: BaseInputButton) {
        this._principalActionButton = this.transformBaseButton(baseButton);
    }

    @Input() integerMultiply = false;

    @Output() onFieldChange = new EventEmitter<string>();

    get lastSaleTotal() {
        return this.#lastSaleTotal();
    }

    get principalActionButton(): GeneralInputButton | undefined {
        return this._principalActionButton;
    }

    private transformBaseButton(baseButton: BaseInputButton): GeneralInputButton {
        return {
            ...baseButton,
            icon: undefined,
            class: "tilby-body-background-primary-color tw-h-full tw-w-full tw-rounded-none tw-px-0 tw-py-0",
            label: signal(this.translateService.instant('CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.OTHER')),
            isIt: signal(true)
        };
    }

    protected finalAmount = 0;
    protected totalQuantity = 0;

    protected isLoading = computed(() =>
        this.activeSaleService.printOrderInProgress() ||
        this.activeSaleService.printDocumentInProgress() ||
        this.activeSaleService.paymentInProgress()
    );

    ngOnChanges(simpleChanges: SimpleChanges) {
        const sale = simpleChanges.sale?.currentValue;
        
        this.finalAmount = sale?.final_amount || 0;
        this.totalQuantity = this.getQuantity(sale?.sale_items || []);
        setTimeout(() => this.adjustFontSize(), 10);
    }

    getQuantity(saleItems: SalesItems[]) : number {
        return saleItems.reduce((acc, item) => {
            const quantity = item.quantity;

            if (Number.isInteger(quantity)) {
                return acc + quantity;
            } else {
                return acc + 1;
            }
        }, 0);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        this.adjustFontSize();
    }

    adjustFontSize() {
        const container = this.relementRefService.nativeElement.querySelector('#infoKeypadSide');
        const width = container.offsetWidth;

        const finalAmountAndQuantityDiv = document.getElementById('finalAmountAndQuantityId');
        const finalAmountDiv = document.getElementById('finalAmountId');

        const textLength = finalAmountAndQuantityDiv!.innerText.length;
        const fontSize = this.calculateFontSize(width, textLength);

        this.rendererService.setStyle(finalAmountDiv, 'font-size', fontSize);
    }

    calculateFontSize(containerWidth: number, textLength: number): string {
        const size = 20 + (containerWidth / 10) - (textLength * 1.2) ;

        if(size > 34) {
            return '34px';
        }

        return `${size}px`;
    }
}
