import { CommonModule } from "@angular/common";
import { Component, ElementRef, Injector, Input, ViewChild, computed, inject, runInInjectionContext } from "@angular/core";
import { ScreenOrientationService, ToolbarEventsService } from "src/app/core";
import { ToolbarEventsContextService } from "src/app/core/services/toolbar-events/toolbar-events-context.service";
import { OpenDialogsService } from "src/app/dialogs";
import { $state } from "app/ajs-upgraded-providers";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { AbstractControl, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { Observable } from "rxjs";
import { MatTableModule } from "@angular/material/table";
import { MatInputModule } from "@angular/material/input";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup, TilbyMagicFormComponent, TilbyTextInputAutocompleteComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { RequireMatch, mobileCheck, subscribeInComponent } from "@tilby/tilby-ui-lib/utilities";
import { FileSchema } from "tilby-models";
import { PrimaryKey } from "../../model/file-importer-fields.model";
import * as _ from 'lodash';

type FormMandatoryFields = {
    primaryKey: string,
    overwriteMethod: string
}

@Component({
    selector: 'app-file-importer-fields',
    templateUrl: 'settings-file-importer-fields.component.html',
    styleUrls: ['settings-file-importer-fields.component.scss'],
    standalone: true,
    imports: [CommonModule, MatTableModule, MatInputModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatAutocompleteModule, TranslateModule, TilbyMagicFormComponent, TilbyTextInputAutocompleteComponent],
})
export class FileImporterFieldsComponent {
    @ViewChild('endpointColumnsInputField') endpointColumnsInputField!: ElementRef<HTMLInputElement>;
    @Input() fileConfig: any;
    @Input() fileSchema!: FileSchema;
    private readonly toolbarEventsContextService = inject(ToolbarEventsContextService);
    private readonly screenOrientationService = inject(ScreenOrientationService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly translateService = inject(TranslateService);
    private readonly injector = inject(Injector);
    private readonly state = inject($state);
    toolbarHelperIsVisible: boolean = true;
    typeInsert!: string;
    exampleRow!: { keyCsv: string; value: any; dbField?: any; search?: string, idSearch?: string }[];
    primaryKeys: PrimaryKey = {
        items: [
            {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.NONE'),
                value: 'none'
            },
            {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.ITEMS.ID'),
                value: 'id'
            }, {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.ITEMS.SKU'),
                value: 'sku'
            }, {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.ITEMS.BARCODE'),
                value: 'barcode'
            }, {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.ITEMS.OPTION1_VALUE'),
                value: 'option1_value'
            }, {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.ITEMS.OPTION2_VALUE'),
                value: 'option2_value'
            }
        ],
        customers: [
            {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.NONE'),
                value: 'none'
            },
            { 
                name: this.translateService.instant("FILE_IMPORTER.FIELDS.PRIMARY_KEYS.CUSTOMERS.ID"),
                value: 'id'
            }, { 
                name: this.translateService.instant("FILE_IMPORTER.FIELDS.PRIMARY_KEYS.CUSTOMERS.FIDELITY"),
                value: 'fidelity'
            }, { 
                name: this.translateService.instant("FILE_IMPORTER.FIELDS.PRIMARY_KEYS.CUSTOMERS.EXTERNAL_ID"),
                value: 'external_id'
            }, { 
                name: this.translateService.instant("FILE_IMPORTER.FIELDS.PRIMARY_KEYS.CUSTOMERS.CUSTOM_1"),
                value: 'custom_1'
            }, { 
                name: this.translateService.instant("FILE_IMPORTER.FIELDS.PRIMARY_KEYS.CUSTOMERS.CUSTOM_2"),
                value: 'custom_2'
            }, {
                name: this.translateService.instant("FILE_IMPORTER.FIELDS.PRIMARY_KEYS.CUSTOMERS.EMAIL"),
                value: 'email'
            }, {
                name: this.translateService.instant("FILE_IMPORTER.FIELDS.PRIMARY_KEYS.CUSTOMERS.PHONE"),
                value: 'phone'
            }
        ],
        suppliers: [
            {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.NONE'),
                value: 'none'
            },
            {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.SUPPLIERS.ID'),
                value: 'id'
            }, {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.SUPPLIERS.VAT_CODE'),
                value: 'vat_code'
            }, {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.SUPPLIERS.EMAIL'),
                value: 'email'
            }
        ],
        stock_movements: [
            {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.NONE'),
                value: 'none'
            },
            {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.STOCK_MOVEMENTS.ID'),
                value: 'id'
            }, {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.STOCK_MOVEMENTS.SKU'),
                value: 'sku'
            }, {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.STOCK_MOVEMENTS.BARCODE'),
                value: 'barcode'
            }
        ],
        components: [
            {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.NONE'),
                value: 'none'
            },
            {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.COMPONENTS.NAME'),
                value: 'name'
            },
        ],
        giftcards: [
            {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.NONE'),
                value: 'none'
            },
            {
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.GIFTCARDS.CODE'),
                value: 'code'
            },
        ]
    };
    overwriteMethods = [
        {
            name: this.translateService.instant('FILE_IMPORTER.FIELDS.FULL_OVERWRITE'),
            value: 'overwrite'
        }, {
            name: this.translateService.instant('FILE_IMPORTER.FIELDS.PARTIAL_OVERWRITE'),
            value: 'onlyFields'
        }, {
            name: this.translateService.instant('FILE_IMPORTER.FIELDS.SKIP_EXISTING'),
            value: 'pass'
        }
    ];
    endpointColumns!: { id: string | undefined; name: any; }[]
    filteredOptions!: Observable<{id: string, name: string}[]>;
    columnsById!: _.Dictionary<{ id: string | undefined; name: any; }>;
    displayedColumns: string[] = ['index_column', 'name_column', 'example', 'tilby_value'];
    fileImporterFieldsForm!: CustomFormGroup<any>;
    fileImporterFieldsFormMandatoryFields!: CustomFormGroup<CustomForm<FormMandatoryFields>>;
    fieldsToRemove = ["Tot. Speso", "Scontrino Medio", "Scontrino medio", "Data creazione", "ID utente creazione", "Credito", "Totale speso", "Ultimo aggiornamento", "ID utente ultimo aggiornamento", "Paese"];
    setListChoices: { key: string; value: any; }[] = [];

    ngOnInit(): void {
        if(!Object.keys(this.fileConfig).includes('typeInsert')) {
            this.state.go('app.new.file_importer');
            return;
        }

        this.typeInsert = _.toLower(this.fileConfig.typeInsert) + (_.endsWith(this.fileConfig.typeInsert, 's') ? '' : 's');

        this.exampleRow = _.map(this.fileSchema.rawFirst, function(val, key) { return { keyCsv: key, value: val }; })
        .filter((row) => { return !this.fieldsToRemove.includes(row.keyCsv) });
        
        this.fileSchema.listDbColumns = _.reject(this.fileSchema.listDbColumns, (col) => { return col.name === 'credit' })
        this.fileSchema.listDbColumns = _.reject(this.fileSchema.listDbColumns, (col) => { return col.name === 'country' })

        this.endpointColumns = _(this.fileSchema.listDbColumns).map((column) => {
            return {
                id: column.name,
                name: this.translateService.instant('FILE_IMPORTER.FIELDS.' + _.toUpper(this.typeInsert) + '.' + _.toUpper(column.name))
            };
        }).value();

        if(!this.fileConfig.primaryKey) {
            if(this.typeInsert === 'items') {
                this.fileConfig.primaryKey = 'sku';
            }
        }

        this.columnsById = _.keyBy(this.endpointColumns, 'id');

        if(!_.isEmpty(this.fileConfig.schemaImporting)) {
            let exampleRowByCsvId = _.keyBy(this.exampleRow, 'keyCsv');
    
            _.forEach(this.fileConfig.schemaImporting, (row) => {
                if(exampleRowByCsvId[row.keyCsv] && this.columnsById[row.keyDb]) {
                    _.assign(exampleRowByCsvId[row.keyCsv], {
                        dbField: this.columnsById[row.keyDb],
                        search: this.columnsById[row.keyDb].name
                    });
                }
            });
        } else {
            _.forEach(this.exampleRow, (row) => {
                if(this.columnsById[row.keyCsv]) {
                    row.dbField = this.columnsById[row.keyCsv];
                }
            });
        }
        
        const createToolbarButtons = () => {
            this.toolbarEventsService.moduleTitle.next("NEW_IMPORTER");
            this.toolbarEventsContextService.showToolbarContext$.next(true);
            this.toolbarEventsContextService.buttons$.next({
                barButtons: [
                    {
                        isIt: () => true,
                        isDisable: () => false,
                        name: 'saveFileImporterFieldsForm',
                        icon: () => 'check',
                        click: () =>  this.confirm()
                    }
                ],
                panelButtons: [] 
            });

            this.toolbarEventsContextService.backButton$.next({
                    isIt: () => true,
                    name: "arrow_back",
                    icon: () => "arrow_back",
                    click: () => this.back()
                }
            );
        };

        createToolbarButtons();
        this.filterPrimaryKeys();
        this.createfileImporterFieldsForm();
        this.createMandatoryFieldsForm();
    }

    ngAfterViewInit(): void {
        runInInjectionContext(
            this.injector, 
            () => {
                subscribeInComponent (
                    this.fileImporterFieldsForm.valueChanges,
                    () => {
                        let mappedFields: string[] = [];

                        for(let row of this.exampleRow){
                            if(this.fileImporterFieldsForm.controls[row.keyCsv] && this.fileImporterFieldsForm.controls[row.keyCsv].value){
                                const keyDb = this.setListChoices.filter((choice) => choice.value === this.fileImporterFieldsForm.controls[row.keyCsv].value);
                                if(keyDb.length > 0) mappedFields.push(keyDb[0].key);
                            }
                        }

                        const primaryKeys = this.primaryKeys[this.typeInsert as keyof typeof this.primaryKeys]
                        .map(primaryKey => ({key: primaryKey.name , value:primaryKey.value}))
                        .filter(primaryKey => { 
                            if(mappedFields.includes(primaryKey.value) || primaryKey.value === 'none'){
                                return primaryKey;
                            }

                            const mappedFieldsWithoutNumbers = mappedFields.map(mappedField => mappedField.replace(/\d+/g, ''));
                            if(mappedFieldsWithoutNumbers.includes(primaryKey.value)){
                                return primaryKey;
                            }
                        });

                        this.fileImporterFieldsFormMandatoryFields.controls.primaryKey.customProps.inputChoices = primaryKeys;   
                    }
                );
            }
        )
    }

    isLandscape = computed(() => !!this.screenOrientationService.getOrientation().includes('landscape'));

    isMobile = mobileCheck();

    private createfileImporterFieldsForm(){
        this.setListChoices = this.endpointColumns.map(column => ({key: column.id! , value:column.name})).filter(column => {
            if(Object.values(column)[1] !== ''){
                return column;
            }
        })

        const requiredMatch: { code: string | undefined | any; name: any; }[] = this.endpointColumns.map(column => ({code: column.id, name: column.name}))
        
        this.fileImporterFieldsForm = new CustomFormGroup({});

        this.exampleRow.forEach((n, i) => {
                const getControl = this.createControlGetter();

                this.fileImporterFieldsForm?.addControl(
                    this.getFormControlLabel(n), 
                    new CustomFormControl(n.dbField ? n.dbField.name : '', {validators: [RequireMatch(requiredMatch), (this.fileConfig.typeInsert === 'giftcards' && (n.keyCsv === 'code' || n.keyCsv === 'valid_since' || n.keyCsv === 'valid_until')) || (this.fileConfig.typeInsert === 'components' && n.keyCsv === 'name') ? Validators.required : Validators.nullValidator]}, {...new CustomFormControlProps(), inputType:'autocomplete', inputChoices: this.setListChoices, customActions: {suffix: {icon: 'cancel',  callback: () => {
                    
                        getControl(this.getFormControlLabel(n)).reset();

                        let mappedFields: string[] = [];

                        for(let row of this.exampleRow){
                            if(this.fileImporterFieldsForm.controls[row.keyCsv] && this.fileImporterFieldsForm.controls[row.keyCsv].value){
                                const keyDb = this.setListChoices.filter((choice) => choice.value === this.fileImporterFieldsForm.controls[row.keyCsv].value);
                                if(keyDb.length > 0) mappedFields.push(keyDb[0].key);
                            }
                        }

                        const primaryKeys = this.primaryKeys[this.typeInsert as keyof typeof this.primaryKeys]
                        .map(primaryKey => ({key: primaryKey.name , value:primaryKey.value}))
                        .filter(primaryKey => { 
                            if(mappedFields.includes(primaryKey.value) || primaryKey.value === 'none'){
                                return primaryKey;
                            }
                        })

                        this.fileImporterFieldsFormMandatoryFields.controls.primaryKey.customProps.inputChoices = primaryKeys;

                        if(primaryKeys.length === 1) {
                            this.fileImporterFieldsFormMandatoryFields.controls.primaryKey.setValue(primaryKeys[0].value);
                        }
                    }}}}),
                );
            }
        );

        this.toolbarEventsService.buttons$.next({
            barButtons: [],
            panelButtons: []
        })
    }

    createControlGetter(): (controlName: string) => AbstractControl {
        return (controlName: string): AbstractControl => {
            return this.fileImporterFieldsForm.get(controlName) ?? this.fileImporterFieldsForm.controls[controlName];
        };
    }

    private createMandatoryFieldsForm(){
        let mappedFields: string[] = [];
        for(let row of this.exampleRow){
            if(this.fileImporterFieldsForm.controls[row.keyCsv] && this.fileImporterFieldsForm.controls[row.keyCsv].value){
                const keyDb = this.setListChoices.filter((choice) => choice.value === this.fileImporterFieldsForm.controls[row.keyCsv].value);
                if(keyDb.length > 0) mappedFields.push(keyDb[0].key);
            }
        }

        const primaryKeys = this.primaryKeys[this.typeInsert as keyof typeof this.primaryKeys]
        .map(primaryKey => ({key: primaryKey.name , value:primaryKey.value}))
        .filter(primaryKey => { 
            if(mappedFields.includes(primaryKey.value) || primaryKey.value === 'none'){
                return primaryKey;
            }
        })

        const overwriteMethods = this.overwriteMethods.map(overwrite_methods => ({key: overwrite_methods.name , value:overwrite_methods.value}));
        
        const primaryKey = this.fileConfig.typeInsert === 'giftcards' || this.fileConfig.typeInsert === 'components' ? 'none' : this.fileConfig.primaryKey;

        this.fileImporterFieldsFormMandatoryFields = new CustomFormGroup<CustomForm<FormMandatoryFields>>({
            primaryKey: new CustomFormControl(primaryKey, {validators:[ Validators.required]}, {...new CustomFormControlProps(), inputType:'select', inputChoices: primaryKeys, id: 'primaryKey', label: 'FILE_IMPORTER.FIELDS.PRIMARY_KEY', class:"tw-w-full"}),
            overwriteMethod: new CustomFormControl(this.fileConfig.overwriteMethod, {validators:[ Validators.required]}, {...new CustomFormControlProps(), inputType:'select', inputChoices: overwriteMethods, id: 'overwriteMethod', label: 'FILE_IMPORTER.FIELDS.OVERWRITE_METHOD', class:"tw-w-full"}),
        });
    }

    filterPrimaryKeys () {
        let mappedFields: { [key: string]: boolean } = {};

        let noPrimaryKey = {
            name: this.translateService.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.NONE'),
            value: 'none'
        };
        
        _.forEach(this.exampleRow, (row: any) => {
            if(_.isObject(row.dbField)) {
                mappedFields[row.dbField.id] = true;
            }
        });

        let primaryKeys = _.filter(this.primaryKeys[this.typeInsert as keyof PrimaryKey], (primaryKey: any) => {
            return mappedFields[primaryKey.value];
        });

        primaryKeys.unshift(noPrimaryKey);

        let isCurrentKeyValid = primaryKeys.some((key: any) => key.value === this.fileConfig.primaryKey);

        if(!isCurrentKeyValid) {
            this.fileConfig.primaryKey = 'none';
        }
    }

    visitHelpPage(): void {
        window.open("https://support.tilby.com/hc/it/articles/360017268218", "_blank");
    }

    closeHelper(){
        this.toolbarHelperIsVisible = false;
    }

    confirm = () => {
        if(this.fileImporterFieldsForm.valid && (this.fileImporterFieldsFormMandatoryFieldsPrimaryKey.value !== '' && this.fileImporterFieldsFormMandatoryFieldsOverwriteMethod.value)) {
            let dataToSend = _.cloneDeep(this.fileConfig);
            dataToSend.primaryKey = this.fileImporterFieldsFormMandatoryFieldsPrimaryKey.value;

            dataToSend.schemaImporting = [];

            for(let row of this.exampleRow){
                if(this.fileImporterFieldsForm.controls[row.keyCsv] && this.fileImporterFieldsForm.controls[row.keyCsv].value){
                    dataToSend.schemaImporting.push({
                        keyCsv: row.keyCsv,
                        keyDb: this.setListChoices.filter((choice) => choice.value === this.fileImporterFieldsForm.controls[row.keyCsv].value)[0].key
                    })
                }
            }
            
            let error = this.compareControlsValues();
            if(!error) {
                this.toolbarEventsContextService.showToolbarContext$.next(false);
                this.toolbarEventsService.showButtonsBar$.next(false);
                this.state.go('app.new.file_importer.schedule', {
                    fileConfig: dataToSend,
                    fileSchema: this.fileSchema,
                    id: this.fileConfig.id
                });
            } else {
                this.openDialogsService.openAlertDialog({data: {messageLabel:'FILE_IMPORTER.FIELDS.DUPLICATED_FIELDS'}});
            }
        } else {
            if(!this.fileImporterFieldsFormMandatoryFields.controls.primaryKey.value || !this.fileImporterFieldsFormMandatoryFields.controls.overwriteMethod.value){
                this.fileImporterFieldsFormMandatoryFields.controls.primaryKey.markAllAsTouched();
                this.fileImporterFieldsFormMandatoryFields.controls.overwriteMethod.markAllAsTouched();
            } else {
                this.openDialogsService.openAlertDialog({data: {messageLabel: this.getFormValidationErrors().find((error: any) => (error.keyError === 'required' && error.errorValue)) ? 'FORM_ERRORS.INSERT_REQUIRED_DATA' : 'FORM_ERRORS.THERE_ARE_ERRORS'}});
            }
        }
    };

    compareControlsValues() {
        let values = Object.values(this.fileImporterFieldsFormControls).filter(control => control.value);
        for (let i = 0; i < values.length; i++) {
            for (let j = i + 1; j < values.length; j++) {
                if(values[i].value !== null || values[j].value !== ''){
                    if (values[i].value === values[j].value) {
                        return true;
                    }
                }
            }
        }
    }

    getFormValidationErrors() {
        const errors: any = [];
        Object.keys(this.fileImporterFieldsForm.controls).forEach(key => {
            const controlErrors: ValidationErrors | null = this.fileImporterFieldsForm.get(key) ? this.fileImporterFieldsForm.get(key)!.errors : null;
            if (controlErrors != null && controlErrors.errors) {
                Object.keys(controlErrors).forEach(keyError => {
                    errors.push({
                        keyControl: key,
                        keyError: keyError,
                        errorValue: controlErrors[keyError]
                    })
                });
            }
        });
        return errors;
    }

    back() {
        this.openDialogsService
        .openConfirmDialog({
            data: {
                messageLabel: "FILE_IMPORTER.BACK_CONFIRM",
                confirmLabel: "DIALOG.CUSTOMER_DELETE.CONFIRM",
                cancelLabel: "DIALOG.CUSTOMER_DELETE.CANCEL",
            },
        })
        .then((res) => {
            if (res) {
                this.state.go(`app.new.file_importer`);
                this.toolbarEventsContextService.buttons$.next({
                    panelButtons: [],
                    barButtons: [],
                });
                this.toolbarEventsService.buttons$.next({
                    barButtons: [],
                    panelButtons: [],
                });
                this.toolbarEventsContextService.showToolbarContext$.next(false);
                this.toolbarEventsService.showButtonsBar$.next(false);
            }
        });
    }

    getFormControlLabel(element: {search?: string; keyCsv: string}) {
        return element.keyCsv;
    }

    get fileImporterFieldsFormControls(){
        return this.fileImporterFieldsForm.controls;
    }

    get fileImporterFieldsFormMandatoryFieldsPrimaryKey(){
        return this.fileImporterFieldsFormMandatoryFields.controls.primaryKey;
    }

    get fileImporterFieldsFormMandatoryFieldsOverwriteMethod(){
        return this.fileImporterFieldsFormMandatoryFields.controls.overwriteMethod;
    }
}