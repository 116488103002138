import {Component, Inject, OnInit, ViewChild, computed} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {restManager, RetailForceProvider, RootScope} from 'app/ajs-upgraded-providers';
import _ from 'lodash';
import {
    ConfigurationManagerService,
    EntityManagerService,
    ScreenOrientationService,
    UserActiveSessionManagerService,
} from 'src/app/core';
import {OpenDialogsService} from 'src/app/dialogs';
import {SettingsUmListComponent} from '../../settings-um-list/settings-um-list.component';
import {ShopSettings, ShopUsers} from 'tilby-models';
import {ButtonUmList} from '../../../../models/settings-users-management.model';
import { keyBy } from 'src/app/shared/utils';

@Component({
    selector: 'app-settings-um-users',
    templateUrl: './settings-um-users.component.html',
    styleUrls: ['./settings-um-users.component.scss']
})
export class SettingsUmUsersComponent implements OnInit {

    @ViewChild(SettingsUmListComponent) componentSettingUmList!: SettingsUmListComponent;

    activeTabUser: boolean = true;
    activeTabStaticToken: boolean = false;
    country!: string;
    enabledModules: any;
    enabledUserModules: any = {};
    enabledUserSettings: any = {};
    enabledCashdrawerPermissions: any = {};
    retailForcePreferences: any;
    showData: boolean = false;
    shopOwner: any = {};
    showError: boolean = false;
    showUserDetail: boolean = false;

    users: ShopUsers[] = [];
    allUsers: ShopUsers[] = [];
    userSelected: ShopUsers | undefined;

    openFilter = false;
    filterText = '';
    itemsIcon = 'account_circle';

    isNewCashregister = this.configurationManagerService.isModuleAngular('tables_and_cashregister');

    userSettingsTemplate = {
        "analytics3.enable_analytics": false,
        "analytics3.hide_stored": false,
        "application.functions.settings.enabled": false,
        "application.functions.tables.show_room_totals": false,
        "application.users.permissions.cashregister.preorder": true,
        "application.users.permissions.cashregister.print_invoice": true,
        "application.users.permissions.tables.manage_tables": true,
        "application.users.permissions.cashregister.use_price_changes": true,
        "application.users.permissions.orders.delete_order": true,
        "application.users.permissions.orders.delete_sent_orders": true,
        "application.users.permissions.orders.edit_sent_orders": true,
        "application.users.permissions.cashregister.delete_sale": true,
        "application.users.permissions.cashregister.store_sale": true,
        "application.users.permissions.cashregister.add_new_item": true,
        "application.users.permissions.cashregister.change_item_quantity": true,
        "application.users.permissions.cashregister.delete_item": true,
        "application.users.permissions.cashregister.bill_lock": true,
        "application.users.permissions.user_management.can_change": false,
        "application.users.permissions.show_developer_options": false,
        "application.functions.cashregister.fiscalprinter.can_open_cash_drawer": true,
        "application.functions.cashregister.hide_open_drawer": false,
        "application.functions.fidelity.setup": false,
        "application.settings.price_list": "1",
        "application.settings.retailforce.client_id": "",
        "application.settings.retailforce.terminal_number": "",
        "application.functions.dashboard.show_best_products": false,
    };

    cashdrawerPermissionsTemplate = {
        "see_status": false,
        "add_change": false,
        "add_change_one_cent": false,
        "remove_cash": false,
        "remove_stacker": false,
        "complete_empty": false,
        "give_change": false,
        "close_till": false,
        "see_log": false,
        "zero_coins": false,
        "see_stats": false,
        "maintenance": false
    };

    userModulesTemplate = {
        "dashboard": true,
        "tables": true,
        "cashregister": true,
        "history": true,
        "stock": true,
        "fidelity": true,
        "items": true,
        "customers": true,
        "suppliers": true,
        "printers": true,
        "bookings": true,
        "file_importer": true,
        "prepaid": true,
        "promotions": true,
        "kiosk": true
    };

    priceLists: { value: string, name: string }[] = [];
    cashdrawerPermissions: { name: string, string: string }[] = [];
    userSettings: { name: string, string: string, disabled?: boolean }[] = [];
    modules: { name: string, string: string }[] = [];

    settingUpRetailForce: boolean = false;
    showCashdrawerPermissions: boolean = false;

    hasBack = false;

    isLoading = false;

    constructor(
        @Inject(restManager) private restManagerService: any,
        @Inject(RetailForceProvider) private retailForceProviderService: any,
        private openDialogsService: OpenDialogsService,
        protected configurationManagerService: ConfigurationManagerService,
        @Inject(RootScope) private rootScope: any,
        private userActiveSessionService: UserActiveSessionManagerService,
        private translateService: TranslateService,
        private entityManagerService: EntityManagerService,
        private screenOrientationService: ScreenOrientationService,
    ) {
    }

    ngOnInit() {
        this.country = this.configurationManagerService.getShopCountry();
        this.enabledModules = this.configurationManagerService.getModulesStatus()['shopModules'];
        this.retailForcePreferences = this.configurationManagerService.getShopPreferences('retailforce.');
        this.loadPriceList();
        this.loadCashdrawerPermissions();
        this.loadUserSettings();
        this.loadModules();
        this.initCashdrawSection();
        this.loadUsers();
    }

    protected isLandscape = computed(() => !!this.screenOrientationService.getOrientation().includes('landscape'));

    customButtons: ButtonUmList[] = [
        {icon: 'filter_list', callback: this.button1Callback.bind(this), type: 'filter', enabled: true},
        {icon: 'add', callback: this.button2Callback.bind(this), type: 'add', enabled: true},
    ];

    callUpdateBadgeId() {
        if (this.userSelected?.badge_id) {
            this.openDialogsService.openConfirmDialog({
                data:{
                    messageLabel: 'SETTINGS.USERS_MANAGEMENT.EDIT_ID_BADGE_QUESTION',
                    confirmLabel: 'APPLICATION.CONFIRM_DIALOG.YES',
                    cancelLabel: 'APPLICATION.CONFIRM_DIALOG.NO'
                }
            }).then((res) => res && this.editIdBadge());
        } else {
            this.editIdBadge();
        }
    }

    editIdBadge($event?: Event) {
        const options = {userId: this.userSelected!.user_id};
        this.openDialogsService.editBadgeIdDialog(
            {
                data: {
                    userId: options.userId!.toString()
                }
            }
        ).then((res) => res && this.updateBadgeIdValue(res));
    }

    updateBadgeIdValue(data: { id_badge: string }) {
        this.userSelected!.badge_id = data.id_badge;
    }

    loadPriceList() {
        this.priceLists = [
            {
                value: "1",
                name: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.PRICE_LIST_1")
            },
            {
                value: "2",
                name: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.PRICE_LIST_2")
            },
            {
                value: "3",
                name: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.PRICE_LIST_3")
            },
            {
                value: "4",
                name: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.PRICE_LIST_4")
            },
            {
                value: "5",
                name: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.PRICE_LIST_5")
            },
            {
                value: "6",
                name: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.PRICE_LIST_6")
            },
            {
                value: "7",
                name: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.PRICE_LIST_7")
            },
            {
                value: "8",
                name: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.PRICE_LIST_8")
            },
            {
                value: "9",
                name: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.PRICE_LIST_9")
            },
            {
                value: "10",
                name: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.PRICE_LIST_10")
            }
        ];
    }

    loadCashdrawerPermissions() {
        this.cashdrawerPermissions = [
            {
                name: "see_status",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.CASHDRAWERS.SEE_STATUS")
            },
            {
                name: "add_change",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.CASHDRAWERS.ADD_CHANGE")
            },
            {
                name: "add_change_one_cent",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.CASHDRAWERS.ADD_CHANGE_ONE_CENT")
            },
            {
                name: "remove_cash",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.CASHDRAWERS.REMOVE_CASH")
            },
            {
                name: "remove_stacker",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.CASHDRAWERS.REMOVE_STACKER")
            },
            {
                name: "complete_empty",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.CASHDRAWERS.COMPLETE_EMPTY")
            },
            {
                name: "give_change",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.CASHDRAWERS.GIVE_CHANGE")
            },
            {
                name: "close_till",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.CASHDRAWERS.CLOSE_TILL")
            },
            {
                name: "see_log",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.CASHDRAWERS.SEE_LOG")
            },
            {
                name: "zero_coins",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.CASHDRAWERS.ZERO_COINS")
            },
            {
                name: "see_stats",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.CASHDRAWERS.SEE_STATS")
            },
            {
                name: "maintenance",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.CASHDRAWERS.MAINTENANCE")
            }
        ];
    }

    loadUserSettings() {
        this.userSettings = [
            {
                name: "analytics3.enable_analytics",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.ENABLE_ANALYTICS")
            },
            {
                name: "application.functions.settings.enabled",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.FUNCTIONS_SETTINGS_ENABLED")
            },
            {
                name: "application.functions.tables.show_room_totals",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.FUNCTIONS_TABLES_SHOW_ROOM_TOTALS")
            },
            {
                name: "application.functions.cashregister.fiscalprinter.can_open_cash_drawer",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.FUNCTIONS_OPEN_CASH_DRAWER")
            },
            {
                name: "application.users.permissions.cashregister.preorder",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_CASHREGISTER_PREORDER")
            },
            ...(!this.isNewCashregister ? [{
                name: "application.users.permissions.cashregister.print_invoice",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_CASHREGISTER_PRINT_INVOICE")
            }] : []),
            {
                name: "application.users.permissions.tables.manage_tables",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_TABLES_MANAGE_TABLES")
            },
            {
                name: "application.users.permissions.cashregister.use_price_changes",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_CASHREGISTER_USE_PRICE_CHANGES")
            },
            ...(!this.isNewCashregister ? [{
                name: "application.users.permissions.orders.delete_order",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_ORDERS_DELETE_ORDER")
            }, {
                name: "application.users.permissions.orders.delete_sent_orders",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_ORDERS_DELETE_SENT_ORDERS")
            }, {
                name: "application.users.permissions.orders.edit_sent_orders",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_ORDERS_EDIT_SENT_ORDERS")
            }] : []),
            {
                name: "application.users.permissions.cashregister.delete_sale",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_CASHREGISTER_DELETE_SALE")
            },
            {
                name: "application.users.permissions.cashregister.add_new_item",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_CASHREGISTER_ADD_NEW_ITEM")
            },
            ...(!this.isNewCashregister ? [{
                name: "application.users.permissions.cashregister.change_item_quantity",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_CASHREGISTER_CHANGE_ITEM_QUANTITY")
            }] : []),
            {
                name: "application.users.permissions.cashregister.delete_item",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_CASHREGISTER_DELETE_ITEM")
            },
            {
                name: "application.users.permissions.cashregister.bill_lock",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_CASHREGISTER_BILL_LOCK")
            },
            {
                name: "application.functions.cashregister.hide_open_drawer",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_CASHREGISTER_HIDE_OPEN_DRAWER")
            },
            {
                name: "application.functions.fidelity.setup",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.FUNCTIONS_FIDELITY_SETUP")
            },
            {
                name: "application.users.permissions.user_management.can_change",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_USER_MANAGEMENT_CAN_CHANGE"),
                disabled: true
            },
            {
                name: "application.users.permissions.show_developer_options",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_SHOW_DEVELOPER_OPTIONS"),
            },
            ...(this.isNewCashregister ? [{
                name: "application.users.permissions.cashregister.accept_payments",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_ACCEPT_PAYMENTS")
            }, {
                name: "application.users.permissions.cashregister.accept_cash_payments",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_ACCEPT_CASH_PAYMENTS")
            }, {
                name: "application.users.permissions.cashregister.enable_dailyclosure",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_ENABLE_DAILYCLOSURE")
            }, {
                name: "application.settings.cashregister.use_tables_map_in_portrait",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.SETTINGS_CASHREGISTER_USE_TABLES_MAP_IN_PORTRAIT")
            }] : []),
            {
                name: 'application.functions.dashboard.show_best_products',
                string: this.translateService.instant('SETTINGS.USERS_MANAGEMENT.FUNCTIONS_SHOW_BEST_PRODUCTS')
            },

        ];

        if (['IT', 'MT'].includes(this.country)) {
            this.userSettings.push({
                name: "application.users.permissions.cashregister.store_sale",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.USERS_PERMISSIONS_CASHREGISTER_STORE_SALE")
            }, {
                name: "analytics3.hide_stored",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.HIDE_STORED")
            });
        }
    }

    loadModules() {
        this.modules = [
            {
                name: "dashboard",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.DASHBOARD")
            },
            {
                name: "tables",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.TABLES")
            },
            ...(!this.isNewCashregister ? [{
                name: "orders",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.ORDERS")
            }] : []),
            {
                name: "cashregister",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.CASHREGISTER")
            },
            {
                name: "history",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.HISTORY")
            },
            {
                name: "stock",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.STOCK")
            },
            {
                name: "fidelity",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.FIDELITY")
            },
            {
                name: "items",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.ITEMS")
            },
            {
                name: "customers",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.CUSTOMERS")
            },
            {
                name: "suppliers",
                string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.SUPPLIERS")
            },
            {
              name: "printers",
              string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.PRINTERS")
            },
            {
              name: "bookings",
              string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.BOOKINGS")
            },
            {
              name: "file_importer",
              string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.FILE_IMPORTER")
            },
            {
              name: "file_exporter",
              string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.FILE_EXPORTER")
            },
            {
              name: "prepaid",
              string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.PREPAID")
            },
            {
              name: "promotions",
              string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.PROMOTIONS")
            },
            {
              name: "kiosk",
              string: this.translateService.instant("SETTINGS.USERS_MANAGEMENT.KIOSK")
            }
        ];
    }

    async loadUsers() {

        this.showError = false;

        try {
            await this.restManagerService.getOne("billing_data");

            this.shopOwner = {
                username: this.userActiveSessionService.getSession()?.username
            };
        } catch (error: any) {
            this.shopOwner = error?.data?.error?.message?.owner_data;
        }

        try {
            const result = await this.restManagerService.getList('shop_users');
            this.users = result;
            this.allUsers = result;
            this.users = this.allUsers.filter(item => item.first_name?.toLowerCase().includes(this.componentSettingUmList.filterText.toLowerCase()) || item.last_name?.toLowerCase().includes(this.componentSettingUmList.filterText.toLowerCase()));
            this.isLoading = true;
        } catch (error) {
            this.showError = true;
        }
    }

    backFromUser() {
        this.hasBack = false;
    }

    async onUserSelected(user: ShopUsers) {
        if (!this.isLandscape()) {
            this.hasBack = true;
        }

        if (this.userSelected === user || !user.user_id) {
            return;
        }

        this.enabledUserModules = {};
        this.enabledUserSettings = {};
        this.enabledCashdrawerPermissions = {};
        this.userSelected = user;

        const userSettings = await this.restManagerService.getList(`shop_users/${user.user_id}/user_settings`) as ShopSettings[];
        const settingsMap = keyBy(userSettings, s => s.id);
        const modules = userSettings.filter((setting) => setting.id.startsWith('application.modules.'));

        const clonedUserModulesTemplate = JSON.parse(JSON.stringify(this.isNewCashregister ? this.userModulesTemplate : {...this.userModulesTemplate, 'orders':true}));
        
        Object.keys(clonedUserModulesTemplate).forEach(key => {
            const value = clonedUserModulesTemplate[key];
            if (this.enabledModules[key]) {
                this.enabledUserModules[key] = value;
            } else {
                this.enabledUserModules[key] = false;
            }
        });

        for (const shopModule of modules) {
            const moduleKey = shopModule.id.replace('application.modules.', '');

            if (this.enabledModules[moduleKey]) {
                this.enabledUserModules[moduleKey] = shopModule.value !== "off" ? true : false;
            } else {
                this.enabledUserModules[moduleKey] = false;
            }
        }

        this.enabledUserSettings = {
            ...this.userSettingsTemplate,
            ...(this.isNewCashregister ? {
                "application.users.permissions.cashregister.accept_payments": true,
                "application.users.permissions.cashregister.accept_cash_payments": true,
                "application.users.permissions.cashregister.enable_dailyclosure": true,
                "application.settings.cashregister.use_tables_map_in_portrait": false,
            } : {})
        };

        Object.entries(this.enabledUserSettings).forEach(([key, setting]) => {
            if (settingsMap[key]) {
                switch (typeof (setting)) {
                    case 'boolean':
                        this.enabledUserSettings[key] = settingsMap[key].value === 'on' ? true : false;
                        break;
                    case 'string':
                        this.enabledUserSettings[key] = settingsMap[key].value;
                        break;
                    default:
                        break;
                }
            }
        });

        if (user.username === this.shopOwner.username) {
            this.enabledUserSettings["application.users.permissions.user_management.can_change"] = true;
        }

        this.enabledCashdrawerPermissions =  { ...this.cashdrawerPermissionsTemplate };

        const cashdrawerPermissions = userSettings.filter((setting) => setting.id.startsWith('application.users.permissions.cashdrawers.'));

        for (const permission of cashdrawerPermissions) {
            if (this.enabledCashdrawerPermissions.hasOwnProperty(permission.id.replace('application.users.permissions.cashdrawers.', ''))) {
                this.enabledCashdrawerPermissions[permission.id.replace('application.users.permissions.cashdrawers.', '')] = permission.value === "on" ? true : false;
            }
        }
    }

    initCashdrawSection() {
        this.entityManagerService.paymentMethods.fetchCollectionOffline({payment_method_type_id: 21}).then((methods) => {
            if (methods.length) {
                this.showCashdrawerPermissions = true;
            }
        });
    }

    isRetailForceEnabled = () => (this.retailForcePreferences['retailforce.identification'] && this.retailForcePreferences['retailforce.store_number']);

    canSetupRetailForce = () => (this.enabledUserSettings['application.settings.retailforce.terminal_number'] && !this.retailForcePreferences['retailforce.host']);

    setupRetailForce = async () => {
        if (this.settingUpRetailForce || !this.isRetailForceEnabled() || !this.canSetupRetailForce()) {
            return;
        }

        this.settingUpRetailForce = true;

        try {
            let terminalNumber = this.enabledUserSettings['application.settings.retailforce.terminal_number'];

            let result = await this.retailForceProviderService.configureProvider({
                cloudApiKey: this.retailForcePreferences['retailforce.cloud_api_key'],
                cloudApiSecret: this.retailForcePreferences['retailforce.cloud_api_secret'],
                host: this.retailForcePreferences['retailforce.host'],
                identification: this.retailForcePreferences['retailforce.identification'],
                storeNumber: this.retailForcePreferences['retailforce.store_number'],
                terminalNumber: terminalNumber
            });

            let settingsToSave = [
                {
                    id: 'application.settings.retailforce.terminal_number',
                    value: terminalNumber
                }, {
                    id: 'application.settings.retailforce.client_id',
                    value: result.client_id
                }
            ];

            for (let setting of settingsToSave) {
                await this.sendUserSetting(setting);
            }

            this.enabledUserSettings['application.settings.retailforce.client_id'] = result.client_id;

            this.openDialogsService.openAlertDialog({
                data:{messageLabel: 'SETTINGS.RETAILFORCE.CONFIGURATION_COMPLETE'}
            });
        } catch (err) {
            this.openDialogsService.openAlertDialog({data:{messageLabel: 'SETTINGS.RETAILFORCE.CONFIGURATION_ERROR'}});
        } finally {
            this.settingUpRetailForce = false;
        }
    };

    sendUserSetting = async (setting: ShopSettings) => {
        let user = this.userSelected;
        let result = await this.restManagerService.post(`shop_users/${user?.user_id}/user_settings`, setting);

        this.rootScope.$broadcast('user-setting:changed', {user, setting});
        return result;
    };

    enableModule = (moduleName: string) => {
        this.sendUserSetting({
            id: `application.modules.${moduleName}`,
            value: this.enabledUserModules[moduleName] ? "on" : "off"
        });
    };

    toggleUserSetting = (userSettingName: string) => {
        this.sendUserSetting({
            id: userSettingName,
            value: this.enabledUserSettings[userSettingName] ? "on" : "off"
        });
    };

    toggleCashdrawerPermission = (permissionName: string) => {
        this.sendUserSetting({
            id: `application.users.permissions.cashdrawers.${permissionName}`,
            value: this.enabledCashdrawerPermissions[permissionName] ? "on" : "off"
        });
    };

    changePriceList = () => {
        this.sendUserSetting({
            id: 'application.settings.price_list',
            value: this.enabledUserSettings['application.settings.price_list']
        });
    };

    button1Callback() {
    }

    button2Callback() {
        this.open();
    }

    filter() {
        this.openFilter = !this.openFilter;
    }

    customFilterFunction(item: ShopUsers): boolean {
        return item.first_name?.toLowerCase().includes(this.filterText.toLowerCase()) || item.last_name?.toLowerCase().includes(this.filterText.toLowerCase()) || false
    }

    setUser(user: ShopUsers) {
        this.userSelected = user;
    }

    open() {
        this.openDialogsService.addNewUserDialog().then((res) => res && this.createUser(res))
    }

    async createUser(data: ShopUsers) {
        try {
            await this.restManagerService.post("shop_users", data);
            await this.loadUsers();
            this.onUserSelected(this.users[this.users.length - 1]);
            this.componentSettingUmList.onItemClick(this.users[this.users.length - 1]);
        } catch (err: any) {
            this.openDialogsService.openAlertDialog({data: {messageLabel: 'Errore : ' + err?.data?.error?.message}});
        }

    }

    buildFieldLegend1(item: ShopUsers): string {
        return item.first_name + ' ' + item.last_name;
    }

    buildFieldLegend2(item: ShopUsers): string {
        return item.username || '';
    }

    openDeleteDialog() {
        this.openDialogsService.openConfirmDialog({
            data:{
                messageLabel: 'SETTINGS.USERS_MANAGEMENT.CONFIRM_REMOVE',
                confirmLabel: 'APPLICATION.CONFIRM_DIALOG.YES',
                cancelLabel: 'APPLICATION.CONFIRM_DIALOG.NO'
        }}).then((res) => res? this.callbackDelete():undefined);
    }

    async callbackDelete() {
        await this.restManagerService.deleteOne("shop_users", this.userSelected?.user_id);
        this.loadUsers();
        this.userSelected = undefined;
    }
}
