import * as angular from 'angular';
import * as _ from 'lodash';
import { paymentMethodTypes } from 'src/app/core/constants';

angular.module('digitalPayments').factory('DigitalPaymentsManager', DigitalPaymentsManager);

DigitalPaymentsManager.$inject = ["$translate", "$injector", "entityManager", "environmentInfo"];

function DigitalPaymentsManager($translate, $injector, entityManager, environmentInfo) {

    const digitalPaymentHandlers = {
        11: {
            handler: 'ingenico',
            isOnlineOnly: true,
            hasPrintFailRollback: false,
            platformCheck: (environmentInfo.isMobileApp() && environmentInfo.isAndroid())
        },
        13: {
            handler: 'sumup',
            isOnlineOnly: true,
            hasPrintFailRollback: false,
            platformCheck: (environmentInfo.isMobileApp() && environmentInfo.isAndroid())
        },
        14: {
            handler: 'ingenico17',
            isOnlineOnly: true,
            hasPrintFailRollback: false,
            platformCheck: environmentInfo.canUseTcpSockets()
        },
        15: {
            handler: 'poynt',
            isOnlineOnly: true,
            hasPrintFailRollback: false,
            platformCheck: environmentInfo.isPoynt()
        },
        18: {
            handler: 'satispay',
            isOnlineOnly: true,
            hasPrintFailRollback: true,
            platformCheck: true
        },
        19: {
            handler: 'cashmatic',
            isOnlineOnly: false,
            hasPrintFailRollback: false,
            platformCheck: environmentInfo.canUseTcpSockets(),
            allowsRefund: true
        },
        20: {
            handler: 'eatsready',
            isOnlineOnly: true,
            hasPrintFailRollback: true,
            platformCheck: true
        },
        21: {
            handler: 'cashlogy',
            isOnlineOnly: false,
            hasPrintFailRollback: false,
            platformCheck: environmentInfo.canUseTcpSockets()
        },
        22: {
            handler: 'bedzzle',
            isOnlineOnly: true,
            hasPrintFailRollback: true,
            platformCheck: true
        },
        23: {
            handler: 'beddy',
            isOnlineOnly: true,
            hasPrintFailRollback: true,
            platformCheck: true
        },
        27: {
            handler: 'verifoneOcius',
            isOnlineOnly: true,
            hasPrintFailRollback: false,
            platformCheck: environmentInfo.canUseTcpSockets(),
            allowsRefund: true
        },
        28: {
            handler: 'leanPMS',
            isOnlineOnly: true,
            hasPrintFailRollback: true,
            platformCheck: true
        },
        29: {
            handler: 'spiaggeIt',
            isOnlineOnly: true,
            hasPrintFailRollback: true,
            platformCheck: true
        },
        30: {
            handler: 'NexiMobilePos',
            isOnlineOnly: true,
            hasPrintFailRollback: false,
            platformCheck: environmentInfo.isMobileApp() && environmentInfo.isAndroid()
        },
        31: {
            handler: 'ZvtPos',
            isOnlineOnly: true,
            hasPrintFailRollback: false,
            platformCheck: environmentInfo.canUseTcpSockets()
        },
        32: {
            handler: 'CimaCashdrawer',
            isOnlineOnly: false,
            hasPrintFailRollback: false,
            platformCheck: true
        },
        34: {
            handler: 'Coverflex',
            isOnlineOnly: true,
            hasPrintFailRollback: false,
            platformCheck: environmentInfo.canUseTcpSockets()
        },
        35: {
            handler: 'NexoPOS',
            isOnlineOnly: true,
            hasPrintFailRollback: false,
            platformCheck: environmentInfo.isAndroid()
        },
        36: {
            handler: 'ericsoftPMS',
            isOnlineOnly: false,
            hasPrintFailRollback: true,
            platformCheck: true
        },
        37: {
            handler: 'RedsysPOS',
            isOnlineOnly: true,
            hasPrintFailRollback: false,
            platformCheck: environmentInfo.isElectronApp() && environmentInfo.isWindows()
        },
        38: {
            handler: 'VneCashdrawer',
            isOnlineOnly: false,
            hasPrintFailRollback: true,
            platformCheck: true
        },
        39: {
            handler: 'campgestPMS',
            isOnlineOnly: true,
            hasPrintFailRollback: true,
            platformCheck: true
        },
        40: {
            handler: 'GloryCashdrawer',
            isOnlineOnly: false,
            hasPrintFailRollback: true,
            platformCheck: environmentInfo.isMobileApp() || environmentInfo.isElectronApp()
        },
        41: {
            handler: 'wellbyPMS',
            isOnlineOnly: true,
            hasPrintFailRollback: true,
            platformCheck: true
        }
    };

    const DigitalPaymentsManager = {
        getCahdrawerMethods() {
            const cashMethods = paymentMethodTypes.filter((type) => type.id !== 1 && type.class === 'cash').map(type => type.id);
            const digitalPaymentsIds = new Set(Object.keys(digitalPaymentHandlers).map(Number));

            return cashMethods.filter((id) => digitalPaymentsIds.has(id));
        },
        isPaymentDigital: function isPaymentDigital(methodTypeId) {
            return digitalPaymentHandlers.hasOwnProperty(methodTypeId);
        },
        isPaymentOnlineOnly: function isPaymentOnlineOnly(methodTypeId) {
            return digitalPaymentHandlers[methodTypeId]?.isOnlineOnly ? true : false;
        },
        isPaymentDigitalEnvironmentAllowed: function isPaymentDigitalEnvironmentAllowed(methodTypeId) {
            return digitalPaymentHandlers[methodTypeId]?.platformCheck ? true : false;
        },
        paymentAllowsRefund: function paymentAllowsRefund(methodTypeId) {
            return digitalPaymentHandlers[methodTypeId]?.allowsRefund ? true : false;
        },
        paymentHasPrintFailRollback: function paymentHasPrintFailRollback(methodTypeId) {
            return digitalPaymentHandlers[methodTypeId]?.hasPrintFailRollback ? true : false;
        },
        digitalPayment: async (amount, paymentMethodId, options) => {
            if(!_.isObject(options)) {
                options = {};
            }

            //Get payment method information
            let paymentMethod = await entityManager.paymentMethods.fetchOneOffline(paymentMethodId);

            if (!paymentMethod) {
                throw $translate.instant('DIGITAL_PAYMENTS.MANAGER.NO_CARDS_METHODS');
            }

            options.paymentMethod = _.clone(paymentMethod);

            //Get payment method type
            let paymentMethodType = _.chain(paymentMethodTypes).find({ id: paymentMethod.payment_method_type_id }).cloneDeep().value();

            if(!DigitalPaymentsManager.isPaymentDigitalEnvironmentAllowed(paymentMethodType.id)) {
                throw $translate.instant('CASHREGISTER.PAYMENTS.METHOD_NOT_AVAILABLE_ON_THIS_DEVICE');
            }

            //Get payment handler
            let handlerName = _.get(digitalPaymentHandlers, [paymentMethodType.id, 'handler']);

            if(!handlerName) {
                throw $translate.instant('DIGITAL_PAYMENTS.MANAGER.NOT_FOUND');
            }

            //Get payment handler and determine if we are doing a payment or a refund
            let paymentHandler = $injector.get(handlerName);
            let targetFunction = amount >= 0 ? 'payment' : 'refund';
            let paymentData = {};

            //Stop if we don't have a payment function for this method
            if(!_.isFunction(paymentHandler[targetFunction]) && amount >= 0) {
                throw $translate.instant('DIGITAL_PAYMENTS.MANAGER.METHOD_NOT_SUPPORTED');
            }

            //Execute the payment/refund function if available
            if(_.isFunction(paymentHandler[targetFunction])) {
                paymentData = await paymentHandler[targetFunction](Math.abs(amount), options);
            }

            const paymentMethodMeta = {
                payment_method_id: paymentMethod.id,
                payment_method_name: paymentMethod.name,
                payment_method_type_id: paymentMethodType.id,
                payment_method_type_name: paymentMethodType.name,
            };

            if(Array.isArray(paymentData)) {
                return paymentData.map((pData) => _.defaults(pData, paymentMethodMeta));
            }

            return _.defaults(paymentData, paymentMethodMeta);
        }
    };

    return DigitalPaymentsManager;
}
