import * as moment from 'moment-timezone';
import { validate as validateUuid, v4 as generateUuid } from 'uuid';
import { RefundCauses, STATIC_ELEMENT_PREFIX, paymentMethodTypes } from 'src/app/core/constants';
import {
    ChainPrepaidMovementsRead,
    ChainPrizes,
    Customers,
    Departments,
    Items,
    ItemsCombinations,
    PaymentMethods,
    Printers,
    SaleTransactions,
    Sales,
    SalesCustomer,
    SalesEInvoice,
    SalesItems,
    SalesItemsPriceChanges,
    SalesPayments,
    SalesPriceChanges,
    RoomsTables,
    Rooms
} from 'tilby-models';

import {
    ConfigurationManagerService,
    ConnectionService,
    CoreStateService,
    EntityBase,
    EntityManagerService,
    ScreenOrientationService,
    StorageManagerService
} from 'src/app/core';

import {
    ActiveSaleStoreService,
    CashregisterStateService,
    EditSaleItemUpdate,
    SalePaymentService,
    SalePrintingUtilsService,
    SaleTransactionUtilsService,
    SaleUtilsService
} from 'src/app/features';

import {
    MathUtils
} from '@tilby/tilby-ui-lib/utilities';

import {
    DocumentPrinterOptions
} from 'src/app/shared/model/document-printer.model';

import {
    Subject,
    filter
} from 'rxjs';

import {
    keyBy,
    pickBy,
    removeFromArray
} from 'src/app/shared/utils';

import {
    OrderSendStatus,
    SaleMode,
    SaleType,
    SalesCashregister
} from 'src/app/shared/model/cashregister.model';

import {
    Injectable,
    Injector,
    WritableSignal,
    effect,
    inject,
    signal
} from '@angular/core';

import {
    $state,
    $stateParams,
    digitalPaymentsManager,
    documentPrinter,
    FiscalProviders,
    fiscalUtils,
    giftCardPrinter,
    leanPMS,
    operatorManager,
    prepaidSale,
    restManager,
    saleUtils,
    splitSaleDialog,
    util
} from 'app/ajs-upgraded-providers';

import { TranslateService } from '@ngx-translate/core';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';

import {
    BuzzerInputDialogService,
    DocumentPrintersManagerDialogStateService,
    OpenDialogsService,
    SignaturePadDialogService
} from 'src/app/dialogs';

import { OpenEditSaleDialogFormParams, RoomsStateService } from '../../tables/rooms.state.service';
import { Exit } from '@tilby/tilby-ui-lib/components/tilby-order';
import { DevLogger } from 'src/app/shared/dev-logger';

import {
    SelectSaleDialogService,
} from 'src/app/dialogs/cashregister';

type LoadSaleOptions = OpenSaleOptions & {
    skipOperatorCheck?: boolean
    skipStore?: boolean
}

type OpenSaleOptions = {
    fromParentSale?: boolean,
    skipResetDocumentData?: boolean
}

type NewSaleOptions = {
    dontSaveSale?: boolean
    dontOpenSale?: boolean
    overrides?: Partial<Sales>
}

type FinishSaleClosingOptions = {
    closeActiveSale?: boolean,
    skipParentCheck?: boolean
}

export type StoreSaleOptions = FinishSaleClosingOptions & {
    reason?: string
}

export type DeleteSaleOptions = {
    sale?: Sales,
    reason?: string
    skipClosing?: boolean
}

type EmitDocumentOptions = {
    batchOrderMode?: boolean
}

type EmitDocumentResult = {
    emittedSale: Sales,
    notes?: string[],
} | {
    error: string
} | {
    printError?: string,
    printerId?: number,
}

type lastSaleTotal = {
    final_amount: number,
    change: number,
    id?: number,
    uuid: string
}

type ActiveSaleEvent =
    { event: 'sale-opened', data: Sales | undefined } |
    { event: ('sale-parked' | 'sale-closed'), data: Sales } |
    { event: ('item-removed' | 'item-added'), data: SalesItems } |
    { event: 'item-changed', data: { currentSaleItem: SalesItems, previousSaleItem: SalesItems } } |
    { event: 'customer-added', data: SalesCustomer } |
    { event: ('priceChange-removed' | 'priceChange-added'), data: { target: Sales | SalesItems, priceChange: SalesPriceChanges | SalesItemsPriceChanges, targetType: 'sale' | 'sale_item' } } |
    { event: 'use-pricelist', data: { priceList: number | 'default', skipNotify?: boolean } } |
    { event: 'reset-pricelist' } |
    { event: 'payment-in-progress' } |
    { event: 'go-to-payments' }

@Injectable({
    providedIn: 'root'
})
export class ActiveSaleService {
    private readonly injector = inject(Injector);
    private readonly $state = inject($state);
    private readonly $stateParams = inject($stateParams);
    private readonly activeSaleStore = inject(ActiveSaleStoreService)
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly connection = inject(ConnectionService);
    private readonly DigitalPaymentsManager = inject(digitalPaymentsManager);
    private readonly documentPrinter = inject(documentPrinter);
    private readonly documentPrintersManager = inject(DocumentPrintersManagerDialogStateService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly FiscalProviders = inject(FiscalProviders);
    private readonly fiscalUtils = inject(fiscalUtils);
    private readonly GiftCardPrinter = inject(giftCardPrinter);
    private readonly oldSaleUtils = inject(saleUtils);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly OperatorManager = inject(operatorManager)
    private readonly prepaidSale = inject(prepaidSale);
    private readonly restManager = inject(restManager);
    private readonly roomsStateService = inject(RoomsStateService);
    private readonly LeanPMS = inject(leanPMS);
    private readonly salePayment = inject(SalePaymentService);
    private readonly salePrintingUtils = inject(SalePrintingUtilsService);
    private readonly saleUtilsService = inject(SaleUtilsService);
    private readonly signaturePadDialog = inject(SignaturePadDialogService);
    private readonly splitSaleDialog = inject(splitSaleDialog);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);
    private readonly translateService = inject(TranslateService);
    private readonly util = inject(util);
    private readonly cashregisterStateService = inject(CashregisterStateService);
    private readonly saleTransactionUtilsService = inject(SaleTransactionUtilsService);
    private readonly buzzerInputDialogService = inject(BuzzerInputDialogService);

    constructor(
    ) {
        StorageManagerService.storageUpdates$.pipe(
            filter((data) => data.entityName === 'printers')
        ).subscribe(
            (data) => this.configurationManagerService.isModuleAngular('tables_and_cashregister') && this.onPrinterUpdates(data.entity as Printers)
        );

        EntityBase.offlineFirstCompletion$.pipe(
            filter((data) => data.entityName === 'sales')
        ).subscribe(
            (data) => this.configurationManagerService.isModuleAngular('tables_and_cashregister') && this.printSaleGiftcard(data.entity as Sales)
        )

        ActiveSaleStoreService.saleUpdates$.subscribe((data) => {
            this.currentSale = data.currentSale || {} as SalesCashregister;
            this.hasPaidPayments.set(!!data.hasPaidPayments);
            this.saleType.set(data.saleType);
            this.saleMode.set(data.saleMode);
        });

        ActiveSaleService.printerDocumentDataUpdates$.subscribe((data) => {
            this.printerDocumentData = data;
        });

        effect(() => {
            CoreStateService.lockUIActions.set(
                this.printDocumentInProgress() || this.printOrderInProgress() || this.paymentInProgress()
            );
        }, { allowSignalWrites: true });
    }

    //Private attributes
    private currentSaleItem: { saleUuid: string, uuid: string } | undefined;
    private isCreatingSale: Promise<any> | null = null;

    //Public attributes
    public currentSale = this.activeSaleStore.currentSale || {} as Readonly<SalesCashregister>;
    public lastSaleTotal: WritableSignal<lastSaleTotal | null> = signal(null);
    public printerDocumentData: DocumentPrinterOptions | null = null;
    public paymentInProgress = signal(false);
    public printDocumentInProgress = signal(false);
    public printOrderInProgress = signal(false);
    public lockPaymentButtons = signal(false);
    public hasPaidPayments = signal(false);
    public saleMode: WritableSignal<SaleMode | undefined> = signal(undefined);
    public saleType: WritableSignal<SaleType | undefined> = signal(undefined);
    public groupItemUuid?: string = undefined;

    //Observables and Subjects
    public static activeSaleEvents$ = new Subject<ActiveSaleEvent>();
    private static printerDocumentDataUpdates = new Subject<DocumentPrinterOptions>();
    public static printerDocumentDataUpdates$ = ActiveSaleService.printerDocumentDataUpdates.asObservable();

    private log(...args:any[]){
        DevLogger.log('[ ActiveSaleService ]',...args);
    }

    private sendActiveSaleEvent(event: ActiveSaleEvent) {
        ActiveSaleService.activeSaleEvents$.next(event);
    }
    /*
        ### HOOKS ###
    */

    private onPrinterUpdates(printer?: Printers) {
        if (printer?.id && this.printerDocumentData?.printer.id === printer.id) {
            this.resetDocumentData();
        }
    }

    private async printSaleGiftcard(sale: Sales) {
        if (!["closed", "stored"].includes(sale.status)) {
            return;
        }

        const departments = await this.entityManagerService.departments.fetchCollectionOffline();
        const departmentGiftCardsMap = keyBy(departments.filter((department) => department.giftcard_type_uuid), (department) => department.id);

        //Find an item that is linked to a giftcard
        const hasGiftCard = !!(sale.sale_items?.find((saleItem) => (departmentGiftCardsMap[saleItem.department_id])));

        if (!hasGiftCard) {
            return;
        }

        const targetPrinter = sale.sale_documents?.[0]?.printer_id;

        if (!targetPrinter) {
            return;
        }

        this.GiftCardPrinter.printSaleGiftCards(sale.uuid, targetPrinter);
    }

    /*
        ### PRIVATE METHODS ###
    */

    private isEInvoiceDocument(documentTypeId: string) {
        return ['e_invoice', 'summary_e_rc', 'summary_e_nrc'].includes(documentTypeId);
    }

    private saveSaleToStorage = (sale?: Sales) => {
        if (!sale) {
            return this.activeSaleStore.saveSale();
        } else {
            if (sale.id) {
                return this.entityManagerService.sales.putOneOfflineFirst(sale);
            } else {
                return this.entityManagerService.sales.postOneOfflineFirst(sale);
            }
        }
    }

    private awaitSale() {
        return new Promise((resolve, reject) => {
            if (this.isActiveSale()) {
                resolve(undefined);
            } else if (this.isCreatingSale) {
                this.isCreatingSale.then(resolve);
            } else {
                this.createNewSale().then(resolve);
            }
        })
    }

    private loadParentSaleIfPresent = async (sale: Sales) => {
        const splitNumRegEx = /^.*-\s(\d+)$/;
        let index = 1;

        const match = sale.name.match(splitNumRegEx);

        if (match) {
            index = parseInt(match[1]) || 1;
        }

        const isChildSale = sale.final_amount! >= 0 && !!sale.sale_parent_uuid && sale.uuid !== sale.sale_parent_uuid;

        if (isChildSale) {
            const parentSale = await this.loadSale(sale.sale_parent_uuid, { fromParentSale: true });

            if (parentSale?.id) {
                this.splitSale({ type: 'by_items', splitIndex: index + 1 });
            }
        }

        return isChildSale;
    }

    private finishSaleClosing = async (sale: Sales, options?: FinishSaleClosingOptions) => {
        let isChildSale;

        //Reset document data if we are closing an active sale (avoid resetting it if we are closing a sale printed by an external event)
        if (!this.isActiveSale() || this.currentSale.uuid === sale.uuid) {
            this.resetDocumentData();
        }

        //Actions on closing an active sale
        if (this.isActiveSale() && this.currentSale.uuid === sale.uuid) {
            if (options?.closeActiveSale !== false) {
                await this.openSale(undefined, { skipResetDocumentData: true });
            }

            if (!options?.skipParentCheck) {
                isChildSale = await this.loadParentSaleIfPresent(sale);
            }

            if(isChildSale === false) { //If not a child sale (and we did not skip parent check)
                if(['closed', 'stored'].includes(sale.status) && sale.table_id && this.configurationManagerService.getPreference('cashregister.keep_table_open')) {
                    let saleCustomer;

                    //Remove id from sale customer
                    if(sale.sale_customer) {
                        let { id, ...sc } = sale.sale_customer;
                        saleCustomer = sc;
                    }

                    await this.createNewSale(undefined, {
                        dontOpenSale: true,
                        overrides: {
                            booking_id: sale.booking_id,
                            covers: sale.covers,
                            order_type: 'normal',
                            room_id: sale.room_id,
                            room_name: sale.room_name,
                            table_id: sale.table_id,
                            table_name: sale.table_name,
                            sale_customer: saleCustomer
                        }
                    });

                    this.openDialogsService.openSnackBarTilby('CASHREGISTER.ACTIVE_SALE.TABLE_KEPT_OPEN', 'MISC.OK', { duration: 5000 });
                }

                if (this.$stateParams.autohide) {
                    this.$state.go('.', { autohide: null });
                    this.util.minimizeApp();
                } else if (this.configurationManagerService.getPreference('cashregister.open_tables_after_send')) {
                    this.goToTablesSelectedRoom(sale.room_id, sale.order_type);
                }
            }
        }
    }

    public goToTablesSelectedRoom(roomId: number | Omit<Sales.OrderTypeEnum, 'normal'> | undefined, order_type: Sales.OrderTypeEnum | undefined) {
        if ((order_type == 'take_away' || order_type == 'delivery') && this.configurationManagerService.getPreference('orders.return_tables_takeaway_delivery')) {
            roomId = order_type;
        }

        if (roomId) {
            this.goToTables(roomId);
        }
    }

    public async goToTables(roomId: number|Omit<Sales.OrderTypeEnum,'normal'>) {
        if (!roomId || !this.configurationManagerService.isModuleEnabled('tables')) {
            return;
        }

        await this.loadSale(undefined);

        if (!this.configurationManagerService.getSettingUserFirst('cashregister.use_tables_map_in_portrait') && this.injector.get(ScreenOrientationService).getOrientation().includes("portrait")) {
            const res = await this.injector.get(SelectSaleDialogService).openDialog();

            if (!res?.saleId) {
                return;
            }

            await this.loadSale(res.saleId);
        } else {
            this.$state.go("app.new.tables.rooms-view", { id: roomId });
        }
    }

    private performStore = async (saleToStore: Sales, options?: StoreSaleOptions) => {
        const opData = this.OperatorManager.getSellerData();

        Object.assign(saleToStore, {
            status: 'stored',
            closed_at: new Date().toISOString(),
            seller_id: opData.id,
            seller_name: opData.full_name
        });

        if (options?.reason) {
            saleToStore.notes = options.reason;
        }

        await this.oldSaleUtils.calculateSaleItemsCosts(saleToStore);
        let sale = await this.saveSaleToStorage(saleToStore);

        this.finishSaleClosing(sale!, options);
    }

    private checkNotDiscountableSaleItem = (saleItem: SalesItems) => {
        if (!this.hasNotDiscountableItems() && saleItem.not_discountable) { //No "Not discountable" item in sale -> Not discountable item in sale
            //Remove sale discount_fix from price_changes
            this.activeSaleStore.removeSalePriceChange((priceChange) => priceChange.type === 'discount_fix');

            //Change discount_perc to disc_perc_nd
            this.activeSaleStore.editSalePriceChanges((priceChange) => {
                if(priceChange.type === 'discount_perc') {
                    priceChange.type = 'disc_perc_nd';
                    priceChange.index += 100;

                    return true;
                }

                return false;
            });
        }
    };

    private addGenericPriceChange = (target: Sales | SalesItems, type: SalesPriceChanges.TypeEnum, value: number, description: string, index?: number, overrides?: Partial<SalesPriceChanges | SalesItemsPriceChanges>) => {
        const priceChange = this.saleUtilsService.getGenericPriceChange(target, type, value, description, index, overrides);

        // Check if we are dealing with a sale or a sale item by checking if the target has a 'open_at' property
        if ('open_at' in target) {
            // Target is a sale
            this.activeSaleStore.addSalePriceChanges(priceChange);

            if (type !== 'disc_perc_nd') {
                this.sendActiveSaleEvent({ event: 'priceChange-added', data: { target: target, priceChange: priceChange, targetType: target.sale_items ? 'sale' : 'sale_item' } });
            }
        } else {
            // Target is a sale item
            this.activeSaleStore.addSaleItemPriceChanges(target.uuid, priceChange);
        }

        return priceChange;
    };

    private async determinePricelistToUse(sale?: Sales, skipNotify?: boolean) {
        const priceListToUse = await this.saleUtilsService.getSalePriceList(sale);

        if(priceListToUse) {
            this.sendActiveSaleEvent({ event: 'use-pricelist', data: { priceList: priceListToUse, skipNotify: skipNotify } });
        } else if (this.configurationManagerService.getPreference("cashregister.reset_pricelist_on_close")) {
            this.sendActiveSaleEvent({ event: 'reset-pricelist' });
        } else {
            this.sendActiveSaleEvent({ event: 'use-pricelist', data: { priceList: 'default', skipNotify: skipNotify } });
        }
    };

    private async openSale(saleData?: Sales | SaleTransactions[], options?: OpenSaleOptions) {
        //Reset status variables
        this.resetGroupItemUuid();
        this.currentSaleItem = undefined;

        //Emit sale-parked event if we are unloading a current open sale
        if (this.isActiveSale() && !['closed', 'stored'].includes(this.currentSale.status)) {
            this.sendActiveSaleEvent({ event: 'sale-parked', data: this.currentSale });
        }

        let sale;

        try {
            sale = this.activeSaleStore.openSale(saleData);
        } catch(err) {
            //Avoid showing this error when loading a parent sale, since there is a chance that the parent sale is not in open status (e.g. for split sales)
            if(!options?.fromParentSale) {
                this.openDialogsService.openAlertDialog({ data: { messageLabel: `CASHREGISTER.ACTIVE_SALE.${err}`} });
            }

            return;
        }

        await this.determinePricelistToUse(sale, true);

        this.sendActiveSaleEvent({ event: 'sale-opened', data: sale });

        if (!this.printerDocumentData && options?.skipResetDocumentData !== true) {
            this.resetDocumentData();
        }

        if (sale) {
            this.lastSaleTotal.set(null);
        }

        return (sale || null);
    };

    private async askSellerIfRequired() {
        if (this.configurationManagerService.getShopPreference('cashregister.ask_seller_for_each_sale')) {
            await this.OperatorManager.changeSeller();
        }
    };

    private getFastPaymentAmount(sale: Sales, paymentMethod: PaymentMethods, paymentAmount?: number): [number, Partial<SalesPayments>] {
        const targetAmount = paymentAmount || sale.final_amount;

        let amount, inputObject: Partial<SalesPayments> = {};

        if ([1].includes(paymentMethod.payment_method_type_id) && this.fiscalUtils.isPaymentRoundingEnabled() && !sale.is_summary) {
            amount = MathUtils.round(Math.round((targetAmount || 0) * 20) / 20);

            if (amount !== targetAmount) {
                inputObject.payment_data = JSON.stringify({ rounding: true, original_amount: targetAmount });
            }
        } else {
            amount = targetAmount || 0;
        }

        return [amount, inputObject];
    };

    /*
        ### PUBLIC METHODS ###
    */

    public resetGroupItemUuid() {
        this.groupItemUuid = undefined;
    }

    public isPristine() {
        return this.activeSaleStore.isPristine();
    }

    public async loadSale(saleId?: number | string, options?: LoadSaleOptions) {
        if (saleId && !options?.skipOperatorCheck) {
            //Wait for operator change if necessary
            await this.askSellerIfRequired();
        }

        //Save current open sale if necessary
        if (options?.skipStore !== true && this.isActiveSale() && !this.isPristine()) {
            await this.saveSaleToStorage();
        }

        const [saleData] = await this.activeSaleStore.loadSale(saleId);

        return this.openSale(saleData, options);
    }

    private async openSaleDetailsDialog(isEdit:boolean, params: OpenEditSaleDialogFormParams, selectedTableId?: number) {
        const title = `TABLES_NEW.DIALOGS.SALE.TITLE${isEdit?'_MODIFY':''}`;
        const skipDialog = this.configurationManagerService.getPreference('orders.skip_creation_dialog');
        const deliveryChannels = await this.entityManagerService.channels.fetchCollectionOffline().then((channels) => channels.filter((channel) => channel.id !== 'pos'));
        const rooms = await this.entityManagerService.rooms.fetchCollectionOffline();

        let tables: (RoomsTables & {room: Rooms})[] = [];

        for (const room of rooms) {
            for (const table of room.tables || []) {
                tables.push(Object.assign({
                    room: room
                }, table));
            }
        }

        tables = tables.filter((table) => (!table.shape.startsWith(STATIC_ELEMENT_PREFIX)));

        let res : any = undefined;

        if ((skipDialog && !params.longPress) && !params.id && params.saleType === 'normal' && params.table?.id) {
            res = { name: this.translateService.instant('TABLES_NEW.DIALOGS.SALE.NAME_TEMPLATE'), type: params.saleType, table_id: params.table.id, covers: params.table.covers };
        } else {
            res = await this.openDialogsService.openMagicFormDialog({
                data: {
                    title: title,
                    form: this.roomsStateService.createOrOpenSaleForm(params, deliveryChannels, tables, isEdit),
                    buttonCancelLabel: 'TABLES_NEW.DIALOGS.SALE.BUTTON_CANCEL',
                    buttonConfirmLabel: 'TABLES_NEW.DIALOGS.SALE.BUTTON_CONFIRM'
                },
                panelClass:'lg:tw-w-4/5'
            });

            this.roomsStateService.ngOnDestroy();
        }

        if(!res) {
            return;
        }

        const updateData: Partial<Sales> = {};

        Object.assign(updateData, {
            name: res.name,
            order_type: res.type,
            notes: res.notes
        });

        switch (res.type) {
            case 'normal':
                const table = tables.find((table) => table.id === res.table_id);

                Object.assign(updateData, {
                    table_id: table?.id,
                    table_name: table?.name,
                    room_id: table?.room?.id,
                    room_name: table?.room?.name,
                    covers: res.covers,
                    booking_id: res.booking_id || null,
                    deliver_at: undefined,
                    external_id: undefined,
                    channel: undefined
                });
                break;
            case 'take_away':
            case 'delivery':
                Object.assign(updateData, {
                    table_id: undefined,
                    table_name: undefined,
                    room_id: undefined,
                    room_name: undefined,
                    covers: undefined,
                    booking_id: undefined,
                    deliver_at: res.delivery_at,
                    external_id: res.type === 'delivery' ? res.order_id : undefined,
                    channel: res.type === 'delivery' ? res.channels : undefined
                });
                break;
        }

        return updateData;
    }

    public async editSale() {
        const params:OpenEditSaleDialogFormParams = {
            id: this.currentSale.id,
            name: this.currentSale.name,
            saleType : this.currentSale.order_type,
            table: {
                id : this.currentSale.table_id,
                covers : this.currentSale.covers
            },
            booking: {
                id: this.currentSale.booking_id
            },
            order_id: this.currentSale.external_id,
            delivery_at: this.currentSale.deliver_at,
            channel: this.currentSale.channel,
            notes: this.currentSale.notes
        }

        const updateData = await this.openSaleDetailsDialog(true, params,this.currentSale.table_id);

        if(!updateData) {
            return;
        }

        this.updateSaleDetails(updateData);

        //Update sale cover
        await this.configureSaleCover(this.currentSale);

        await this.saveSale();
    }

    public async setLockStatus(locked: boolean, options?: { skipSave?: boolean }) {
        if (!!this.currentSale.bill_lock === !!locked) {
            return;
        }

        this.updateSaleDetails({ bill_lock: locked });

        if (!options?.skipSave) {
            await this.saveSale();
        }
    }

    private async configureSaleCover(sale: Sales) {
        const isActiveSale = sale === this.currentSale;

        if (!sale || sale.sale_parent_uuid || !sale.table_id || !sale.covers) {
            return;
        }

        const coverConfig = await this.saleUtilsService.getCoverConfiguration();

        if (!isActiveSale) {
            return this.saleUtilsService.applyCoverToSale(sale, coverConfig);
        }

        sale.sale_items = sale.sale_items || [];

        switch (coverConfig.type) {
            case 'item':
                const currentCoverItem = sale.sale_items?.find((item) => item.item_id === coverConfig.data.id);

                if (currentCoverItem) {
                    this.editSaleItem(currentCoverItem, { quantity: sale.covers })
                } else {
                    this.activeSaleStore.addSaleItem(this.saleUtilsService.getCoverSaleItem(sale, coverConfig.data, sale.covers));
                }
                break;
            case 'price_change':
                // TODO: implement
                break;
            case 'error':
                break;
            default:
                break;
        }
    }

    public async getBuzzerInputIfRequired(sale: Sales): Promise<{ pager: string | null } | undefined> {
        if (!['normal', 'take_away'].includes(sale.order_type!) || sale.table_id) {
            return { pager: null };
        }

        const requireBuzzerInput = !!this.configurationManagerService.getShopPreference('cashregister.require_buzzer_input');

        if (!requireBuzzerInput) {
            return { pager: null };
        }

        return this.buzzerInputDialogService.openDialog({ data: { title: 'DIALOG.BUZZER_INPUT_DIALOG.TITLE' } });
    }

    /**
     * @deprecated
     * Creates a new sale. Used only for compatiblity with old code.
     * @param options - Optional parameters for creating a new sale.
     * @returns {Promise<Sales>} - A promise that resolves to the newly created sale.
     */
    public async newSale(options?: NewSaleOptions) {
        return this.createNewSale(undefined, options);
    }

    /**
     * Creates a new sale.
     * 
     * This function is responsible for creating a new sale. It handles various aspects of the sale creation process,
     * 
     * @param {OpenEditSaleDialogFormParams} params - Optional parameters for opening the sale details dialog.
     * @param {NewSaleOptions} options - Optional parameters for creating a new sale.
     * @returns {Promise<Sales>} - A promise that resolves to the newly created sale.
     * @throws {string} - Throws an error with the message 'SALE_CREATION_PENDING' if a sale is currently being created.
     */
    public async createNewSale(params?: OpenEditSaleDialogFormParams, options?: NewSaleOptions) {
        // Check if a sale creation is already in progress
        if (this.isCreatingSale) {
            // Throw an error indicating that a sale creation is pending
            throw 'SALE_CREATION_PENDING';
        }

        // Create a new promise that represents the sale creation process
        return this.isCreatingSale = new Promise(async (resolve, reject) => {
            try {
                // If there is an active sale, save it to the storage
                if (this.isActiveSale() && this.currentSale.id) {
                    this.saveSaleToStorage();
                }

                // If the sale is not being saved (e.g. in kiosk mode), ask for the current seller
                if (!options?.dontSaveSale) {
                    await this.askSellerIfRequired();
                }

                // Open the sale details dialog if required parameters are provided
                let updateData = null;

                if (params) {
                    updateData = await this.openSaleDetailsDialog(false, params);

                    // If the sale details dialog is cancelled, return without creating a new sale
                    if (!updateData) {
                        return;
                    }
                }

                // Create a new sale object with default values and any provided update data or overrides
                let sale: Sales = {
                    ...(await this.oldSaleUtils.getSaleTemplate()),
                    ...updateData || {},
                    ...options?.overrides || {},
                }

                // Configure the sale cover if necessary
                await this.configureSaleCover(sale);

                // If the sale is being saved (not in kiosk mode), ask for the current pager
                if (!options?.dontSaveSale) {
                    //TODO: once we use this service in kiosk mode we should merge pager handling in one service
                    const buzzer = await this.getBuzzerInputIfRequired(sale);

                    // Cancel sale creation if the user cancels the buzzer input dialog
                    if (!buzzer) {
                        return;
                    }

                    sale.name = buzzer.pager ? `Pager ${buzzer.pager}` : sale.name;

                    // Check for LeanPMS and add PMS data if the user selects them
                    if(this.LeanPMS.isEnabled() && this.configurationManagerService.getPreference('lean_pms.ask_room_number_on_sale_open')) {
                        const reservationData = await this.LeanPMS.getSaleReservationData(sale);

                        if(reservationData) {
                            Object.assign(sale, reservationData);
                        }
                    }

                    // Save the sale
                    sale = await this.saveSaleToStorage(sale) as Sales;

                    // Load the sale if not asked otherwise
                    if (!options?.dontOpenSale) {
                        await this.loadSale(sale.id, { skipOperatorCheck: true });
                    }
                } else {
                    // Load the sale (only in memory)
                    if (!options?.dontOpenSale) {
                        await this.openSale(sale);
                    }
                }

                // If a customer is provided in the parameters, add it to the customer list
                if (params?.customer) {
                    await this.cashregisterStateService.addCustomer(params.customer);
                }

                // Resolve the promise with a clone of the current sale
                resolve(structuredClone(this.currentSale));
            } catch (error) {
                reject(error);
            } finally {
                // Reset isCreatingSale to indicate that the sale creation process has finished
                this.isCreatingSale = null;
            }
        });
    }

    public async saveSale(newName?: string, closeAfterSave?: boolean) {
        if (newName && newName !== this.currentSale.name) {
            this.updateSaleDetails({ name: newName });
        }

        const sale = await this.saveSaleToStorage();

        if (closeAfterSave) {
            await this.openSale();
            await this.loadParentSaleIfPresent(sale!);
        }
    }

    public async storeSale(options?: StoreSaleOptions) {
        const saleToSend = structuredClone(this.currentSale);

        if (!Object.keys(saleToSend || {}).length) {
            return;
        }

        const saleToPrint = structuredClone(saleToSend);

        if (saleToSend.final_amount! > 0) {
            const cashDrawerMethods = await this.entityManagerService.paymentMethods.fetchCollectionOffline({ payment_method_type_id_in: this.DigitalPaymentsManager.getCahdrawerMethods() });
            let cashDrawerMethod = cashDrawerMethods.find((paymentMethod) => this.DigitalPaymentsManager.isPaymentDigitalEnvironmentAllowed(paymentMethod.payment_method_type_id));

            if (cashDrawerMethod) {
                await this.DigitalPaymentsManager.digitalPayment(saleToSend.final_amount, cashDrawerMethod.id, { sale: structuredClone(saleToSend) });
            }
        }

        await this.performStore(saleToSend, options);

        if (this.configurationManagerService.getPreference('cashregister.print_nonfiscal_sale_on_store')) {
            this.salePrintingUtils.sendSaleAsOrder(saleToPrint);
        }

        if (this.printerDocumentData?.printer.id && this.configurationManagerService.getPreference('cashregister.open_cashdrawer_on_store')) {
            this.documentPrinter.openCashDrawer(this.printerDocumentData);
        }
    }

    public async deleteSale(options?: DeleteSaleOptions) {
        if (options?.reason) {
            this.updateSaleDetails({ notes: options.reason });
        }

        const clonedCurrentSale = structuredClone(options?.sale || this.currentSale);

        //Delete sale from the fiscal provider if necessary
        const fiscalProviderDocument = clonedCurrentSale.sale_documents?.find((document) => document.document_type === 'fiscal_provider');

        if (fiscalProviderDocument) {
            const fiscalProviderName = fiscalProviderDocument.meta?.fiscal_provider;
            const fiscalProvider = this.FiscalProviders.getFiscalProvider(fiscalProviderName);

            if (fiscalProvider && 'cancelFiscalDocument' in fiscalProvider) {
                try {
                    await fiscalProvider.cancelFiscalDocument(clonedCurrentSale);
                } catch (error) {
                    if (fiscalProvider.getProviderError) {
                        throw fiscalProvider.getProviderError(error);
                    }

                    throw error;
                }
            }
        }

        try {
            await this.entityManagerService.sales.deleteOneOfflineFirst(clonedCurrentSale.id!);
        } catch (err) { }

        if(options?.skipClosing) {
            return;
        }

        this.finishSaleClosing(clonedCurrentSale);
    }

    public async addItemToSale(item: Items, priceList: number, combinationId?: number, quantity?: number, barcode?: string, overrides?: Partial<SalesItems>, options?: { addAsNew?: boolean }) {
        await this.awaitSale();

        const priceListStr = `price${priceList}` as (`price${number}` & keyof Items & keyof ItemsCombinations);

        //Get target price
        let targetPrice = item[priceListStr];

        const menuMode = this.groupItemUuid && !item.is_group_item;

        if (menuMode) {
            targetPrice = 0;
        }
        else if (overrides?.price != null && Number.isFinite(overrides?.price)) {
            targetPrice = overrides.price;
        }
        else if (combinationId) {
            const combination = item.combinations?.find((combination) => combination.id === combinationId);
            targetPrice = combination ? combination[priceListStr] : item[priceListStr];
        }

        const editExistingItem =
            !options?.addAsNew &&
            !this.configurationManagerService.getPreference("cashregister.add_item_as_new") &&
            (!Object.keys(overrides || {}).length || (Object.keys(overrides || {}).length==1 &&overrides?.exit)) &&
            quantity == null &&
            !item.is_group_item &&
            !item.split_group_components

        if (editExistingItem) {
            //Check if the item is already in the sale
            const saleItem = this.currentSale.sale_items?.find((saleItem) => (
                saleItem.type === 'sale' &&
                saleItem.item_id === item.id &&
                (overrides?saleItem.exit === overrides.exit:true) &&
                saleItem.sale_item_parent_uuid == this.groupItemUuid &&
                saleItem.combination_id == combinationId &&
                saleItem.price === targetPrice &&
                !(saleItem.variations?.length) &&
                !(saleItem.ingredients?.length)
            ));

            if (saleItem?.item_id) {
                return this.editSaleItem(saleItem, { quantity: (saleItem.quantity === -1) ? 1 : (saleItem.quantity + 1) });
            }
        }

        const { price: itemPrice, ...itemOverrides } = overrides || {};

        const saleItem = await this.oldSaleUtils.getSaleItemTemplate(item, priceList, combinationId, quantity, barcode, Object.assign(itemOverrides, {
            price: targetPrice,
            sale_item_parent_uuid: menuMode ? (this.groupItemUuid || null) : null
        })) as SalesItems;

        if (!saleItem?.quantity) {
            return saleItem;
        }

        this.checkNotDiscountableSaleItem(saleItem);

        const itemsToAdd = [];

        if (item.is_group_item || item.split_group_components) {
            this.groupItemUuid = saleItem.uuid;
        }

        //Handle menus with components splitting
        if (item.split_group_components) {
            //Find the items linked to the menu and add the resulting order items
            // Remove the variations that have a linked item from the sale item
            const variationsWithItem = removeFromArray(saleItem.variations || [], (variation) => !!(variation.linked_item_uuid));

            for (const variation of variationsWithItem) {
                const linkedItemSearch = await this.entityManagerService.items.fetchCollectionOffline({ uuid: variation.linked_item_uuid });
                const defaultExit =  item.variations?.find((v) => v.id === variation.variation_id)?.default_exit;

                if (linkedItemSearch.length) {
                    let itemToAdd = await this.oldSaleUtils.getSaleItemTemplate(linkedItemSearch[0], priceList, null, saleItem.quantity, null, { price: variation.price_difference || 0, sale_item_parent_uuid: this.groupItemUuid, exit: defaultExit });

                    if (itemToAdd?.quantity) {
                        itemsToAdd.push(itemToAdd);
                    }
                }
            }

            this.resetGroupItemUuid();
        }

        itemsToAdd.unshift(saleItem);

        for (let itemToAdd of itemsToAdd) {
            this.activeSaleStore.addSaleItem(itemToAdd);
        }

        this.sendActiveSaleEvent({ event: 'item-added', data: saleItem });

        return saleItem;
    }

    public cloneSaleItem(saleItem?: SalesItems, numClones?: number) {
        const targetSaleItem = this.getActiveSaleItem(saleItem);

        if (!targetSaleItem) {
            return;
        }

        const now = new Date().toISOString();
        const opData = this.OperatorManager.getSellerData();
        const { id, ...newSaleItem } = structuredClone(targetSaleItem);

        if (!numClones) {
            numClones = 1;
        }

        Object.assign(newSaleItem, {
            added_at: now,
            ingredients: this.oldSaleUtils.getCleanSubEntity(newSaleItem.ingredients),
            lastupdate_at: now,
            lastupdate_by: opData.id,
            price_changes: this.oldSaleUtils.getCleanSubEntity(newSaleItem.price_changes),
            seller_id: opData.id,
            seller_name: opData.full_name,
            variations: this.oldSaleUtils.getCleanSubEntity(newSaleItem.variations)
        });

        for (let i = 0; i < numClones; i++) {
            this.activeSaleStore.addSaleItem(Object.assign(structuredClone(newSaleItem), { uuid: generateUuid() }));
        }
    }

    public canChangeSaleItemQuantity(saleItem: SalesItems) {
        return !saleItem.prize_id && !['deposit_cancellation', 'coupon'].includes(saleItem.type);
    }

    public changeSaleItemQuantity(saleItem: SalesItems, newQuantity: number) {
        const targetSaleItem = this.getActiveSaleItem(saleItem);

        if (!targetSaleItem || !Number.isFinite(newQuantity) || !this.canChangeSaleItemQuantity(targetSaleItem)) {
            return;
        }

        this.editSaleItem(targetSaleItem, {
            quantity: newQuantity,
            type: "sale",
            request_weighing: false,
            refund_cause_description: undefined,
            refund_cause_id: undefined
        });
    }

    private expandDepartmentData (saleItem: Partial<SalesItems>) {
        if(saleItem.department) {
            Object.assign(saleItem, {
                department_id: saleItem.department.id,
                department_name: saleItem.department.name,
                vat_perc: saleItem.department.vat?.value
            });
        }

        return saleItem;
    }

    public addSaleItem(saleItem: SalesItems) {
        const saleItemToAdd = structuredClone(saleItem);
        const time = new Date().toISOString();
        const opData = this.OperatorManager.getOperatorData();

        Object.assign(saleItem, {
            added_at: time,
            lastupdate_at: time,
            lastupdate_by: opData.id,
            seller_id: opData.id,
            seller_name: opData.full_name,
            uuid: generateUuid()
        });

        this.expandDepartmentData(saleItemToAdd);

        this.activeSaleStore.addSaleItem(saleItem);
    }

    public editSaleItem(saleItem: SalesItems, newData?: Partial<SalesItems>, options?: { overrideMenuChecks?: boolean }) {
        let previousSaleItem = structuredClone(saleItem);

        //Incremental update
        let fieldsToOmit: (keyof SalesItems)[] = [];

        if (!options?.overrideMenuChecks) {
            let isItemASelectedMenu = (this.groupItemUuid && saleItem.uuid === this.groupItemUuid);
            let isItemUnderUnselectedMenu = (saleItem.sale_item_parent_uuid && saleItem.sale_item_parent_uuid !== this.groupItemUuid);

            if (isItemASelectedMenu || isItemUnderUnselectedMenu) {
                fieldsToOmit.push('quantity');
            }

            if (saleItem.sale_item_parent_uuid) {
                fieldsToOmit.push('price');
            }
        }

        const updateData = pickBy(newData || {}, (value, key) => !fieldsToOmit.includes(key));

        if (!Object.keys(updateData).length) {
            return saleItem;
        }

        this.expandDepartmentData(updateData);

        const saleItemsToUpdate: EditSaleItemUpdate[] = [{
            saleItem,
            newData: updateData
        }];

        //If we changed the quantity of a menu, change all menu items quantity accordingly
        if (saleItem.is_group_item && updateData.quantity) {
            let multiplierFactor = (updateData.quantity / saleItem.quantity);
            let itemsToChange = this.currentSale.sale_items?.filter((si) => si.sale_item_parent_uuid === saleItem.uuid);

            for (const si of itemsToChange || []) {
                saleItemsToUpdate.push({
                    saleItem: si,
                    newData: { quantity: Math.ceil(si.quantity * multiplierFactor) }
                });
            }
        }

        this.activeSaleStore.editSaleItems(saleItemsToUpdate);

        this.sendActiveSaleEvent({ event: 'item-changed', data: { currentSaleItem: saleItem, previousSaleItem } });

        return this.currentSale.sale_items?.find((si) => si.uuid === saleItem.uuid);
    }

    public updateSaleDetails(saleDetails: Partial<Sales>) {
        if (!this.isActiveSale()) {
            return;
        }

        this.activeSaleStore.updateSaleDetails(saleDetails);
    }

    public removeSaleItem(saleItem: SalesItems) {
        //Remove target and all children from current sale
        const itemsToRemove = this.currentSale?.sale_items?.filter((si) => si.uuid === saleItem.uuid || si.sale_item_parent_uuid === saleItem.uuid) || [];
        this.activeSaleStore.removeSaleItems(itemsToRemove);

        //Revert not-discountable price changes in sale if there are no more not-discountable items
        if (this.currentSale.price_changes?.some((pc) => pc.type === "disc_perc_nd") && this.currentSale.sale_items?.every((si) => !si.not_discountable)) {
            const updatedSalePriceChanges = structuredClone(this.currentSale.price_changes || []);

            for (const priceChange of updatedSalePriceChanges.filter((pc) => pc.type === "disc_perc_nd")) {
                for (const saleItem of this.currentSale.sale_items) {
                    this.activeSaleStore.removeSaleItemPriceChange(saleItem.uuid, (pc) => pc.index === priceChange.index);
                }

                priceChange.type = 'discount_perc';
                priceChange.index -= 100;
            }

            this.activeSaleStore.addSalePriceChanges(updatedSalePriceChanges, { replace: true });
        }

        if (saleItem.uuid === this.groupItemUuid) {
            this.resetGroupItemUuid();
        }

        if (this.currentSaleItem?.uuid && saleItem.uuid === this.currentSaleItem.uuid) {
            this.currentSaleItem = undefined;
        }

        this.sendActiveSaleEvent({ event: 'item-removed', data: saleItem });
    }

    public refundSaleItem(saleItem: SalesItems, refundCause: RefundCauses) {
        return this.editSaleItem(saleItem, {
            quantity: -(Math.abs(saleItem.quantity)),
            type: 'refund',
            refund_cause_id: refundCause.id,
            refund_cause_description: this.translateService.instant(refundCause.translation_id),
            price_changes: []
        });
    }

    public cancelRefundOnSaleItem(saleItem: SalesItems) {
        return this.editSaleItem(saleItem, {
            quantity: Math.abs(saleItem.quantity),
            type: 'sale',
            refund_cause_id: undefined,
            refund_cause_description: undefined,
        });
    }

    public removePriceChangeFromSale(priceChange: SalesPriceChanges) {
        this.activeSaleStore.removeSalePriceChange((pc) => pc === priceChange);

        this.sendActiveSaleEvent({ event: 'priceChange-removed', data: { target: this.currentSale, priceChange: priceChange, targetType: 'sale' } });
    }

    public async removePriceChangeFromSaleItem(saleItem: SalesItems, priceChange: SalesItemsPriceChanges) {
        if (this.hasNotDiscountableItems() && priceChange.index >= 100) {
            this.activeSaleStore.removeSalePriceChange((pc) => pc.type === 'disc_perc_nd' && pc.index === priceChange.index);
        } else {
            this.activeSaleStore.removeSaleItemPriceChange(saleItem.uuid, (pc) => pc === priceChange);
        }

        //Restore default item department if the discount is a gift
        if (priceChange.type === 'gift' && saleItem.item_id) {
            try {
                const originalItem = await this.entityManagerService.items.fetchOneOffline(saleItem.item_id);

                if (originalItem) {
                    this.editSaleItem(saleItem, {
                        department: originalItem.department
                    });
                }
            } catch (err) {
                //Nothing to do
            }
        }

        this.sendActiveSaleEvent({ event: 'priceChange-removed', data: { target: saleItem, priceChange: priceChange, targetType: 'sale_item' } });
    }

    public removeCustomerPriceChangeFromSale() {
        const targetDescription = this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.CLIENT_DISCOUNT');

        //Remove customer discount and update details if there are changes
        this.activeSaleStore.removeSalePriceChange((pc) => (pc.description === targetDescription) || !!pc.prize_id);

        for (const saleItem of this.currentSale?.sale_items || []) {
            if (saleItem.prize_id) {
                this.removeSaleItem(saleItem);
            }
        }
    }

    public async addDynamicItemToSale(department: Departments, price: number, overrides?: Partial<SalesItems>) {
        await this.awaitSale();

        let saleItem = this.oldSaleUtils.getDynamicSaleItemTemplate(department, price);
        Object.assign(saleItem, overrides || {});

        this.checkNotDiscountableSaleItem(saleItem);

        this.activeSaleStore.addSaleItem(saleItem);

        this.sendActiveSaleEvent({ event: 'item-added', data: saleItem });

        return saleItem;
    }

    public async applyPriceList(priceList: number) {
        const targetDepartment = priceList === 1 ? `department` : `department${priceList}` as (`department${number}` & keyof Items);
        const itemsMap: Record<number, Items> = await this.util.getItemsFromIds(this.currentSale.sale_items);
        const priceListStr = `price${priceList}` as (`price${number}` & keyof Items & keyof ItemsCombinations);

        for (const saleItem of this.currentSale?.sale_items || []) {
            // Ignore items without item_id or that have a parent row
            if (!saleItem.item_id || saleItem.sale_item_parent_uuid) {
                continue;
            }

            const item = itemsMap[saleItem.item_id];

            if (!item) {
                continue;
            }

            let newPrice;

            if (saleItem.combination_id) {
                const combinationToUse = item.combinations?.find((c) => c.id === saleItem.combination_id);
                newPrice = combinationToUse?.[priceListStr];
            } else {
                newPrice = item[priceListStr];
            }

            if (!Number.isFinite(newPrice)) {
                continue;
            }

            const department = item[targetDepartment] || item['department']!;

            this.editSaleItem(saleItem, {
                department: department,
                price: newPrice
            });
        }
    }

    public isActiveSale() {
        return !!(this.currentSale?.uuid);
    }

    public isEmpty() {
        return !(this.currentSale?.sale_items?.length);
    }

    public async addCustomer(customer: Customers|SalesCustomer) {
        await this.awaitSale();

        //Prepare sale customer
        const saleCustomer = <SalesCustomer>structuredClone(customer);

        if (validateUuid(String(saleCustomer.id || ''))) {
            Object.assign(saleCustomer, {
                id: null,
                uuid: saleCustomer.id,
                customer_id: null
            });
        } else {
            Object.assign(saleCustomer, {
                id: null,
                customer_id: saleCustomer.id
            })
        }

        //Add customer to sale
        this.updateSaleDetails({
            customer_tax_code: undefined,
            lottery_code: (<Customers>customer).lottery_code || this.currentSale.lottery_code,
            sale_customer: saleCustomer
        });

        //Manage customer discounts
        this.removeCustomerPriceChangeFromSale();

        if (saleCustomer.discount_perc) {
            this.addSaleDiscountPerc(saleCustomer.discount_perc, this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.CLIENT_DISCOUNT'));
        }

        this.sendActiveSaleEvent({ event: 'customer-added', data: saleCustomer });

        return saleCustomer;
    }

    public removeCustomer() {
        if (this.currentSale?.sale_customer?.default_pricelist) {
            this.sendActiveSaleEvent({ event: 'use-pricelist', data: { priceList: 'default' } });
        }

        this.activeSaleStore.removeSaleCustomer();

        this.updateSaleDetails({
            customer_tax_code: undefined
        });

        this.removeCustomerPriceChangeFromSale();
    }

    public async addTaxCode(taxCode: string) {
        await this.awaitSale();

        this.updateSaleDetails({
            sale_customer: undefined,
            customer_tax_code: `${taxCode}`.toUpperCase()
        });
    }

    public hasActiveSaleItem() {
        return !!(this.currentSaleItem?.saleUuid && this.currentSaleItem.saleUuid === this.currentSale.uuid);
    }

    public isActiveSaleItem(saleItem?: SalesItems) {
        // Return false if no item is selected
        if (!this.currentSaleItem) {
            return false;
        }

        const currentUuid = this.currentSaleItem.uuid;

        if (saleItem) {
            // if a sale item is provided, check if it is the same as the current selected item
            return currentUuid === saleItem.uuid;
        } else {
            // if no sale item is provided, check if the current selected item is in the sale
            saleItem = this.currentSale.sale_items?.find(item => item.uuid === currentUuid);

            if (saleItem) {
                return saleItem.id || saleItem.quantity > 0;
            }
        }

        // If we reach this point, the current selected item is not in the sale. Clean-up and return false
        this.currentSaleItem = undefined;

        return false;
    }

    public getActiveSaleItem = (saleItem?: SalesItems) => {
        return saleItem || this.currentSale.sale_items?.find(i => i.uuid === this.currentSaleItem?.uuid);
    }

    public hasNotDiscountableItems() {
        return this.currentSale.sale_items?.some(item => item.not_discountable);
    }

    public selectActiveSaleItem(saleItem?: SalesItems) {
        //Disable selection on sale items that are part of a menu
        if (saleItem?.sale_item_parent_uuid) {
            return;
        }

        this.currentSaleItem = this.currentSaleItem?.uuid === saleItem?.uuid ? undefined : { saleUuid: this.currentSale.uuid!, uuid: saleItem?.uuid! };

        return this.currentSaleItem;
    }

    public addSaleSurchargeFix(value: number, description?: string) {
        this.addGenericPriceChange(this.currentSale, 'surcharge_fix', value, description || this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SURCHARGE'));
    }

    public addSaleDiscountFix(value: number, description?: string) {
        if (value > this.currentSale.final_amount!) {
            throw 'DISCOUNT_TOO_HIGH';
        }

        if (this.hasNotDiscountableItems()) {
            throw 'ITEMS_NOT_DISCOUNTABLE';
        }

        this.addGenericPriceChange(this.currentSale, 'discount_fix', value, description || this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.DISCOUNT'));
    }

    public addSaleSurchargePerc(value: number, description?: string) {
        this.addGenericPriceChange(this.currentSale, 'surcharge_perc', value, description || this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SURCHARGE'));
    }

    public addSaleDiscountPerc(value: number, description?: string) {
        if (value > 100) {
            throw 'DISCOUNT_OVER_100';
        }

        this.addGenericPriceChange(this.currentSale, this.hasNotDiscountableItems() ? 'disc_perc_nd' : 'discount_perc', value, description || this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.DISCOUNT'));
    }

    public addSaleItemSurchargeFix(value: number, description?: string) {
        const saleItem = this.getActiveSaleItem();

        if (!saleItem) {
            return;
        }

        this.addGenericPriceChange(saleItem, 'surcharge_fix', value, description || this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SURCHARGE'));
    }

    public addSaleItemDiscountFix(value: number, description?: string) {
        const saleItem = this.getActiveSaleItem();

        if (!saleItem) {
            return;
        }

        if (saleItem.final_price === 0) {
            throw 'NO_DISCOUNT_WITH_FREE_ITEM';
        }

        if (value > (saleItem.final_price * saleItem.quantity)) {
            throw 'NO_DISCOUNT_HIGHER_THEN_AMOUNT';
        }

        if (saleItem.not_discountable) {
            throw 'ITEM_NOT_DISCOUNTABLE';
        }

        this.addGenericPriceChange(saleItem, 'discount_fix', value, description || this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.DISCOUNT'));
    }
    public addSaleItemSurchargePerc(value: number, description?: string) {
        const saleItem = this.getActiveSaleItem();

        if (!saleItem) {
            return;
        }

        this.addGenericPriceChange(saleItem, 'surcharge_perc', value, description || this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SURCHARGE'));
    }
    public addSaleItemDiscountPerc(value: number, description?: string) {
        const saleItem = this.getActiveSaleItem();

        if (!saleItem) {
            return;
        }

        if (value > 100) {
            throw 'DISCOUNT_OVER_100';
        }

        if (saleItem.final_price === 0) {
            throw 'NO_DISCOUNT_WITH_FREE_ITEM';
        }

        if (saleItem.not_discountable && !(this.configurationManagerService.getSetting("cashregister.allow_gift_not_discountable") && value === 100)) {
            throw 'ITEM_NOT_DISCOUNTABLE';
        }

        this.addGenericPriceChange(saleItem, 'discount_perc', value, description || this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.DISCOUNT'));
    }

    public async addPrize(prize: ChainPrizes) {
        switch (prize.type) {
            case 'discount_fix':
            case 'discount_perc':
                this.addGenericPriceChange(this.currentSale, prize.type, prize.discount_amount!, prize.name, undefined, { prize_id: prize.id });
                break;
            case 'gift':
                const items = await this.entityManagerService.items.fetchCollectionOffline({ sku: prize.item_sku });
                const saleItem = await this.addItemToSale(items[0], 1, undefined, 1, undefined, { prize_id: prize.id });
                this.giftSaleItem(saleItem);
                break;
        }
    }

    public giftSaleItem(saleItem?: SalesItems, description?: string, newDepartment?: Departments) {
        const targetSaleItem = this.getActiveSaleItem(saleItem);

        if (!targetSaleItem) {
            return;
        }

        if (targetSaleItem.not_discountable && !this.configurationManagerService.getSetting("cashregister.allow_gift_not_discountable")) {
            throw 'CASHREGISTER.ACTIVE_SALE_MODEL.ITEM_NOT_DISCOUNTABLE';
        }

        const giftPriceChange = this.saleUtilsService.getGenericPriceChange(targetSaleItem, 'gift', 100, description || this.translateService.instant('CASHREGISTER.ACTIVE_SALE.GIFT'));

        this.activeSaleStore.addSaleItemPriceChanges(targetSaleItem.uuid, [giftPriceChange], { replace: true });

        if (newDepartment) {
            this.editSaleItem(targetSaleItem, {
                department: newDepartment
            });
        }
    }

    public checkSale() { //Checks the sale before printing a document
        const errors: Record<string, boolean> = {};
        const warnings: Record<string, boolean> = {};

        const hasNegativeAmount = (this.currentSale?.final_amount || 0) < 0;
        const saleItems = this.currentSale.sale_items || [];

        if (!saleItems.length) {
            errors["SALE_EMPTY"] = true;
        } else {
            for (const saleItem of saleItems) {
                if(saleItem.request_weighing) {
                    errors["REQUEST_WEIGHING"] = true;
                }
                if (saleItem.quantity > 0) {
                    if (hasNegativeAmount) {
                        errors["NEG_AMOUNT_WITH_SALES"] = true;
                    }
                    if (saleItem.$partialAmount && saleItem.$partialAmount < 0) {
                        errors["NEG_SALE_ROWS"] = true;
                    }
                }
                if (!saleItem.price && saleItem.type === 'sale' && !saleItem.sale_item_parent_uuid) {
                    if (!this.configurationManagerService.getPreference('cashregister.hide_zero_price_warnings')) {
                        warnings["ZERO_PRICE_ITEMS"] = true;
                    }
                }
            }
        }

        const errorKeys = Object.keys(errors);
        const warningKeys = Object.keys(warnings);

        return {
            isValid: !errorKeys.length,
            hasWarnings: !!warningKeys.length,
            errors: errorKeys,
            warnings: warningKeys
        };
    }

    public async setPrinterDocumentData(data: DocumentPrinterOptions) {
        ActiveSaleService.printerDocumentDataUpdates.next(data);

        if (this.isActiveSale() && this.currentSale.e_invoice != null && !this.isEInvoiceDocument(data?.document_type?.id)) {
            try {
                const answer = await this.openDialogsService.openConfirmDialog({ data: { messageLabel: 'CASHREGISTER.ACTIVE_SALE.REMOVE_SALE_E_INVOICE_PROMPT', confirmLabel: 'DIALOG.CONFIRM.YES', cancelLabel: 'DIALOG.CONFIRM.NO' } });

                if (answer) {
                    this.activeSaleStore.removeEInvoice();
                } else {
                    const newDocumentData = await this.documentPrintersManager.getPrinterDocumentData(data?.printer?.id || 'default', ['e_invoice'], data.options);
                    ActiveSaleService.printerDocumentDataUpdates.next(newDocumentData);
                }
            } catch (error) { }
        }
    }


    public async resetDocumentData() {
        try {
            let defaultDocumentData = await this.documentPrintersManager.getPrinterDocumentData('default', 'default');
            await this.setPrinterDocumentData(defaultDocumentData);
        } catch (error) {
            this.printerDocumentData = null;
            throw error;
        }
    }

    public setEInvoiceData(eInvoiceData: SalesEInvoice) {
        this.updateSaleDetails({ e_invoice: eInvoiceData });
    }

    public async verifyPrinterDocumentDataAndSelect() {
        try {
            let pDocData = this.printerDocumentData;

            if (pDocData?.printer && pDocData?.document_type) {
                await this.documentPrintersManager.getPrinterDocumentData(pDocData.printer.id!, (pDocData?.document_template?.id || pDocData.document_type.id as any), (pDocData.options || {}));
            } else {
                await this.resetDocumentData();
            }
        } catch (err) {
            const printerDocumentData = await this.documentPrintersManager.openDialog();

            if (printerDocumentData) {
                this.setPrinterDocumentData(printerDocumentData);
            } else {
                throw err;
            }
        }
    }

    public async cashPayment() {
        const paymentMethods = await this.entityManagerService.paymentMethods.fetchCollectionOffline({ payment_method_type_id: 1 });

        if (!paymentMethods.length) {
            throw 'CASHREGISTER.ACTIVE_SALE.NO_CASH_PAYMENT_AVAILABLE';
        }

        const time = new Date();
        const paymentMethod = paymentMethods[0];

        const [amount, paymentInputObject] = this.getFastPaymentAmount(this.currentSale, paymentMethod);

        this.updateSaleDetails({
            payments: [Object.assign(paymentInputObject, {
                amount: amount,
                date: time.toISOString(),
                payment_method_id: paymentMethod.id,
                payment_method_name: paymentMethod.name,
                payment_method_type_id: 1,
                payment_method_type_name: this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.CASH'),
                unclaimed: false,
            })]
        });

        return this.processPayments();
    }

    public async fastPayment(paymentMethod: PaymentMethods, paymentAmount?: number) {
        const [amount, paymentInputObject] = this.getFastPaymentAmount(this.currentSale, paymentMethod, paymentAmount);

        this.addGenericPayment(paymentMethod, amount, paymentInputObject, { clearPreviousPayments: true });

        await this.processPayments();
        await this.processSaleChange();
    }

    public async addGenericPayment(paymentMethod: PaymentMethods, amount?: number, paymentInputObject?: Partial<SalesPayments>, options?: { clearPreviousPayments?: boolean }) {
        const availableType = paymentMethodTypes.find((type) => type.id === paymentMethod.payment_method_type_id);

        if (!availableType) {
            this.openDialogsService.openAlertDialog({ data: { messageLabel: 'CASHREGISTER.ACTIVE_SALE_MODEL.NO_TYPE_FOR_PAYMENT_METHOD' } });
            throw 'NO_TYPE_FOR_PAYMENT_METHOD';
        }

        const time = new Date();

        const payment = Object.assign({}, paymentInputObject || {}, <SalesPayments>{
            amount: amount || this.currentSale.final_amount,
            date: (time.toISOString() as unknown as Date),
            payment_method_id: paymentMethod.id,
            payment_method_name: paymentMethod.name,
            payment_method_type_id: availableType.id,
            payment_method_type_name: availableType.name,
            unclaimed: this.fiscalUtils.isMethodUnclaimed(availableType.id)
        });

        const currentPayments = Array.isArray(this.currentSale?.payments) && !options?.clearPreviousPayments ? this.currentSale.payments.filter((p) => p.amount) : [];

        this.updateSaleDetails({
            payments: [...currentPayments, payment]
        });
    }

    public skipTransactionsSend() {
        return this.activeSaleStore.markPendingTransactionsAsSkipped();
    }

    public async sendTransactions(): Promise<OrderSendStatus[]> {
        if(!this.currentSale?.uuid) {
            return [];
        }

        this.printOrderInProgress.set(true);

        try {
            //Save pending transaction first (use offline-mode as the sendTransactions will also save the transaction in offline-first mode)
            await this.activeSaleStore.saveCurrentTransaction({ offlineMode: true });

            const results = await this.salePrintingUtils.sendTransactions(this.currentSale.uuid);

            return results;
        } finally {
            this.printOrderInProgress.set(false);
        }
    }

    public async reprintOrder(): Promise<OrderSendStatus[]> {
        if(!this.currentSale?.uuid) {
            return [];
        }

        this.printOrderInProgress.set(true);

        try {
            const results = await this.salePrintingUtils.sendSaleAsOrder(this.currentSale, { reprint: true });

            return results;
        } finally {
            this.printOrderInProgress.set(false);
        }
    }

    public async sendExitToPrinter(exit: Exit): Promise<OrderSendStatus[]> {
        if(!exit.nameValue) {
            return [];
        }

        const opData = this.OperatorManager.getOperatorData();

        //Create a copy of the sale for the print function
        const sale = structuredClone(this.currentSale);

        Object.assign(sale, {
            seller_id: opData.id,
            seller_name: opData.full_name,
        });

        //Send the order to the printer
        return this.salePrintingUtils.sendExitToPrinter(sale, exit.nameValue);
    }

    public async printNonFiscalSale(printer?: Printers) {
        if (!this.currentSale) {
            return;
        }

        if (!printer) {
            printer = this.printerDocumentData?.printer;
        }

        if (!printer) {
            throw 'NO_PRINTER_SELECTED';
        }

        //Freeze the sale to prevent it from being changed by the user
        const saleToPrint = structuredClone(this.currentSale);

        try {
            this.printDocumentInProgress.set(true);
            await this.salePrintingUtils.printNonFiscalSale(saleToPrint, printer);
            if (this.configurationManagerService.getPreference('cashregister.open_tables_after_send')) {
                this.goToTablesSelectedRoom(saleToPrint.room_id, saleToPrint.order_type);
            }
        } finally {
            this.printDocumentInProgress.set(false);
        }
    }

    public async emitDocument(sale?: Sales, printerDocumentData?: DocumentPrinterOptions, options?: EmitDocumentOptions): Promise<EmitDocumentResult> {
        let result: EmitDocumentResult = {}; //TODO: fix typing

        const opData = this.OperatorManager.getSellerData();

        const targetSale = sale || this.currentSale;
        const saleToEmit = Object.assign(structuredClone(targetSale), { seller_name: opData.full_name, seller_id: opData.id }) as SalesCashregister;
        const isActiveSale = targetSale === this.currentSale;

        const docData = structuredClone(printerDocumentData || this.printerDocumentData!);
        const isKioskSale = saleToEmit.channel === 'kiosk' && (this.$state.current.name.startsWith('app.kiosk') || this.configurationManagerService.getPreference('kiosk.payments.send_sale_to_order_printers'));

        const printerId = docData.printer.id;
        const docId = docData.document_type.id;

        if (isActiveSale) {
            this.printDocumentInProgress.set(true);

            this.lastSaleTotal.set({
                final_amount: targetSale.final_amount || 0,
                change: targetSale.change || 0,
                id: typeof targetSale.id === 'number' ? targetSale.id : undefined,
                uuid: targetSale.uuid!
            });
        }

        try {
            if(this.isEInvoiceDocument(docId)) {
                try {
                    await this.activeSaleStore.saveSale({ onlineFirstMode: true });
                } catch(err) {
                    throw 'E_INVOICE_OFFLINE';
                }
            }

            //Convert quick coupons in tickets
            await this.salePayment.quickCouponsToTickets(saleToEmit);

            let printedDocuments = await this.documentPrinter.printDocument(saleToEmit, docData);

            if (this.isEInvoiceDocument(docId)) {
                this.finishSaleClosing(printedDocuments);

                result = {
                    emittedSale: printedDocuments
                }
            } else {
                try {
                    let notes = [];

                    let now = moment();
                    let documentDate = saleToEmit.sale_documents?.[0]?.date;

                    if (documentDate) {
                        let documentDateObj = moment(documentDate);

                        if (!documentDateObj.isBetween(moment(now).subtract(10, 'minutes'), moment(now).add(10, 'minutes'))) {
                            notes.push("CHECK_PRINTER_CLOCK");
                        }
                    }

                    if (saleToEmit.is_summary && !saleToEmit.sale_documents?.find((saleDocument) => ['summary_invoice', 'summary_e_rc', 'summary_e_nrc', 'generic_invoice'].includes(saleDocument.document_type))) {
                        saleToEmit.is_summary = false;
                    }

                    Object.assign(saleToEmit, {
                        status: 'closed',
                        closed_at: now.toISOString(),
                        change: this.getChange(saleToEmit),
                        secondary_final_amount: saleToEmit.secondary_exchange_rate ? ((saleToEmit.final_amount || 0) * saleToEmit.secondary_exchange_rate) : null
                    });

                    if (!saleToEmit.channel || saleToEmit.channel === 'pos') {
                        const deliveryChannel = saleToEmit.payments?.find((payment) => payment.payment_method_type_id === 17);

                        if (deliveryChannel && deliveryChannel.payment_data) {
                            saleToEmit.channel = deliveryChannel.payment_data;
                        }
                    }

                    const sellerData = {
                        seller_id: opData.id,
                        seller_name: opData.full_name
                    };

                    if (!saleToEmit.seller_id) {
                        Object.assign(saleToEmit, sellerData);
                    }

                    await this.oldSaleUtils.calculateSaleItemsCosts(saleToEmit);
                    const sale = await this.saveSaleToStorage(saleToEmit);

                    this.sendActiveSaleEvent({ event: 'sale-closed', data: structuredClone(saleToEmit) });

                    this.finishSaleClosing(sale!);

                    Object.assign(result, {
                        emittedSale: saleToEmit,
                        notes: notes
                    });
                } catch (error: any) {
                    result = {
                        error: this.translateService.instant('CASHREGISTER.ACTIVE_SALE.ERROR_WHILE_SAVING_AFTER_PRINT')
                    }
                }
            }
        } catch (msg_error: any) {
            result = {
                printError: msg_error,
                printerId: printerId
            }

            if (isActiveSale && !this.isEInvoiceDocument(docId)) {
                this.saveSaleToStorage();
            }
        }

        if (isActiveSale) {
            this.printDocumentInProgress.set(false);

            if (this.printerDocumentData) {
                this.printerDocumentData.tail = undefined;
            }
        }

        if ('emittedSale' in result) {
            const printNFSaleOptions = {
                isAutoPrint: true
            };

            const isPrintOrdersOnSaleCloseEnabled = !!this.configurationManagerService.getPreference('cashregister.print_orders_on_sale_close');
            const isSaleAPrintableOrder = !saleToEmit.table_id && !['delivery', 'take_away'].includes(saleToEmit.order_type!); //Exclude sales with a table or take away/delivery
            const isSaleAutoPrintable = !!(saleToEmit.auto_print_order && options?.batchOrderMode); //For external sales

            //Set the source cashregister if this sale is not from a kiosk and the auto order print is not enabled. The order will be printed only on the order printers that are linked to the printer that emitted the sale
            if (!isKioskSale && !isPrintOrdersOnSaleCloseEnabled && !isSaleAutoPrintable) {
                Object.assign(printNFSaleOptions, { sourceCashregister: printerId });
            }

            if(isKioskSale || isSaleAutoPrintable || isSaleAPrintableOrder) {
                this.salePrintingUtils.sendSaleAsOrder(result.emittedSale, printNFSaleOptions);
            }
        }

        return result;
    }

    public isCreditNote() {
        return this.saleUtilsService.isCreditNote(this.currentSale);
    }

    public async splitSale(options?: any) {
        if (!this.currentSale.sale_items?.length) {
            return;
        }

        this.lockPaymentButtons.set(true);

        //If we split by amount or covers, will contain the original sale and will be stored
        let saleToStore;

        try {
            //Save the current changes (if any)
            await this.saveSaleToStorage();

            //Show the split dialog
            const splitResult = await this.splitSaleDialog.show(this.currentSale, options);

            if (splitResult.storeCurrentSale) {
                saleToStore = Object.assign(structuredClone(this.currentSale), { notes: "Conto separato" });
            }

            let saleToOpen;

            if(splitResult.originalSaleTransaction) { //There are changes to apply via transactions (split by items)
                await this.entityManagerService.saleTransactions.postOneOfflineFirst(splitResult.originalSaleTransaction);
            } else if(splitResult.originalSale) { //The original sale is changed entirely (split by covers or amount)
                saleToOpen = await this.saveSaleToStorage(splitResult.originalSale);
            }

            if (splitResult.splitSale) {
                saleToOpen = await this.saveSaleToStorage(splitResult.splitSale);
            }

            if(saleToOpen) {
                await this.loadSale(saleToOpen.uuid);
            }
        } catch (err) {

        } finally {
            this.lockPaymentButtons.set(false);

            if (saleToStore) {
                this.performStore(saleToStore, { skipParentCheck: true, closeActiveSale: false });
            }
        }
    }

    /**
     * Moves the items of the current sale to another sale, deletes the current sale and opens the target sale
     * @param saleUuid the uuid of the sale to move the items to
     */
    public async mergeSales(saleUuid: string) {
        //Check if we have an active sale and if it is not the same as the one to merge
        if (!this.isActiveSale() || saleUuid === this.currentSale.uuid) {
            return;
        }

        //Fetch target sale (will be used to get current info like covers)
        const targetSale = (await this.saleTransactionUtilsService.fetchSalesFromTransactions({ uuid: saleUuid }))[0];

        if (!targetSale) {
            return;
        }

        const currentSale = structuredClone(this.currentSale);

        //Create a transaction from the target sale (remove the details ad we don't need them)
        const targetSaleTransaction = this.saleTransactionUtilsService.saleToTransaction(currentSale);
        targetSaleTransaction.skip_printing = true;
        targetSaleTransaction.sale_id = undefined;
        targetSaleTransaction.sale_uuid = saleUuid;
        
        //Configure covers
        const targetSaleCovers = ((targetSale.covers || 0) + (currentSale.covers || 0) || undefined)

        targetSaleTransaction.sale_details = {
            covers: targetSaleCovers
        };

        if(targetSaleCovers) {
            const coverConfig = await this.saleUtilsService.getCoverConfiguration();

            if(coverConfig.type === 'item') {
                const itemId = coverConfig.data.id!;

                //Remove the item from the target sale transaction
                targetSaleTransaction.sale_items_transactions = targetSaleTransaction.sale_items_transactions?.filter((item) => item.sale_item_details?.item_id !== itemId) || [];

                //Check if the item is already in the target sale and, if so, adjust the quantity.
                const existingItem = targetSale.sale_items?.find((item) => item.item_id === itemId);

                if(existingItem) {
                    const coverItemTransaction = this.saleTransactionUtilsService.createSaleItemTransaction(existingItem);
                    coverItemTransaction.quantity_difference = targetSaleCovers - existingItem.quantity;
                    targetSaleTransaction.sale_items_transactions?.push(coverItemTransaction);
                }
            }
        }

        //Cleanup sub entities
        for(const saleItem of targetSaleTransaction.sale_items_transactions || []) {
            for(const entity of ['variations', 'ingredients', 'price_changes']) {
                if(saleItem.sale_item_details?.[entity]) {
                    saleItem.sale_item_details[entity] = this.saleUtilsService.getCleanSubEntity(saleItem.sale_item_details[entity]);
                }
            }
        }
        
        //Remove sale items and save changes of the current sale (save is executed in background)
        this.activeSaleStore.removeSaleItems(currentSale.sale_items || []);

        this.activeSaleStore.saveCurrentTransaction({ onlineFirstMode: true }).finally(() => {
            //Delete the current sale
            this.deleteSale({ skipClosing: true, sale: currentSale });
        });

        //Add the target sale transaction
        await this.entityManagerService.saleTransactions.postOneOfflineFirst(targetSaleTransaction);

        //Load the target sale
        await this.loadSale(saleUuid);
    }

    public isPaymentsOnSaleEmpty() {
        return !(this.currentSale?.payments?.length);
    }

    public removePaymentFromSale(payment: SalesPayments) {
        this.updateSaleDetails({
            payments: this.currentSale?.payments?.filter(p => p !== payment) || []
        });
    }

    public cleanPaymentsFromSale() {
        const currentPayments = this.currentSale?.payments || [];
        const newPayments = currentPayments.filter(p => p.paid);

        if (newPayments.length !== currentPayments.length) {
            this.updateSaleDetails({
                payments: newPayments
            });
        }
    }

    public getToPay() {
        return this.salePayment.getToPay(this.currentSale);
    }

    public getPaid() {
        return this.salePayment.getPaid(this.currentSale);
    }

    public getChange(targetSale: Sales) {
        if (!targetSale) {
            targetSale = this.currentSale;
        }

        return this.salePayment.getSaleChange(targetSale);
    }

    public async processSaleChange() {
        const targetSale = this.currentSale as SalesCashregister;

        if (!targetSale) {
            return;
        }

        this.salePayment.processSaleChange(targetSale);

        //If the sale doesn't have change or we are not processing the current sale, do nothing
        if (!targetSale.change) {
            return;
        }

        //Process left change
        const departments = await this.entityManagerService.departments.fetchCollectionOffline();

        //Prepaid change check
        if (this.connection.isOnline() && this.prepaidSale.isEnabled() && targetSale.sale_customer && validateUuid(targetSale.sale_customer.uuid!) && targetSale.sale_customer.fidelity) {
            const rechargeDepartmentId = parseInt(this.configurationManagerService.getPreference('prepaid.recharge_department_id') || '') || 0;
            const rechargeDepartment = rechargeDepartmentId ? departments.find(department => department.id === rechargeDepartmentId) : undefined;

            if (rechargeDepartment) {
                const answer = await this.openDialogsService.openConfirmDialog({
                    data:
                    {
                        messageLabel: 'CASHREGISTER.ACTIVE_SALE_MODEL.WANT_TO_RELOAD_WITH_CHANGE',
                        messageParams: { value: this.tilbyCurrencyPipe.transform(targetSale.change) },
                        confirmLabel: 'DIALOG.CONFIRM.YES',
                        cancelLabel: 'DIALOG.CONFIRM.NO'
                    }
                });

                if (answer) {
                    await this.addDynamicItemToSale(rechargeDepartment, targetSale.change);

                    delete targetSale.change_type;
                    delete targetSale.change;

                    return;
                }
            }
        }

        if (targetSale.change_type === 'ticket' && this.configurationManagerService.getPreference('fiscalprinter.ticket_change')) {
            const ticketDepartment = departments.find(department => department.vat?.code === 'N2');

            if (ticketDepartment) {
                await this.addDynamicItemToSale(ticketDepartment, targetSale.change, { name: this.translateService.instant('CASHREGISTER.ACTIVE_SALE.TICKET_CHANGE_ITEM_NAME'), not_discountable: true });

                return;
            } else {
                throw 'MISSING_TICKET_CHANGE_DEPARTMENT';
            }
        }

        if (targetSale.change_type === 'ticket') {
            throw 'UNHANDLED_TICKET_CHANGE';
        }
    }

    public async addSignatureToSale() {
        if (!this.currentSale) {
            return;
        }

        const signature = await this.signaturePadDialog.openDialog({
            data: {
                canvasSize: { width: 800, height: 200 },
                showSignatureGuide: true
            }
        });

        if (signature) {
            Object.assign(this.currentSale, { customer_signature: signature });
        } else {
            throw null;
        }
    }

    public async processPayments() {
        if (!this.currentSale || !this.printerDocumentData) {
            return;
        }

        const paymentMethods = await this.entityManagerService.paymentMethods.fetchCollectionOffline();
        const paymentMethodsById = keyBy(paymentMethods, m => m.id);
        const paymentsInSale = [...new Set((this.currentSale.payments || []).map(p => p.payment_method_id))];

        //These payment methods have a signature request inside their payment flow, so we ignore them here
        const paymentsWithSignatureRequest = new Set([28, 36]);

        //Check if a payments requires a customer signature
        const requiresSignature = paymentsInSale.some(methodId => {
            const paymentMethod = paymentMethodsById[methodId];

            return paymentMethod && paymentMethod.require_signature && !paymentsWithSignatureRequest.has(paymentMethod.payment_method_type_id)
        });

        if (requiresSignature) {
            await this.addSignatureToSale();
        }

        this.sendActiveSaleEvent({ event: 'payment-in-progress' });

        return this.salePayment.processPayments(this.currentSale, this.printerDocumentData);
    }

    public hasOnlineDigitalPayment() {
        return this.salePayment.hasOnlineDigitalPayment(this.currentSale)
    }

    public async getCustomerPrepaidInfo() {
        const customer_uuid = this.currentSale.sale_customer?.uuid;
        const customer_fidelity = this.currentSale.sale_customer?.uuid;

        if (!customer_uuid || !customer_fidelity) {
            return;
        }

        const movements = await this.restManager.getList('prepaid_movements', { customer_uuid: customer_uuid, valid_to: 'null', pagination: 'false' }) as ChainPrepaidMovementsRead[];

        if (!Array.isArray(movements) || !movements.length) {
            return {
                credit: 0,
                ticket_credit: 0,
                total_credit: 0
            };
        }

        const lastMovement = movements[0];

        return {
            credit: lastMovement.credit,
            ticket_credit: lastMovement.ticket_credit,
            total_credit: lastMovement.credit + lastMovement.ticket_credit
        };
    }

    public async checkPrepaidPaymentStatus(prepaidCardMethod: PaymentMethods) {
        Object.assign(prepaidCardMethod, { $disabled: true });

        let result = {
            customer_uuid: '',
            $disabled: true
        };

        const customer_uuid = this.currentSale.sale_customer?.uuid;
        const customer_fidelity = this.currentSale.sale_customer?.uuid;

        if (customer_uuid && customer_fidelity) {
            result.customer_uuid = customer_uuid;

            Object.assign(prepaidCardMethod, {
                $info: this.translateService.instant('CASHREGISTER.PAYMENTS.UPDATE_CREDIT')
            });

            try {
                const creditStatus = await this.getCustomerPrepaidInfo();

                if (creditStatus?.total_credit) {
                    result.$disabled = false;
                }

                Object.assign(prepaidCardMethod, {
                    $disabled: result.$disabled,
                    $info: this.translateService.instant('CASHREGISTER.PAYMENTS.CREDIT', { value: this.tilbyCurrencyPipe.transform(creditStatus?.total_credit || 0) })
                });
            } catch (err) {
                Object.assign(prepaidCardMethod, {
                    $info: this.translateService.instant('CASHREGISTER.PAYMENTS.CONNECTION_ERROR')
                });
            }
        } else {
            Object.assign(prepaidCardMethod, {
                $info: this.translateService.instant('CASHREGISTER.PAYMENTS.CUSTOMER_NOT_SELECTED')
            });
        }

        return result;
    }

    public async checkSaleConfirm(skipWarnings?: boolean) {
        let result = this.checkSale();

        if (!result.isValid) {
            let error = result.errors[0];
            let message;

            switch (error) {
                case "SALE_EMPTY":
                case "NEG_AMOUNT_WITH_SALES":
                case "NEG_SALE_ROWS":
                case "REQUEST_WEIGHING":
                    message = `CASHREGISTER.ACTIVE_SALE.${error}`;
                    break;
                default:
                    message = 'CASHREGISTER.ACTIVE_SALE.UNKNOWN_ERROR_CAPITAL';
            }

            await this.openDialogsService.openAlertDialog({data: {messageLabel: message}});

            throw null;
        }

        if (result.hasWarnings && !skipWarnings) {
            for (let warning of result.warnings) {
                let message;

                switch (warning) {
                    case "ZERO_PRICE_ITEMS":
                        message = 'CASHREGISTER.ACTIVE_SALE.ITEMS_WITH_PRICE_ZERO';
                        break;
                    default:
                        message = 'CASHREGISTER.ACTIVE_SALE.UNKNOWN_ERROR_MANAGE_CHECK';
                }

                let result = await this.openDialogsService.openConfirmDialog({data: {messageLabel: message}});

                if (!result) {
                    throw null;
                }
            }
        }
    };
}
