@if({
    lastUuidChanged:lastUuidChanged$|async,
    lastUuidSelected:lastUuidSelected$|async,
}; as data){
    @if(saleUpdates.currentSale){
        <tilby-shop-cart
              [showAllExits]="!!saleUpdates.currentSale.table_id"
              [departments]="cashregisterStateService.departments"
              [exitsArray$]="exitsArray$"
              [groupItemUuid]="activeSaleService.groupItemUuid"
              [lastUuidChanged]="data.lastUuidChanged||undefined"
              [lastUuidSelected]="data.lastUuidSelected||undefined"
              [ingredientsRemovalAffectsPrice]="ingredientsRemovalAffectsPrice"
              [sale]="saleUpdates.currentSale"
              [disableExitStatus]="disableExitStatus"
              [compactMode]="sideKeyboard() ? true : false"
              [priceChangeDescriptions]="priceChangeDescriptions"
              (swipeLeftHandlerEmitter)="swipeLeftHandler($event)"
              (swipeRightHandlerEmitter)="swipeRightHandler($event)"
              (longPressHandlerEmitter)="longPressHandler($event)"
              (removePriceChangeFromSaleItemEmitter)="removePriceChangeFromSaleItem($event)"
              (removePriceChangeFromSaleEmitter)="removePriceChangeFromSale($event)"
              (clickHandlerEmitter)="clickHandler($event)"
              (lastUuidChangedEmitter)="lastUuidChangedEmitterHandler($event)"
              (exitChange)="exitChange($event)"
              (sendExit)="sendExitToPrinter($event)"
              (mousedown)="enableClick()"
        >
            @if(activeSaleService.groupItemUuid){
                <ng-container MenuMode>
                    <div class="order-menu-mode tw-flex tw-justify-between tw-items-center tw-pl-3 tw-w-full tilby-frame-primary tw-sticky">
                        <span class="tw-flex" translate>CASHREGISTER.ACTIVE_SALE.MENU_MODE</span>
                        <button mat-button (click)="activeSaleService.resetGroupItemUuid()" [color]="'primary'"
                                translate="CASHREGISTER.ACTIVE_SALE.MENU_MODE_END"></button>
                    </div>
                </ng-container>
            }
            @if(isMobilePortrait()){
                <app-active-sale-header-info-wrapper Header [sale]="saleUpdates.currentSale" [isPaymentsState]="false"/>
            }
        </tilby-shop-cart>
    }
    @else {
        <div (click)="selectSaleAction()" class="tw-relative tw-flex tw-flex-col tw-h-full tw-px-5 tw-justify-center tw-items-center tw-text-center tw-text-2xl">
            <div class="tw-absolute tw-top-[5%] tw-flex tw-flex-col">
                <div class="tw-font-bold tw-text-2xl">{{ currentTime | async | tilbyDate: 'dd/MM/yyyy' }}</div>
                <div class="tw-font-bold tw-text-6xl">{{ currentTime | async | tilbyDate: 'HH:mm' }}</div>
            </div>
            <div class="tw-flex">
                <div translate>CASHREGISTER.ACTIVE_SALE.SELECT_PRODUCT_TO_CREATE_SALE</div>
            </div>
            <div class="tw-absolute tw-bottom-[5%] tw-flex">
                <img class="tw-h-12" src="assets/images/tilby/Zuc_Orizz_B.svg" alt="Logo Zucchetti">
            </div>
        </div>
    }
}
