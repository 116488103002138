import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {
    AddressesFormGroups,
    CustomersFields,
    CustomersFormGroups,
    CustomerValidators,
    PriceList,
    VatValidateStatus
} from './customers-form.model';
import {FormControl, FormGroup} from '@angular/forms';
import _ from 'lodash';
import {Observable} from 'rxjs';
import {VatInfoResponse} from 'src/app/models';
import {codeToCountry, countryCodesShort} from 'src/utilities';
import { customerUtils } from 'app/ajs-upgraded-providers';
import {ITALY} from "src/app/shared/utils";
import {CustomFormControl} from "@tilby/tilby-ui-lib/components/tilby-magic-form";

let customersFormReference: FormGroup<CustomersFormGroups> | undefined;

interface CurrentShippingAddress {
    shippingForm: string,
    shipping_street: string,
    shipping_number: string,
    shipping_zip: string,
    shipping_city: string,
    shipping_prov: string,
    shipping_country: string
}

@Component({
    selector: 'app-customer-form',
    templateUrl: './customer-form.component.html',
    styleUrls: ['./customer-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerFormComponent {
    @ViewChild('customerForm')customerFormRef: FormGroup<CustomersFormGroups> | undefined;
    vatValidateStatus: VatValidateStatus = 'default';
    Italy = ITALY;
    @Input() customersForm?: FormGroup<CustomersFormGroups>;
    //@Output() customersFormChange = new EventEmitter<FormGroup<CustomersFormGroups>>();
    @Input() customer?: CustomersFields;
    @Input() customTypes?: string[];
    @Input() customFields?: { idx: number, name?: string }[];
    @Input() validators?: CustomerValidators;
    @Input() userCountry?: string;
    @Input() fidelityLabel?: string;
    @Input() paymentMethods?: any[];
    @Input() priceLists?: PriceList[];
    @Input() filteredOptions = new Observable();
    @Output() submit = new EventEmitter<FormGroup<CustomersFormGroups>>();
    @Output() delete = new EventEmitter<void>();
    countryCodesDict?: _.Dictionary<any>;
    @Input() phoneNumber?: string;
    isCustomerTypeIncludes: boolean = true;

    fieldByIdx(i: number, f: any) {
        return f.idx
    }

    shippingAddressRange = _.range(1, 11);

    currentAddress = (val = 1): CurrentShippingAddress => ({
        shippingForm: `shippingForm${(val - 1 ? '_' + (val - 1) : '')}`,
        shipping_street: `shipping_street${(val - 1 ? '_' + (val - 1) : '')}`,
        shipping_number: `shipping_number${(val - 1 ? '_' + (val - 1) : '')}`,
        shipping_zip: `shipping_zip${(val - 1 ? '_' + (val - 1) : '')}`,
        shipping_city: `shipping_city${(val - 1 ? '_' + (val - 1) : '')}`,
        shipping_prov: `shipping_prov${(val - 1 ? '_' + (val - 1) : '')}`,
        shipping_country: `shipping_country${(val - 1 ? '_' + (val - 1) : '')}`
    });
    currentSlot = 1;

    constructor(@Inject(customerUtils) private customerUtilsService: any) {
    }

    ngOnInit() {
        this.customer?.custom_type && (this.isCustomerTypeIncludes = this.customTypes?.includes(this.customer?.custom_type) || false);
        
        if (!this.customer && this.phoneNumber) { this.customersForm?.controls.generalForm.patchValue({ mobile: this.phoneNumber });}
        this.countryCodesDict = _.keyBy(countryCodesShort, 'code')
        customersFormReference = this.customersForm;
    }

    get generalForm() {
        return this.customersForm?.controls.generalForm;
    }

    get addressesForm() {
        return this.customersForm?.controls.addressesForm;
    }

    get optionsForm() {
        return this.customersForm?.controls.optionsForm;
    }

    get addressesBillingForm() {
        return this.addressesForm?.controls.billingForm;
    }

    addressesShippingForm(n = 1) {
        return this.addressesForm?.get('shippingForm' + (n - 1 ? '_' + (n - 1) : '')) as AddressesFormGroups['shippingForm'] | undefined;
    }

    get company_name() {
        return this.generalForm?.controls.company_name;
    }

    get first_name() {
        return this.generalForm?.controls.first_name;
    }

    get last_name() {
        return this.generalForm?.controls.last_name;
    }

    get birthdate() {
        return this.generalForm?.controls.birthdate;
    }

    get tax_code() {
        return this.generalForm?.controls.tax_code as CustomFormControl;
    }

    get vat_code() {
        return this.generalForm?.controls.vat_code;
    }

    get sdi_code() {
        return this.generalForm?.controls.sdi_code;
    }

    get lottery_code() {
        return this.generalForm?.controls.lottery_code;
    }

    get country() {
        return this.generalForm?.controls.country;
    }

    get custom_type() {
        return this.generalForm?.controls.custom_type as CustomFormControl | undefined;
    }

    get phone() {
        return this.generalForm?.controls.phone;
    }

    get mobile() {
        return this.generalForm?.controls.mobile;
    }

    get email() {
        return this.generalForm?.controls.email;
    }

    get email_pec() {
        return this.generalForm?.controls.email_pec;
    }

    get facebook() {
        return this.generalForm?.controls.facebook;
    }

    get twitter() {
        return this.generalForm?.controls.twitter;
    }

    get linkedin() {
        return this.generalForm?.controls.linkedin;
    }

    get instagram() {
        return this.generalForm?.controls.instagram;
    }

    get fidelity() {
        return this.generalForm?.controls.fidelity;
    }

    get discount_perc() {
        return this.generalForm?.controls.discount_perc;
    }

    get billing_street() {
        return this.addressesBillingForm?.controls.billing_street;
    }

    get billing_number() {
        return this.addressesBillingForm?.controls.billing_number;
    }

    get billing_zip() {
        return this.addressesBillingForm?.controls.billing_zip;
    }

    get billing_city() {
        return this.addressesBillingForm?.controls.billing_city;
    }

    get billing_prov() {
        return this.addressesBillingForm?.controls.billing_prov;
    }

    get billing_country() {
        return this.addressesBillingForm?.controls.billing_country;
    }

    get currentShippingAddress() {
        return this.addressesForm?.controls.currentShippingAddress;
    }

    shipping_street(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_street' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_number(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_number' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_zip(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_zip' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_city(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_city' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_prov(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_prov' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_country(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_country' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    searchVat(vatCode?: string | null) {
        if (this.vatValidateStatus === 'default' && vatCode) {
            this.customerUtilsService.getVatInfo(vatCode).then((result: VatInfoResponse) => {
                if (result) {
                    Object.entries(result).forEach(([k, v]: [string, string]) => {
                        if (k.split('_')[0].startsWith('billing')) this.customersForm?.patchValue({addressesForm: {billingForm: {[k]: codeToCountry(k, v) || v}}});
                        else this.customersForm?.patchValue({generalForm: {[k]: codeToCountry(k, v) || v}})
                    });
                    this.vatValidateStatus = 'found';
                } else {
                    this.vatValidateStatus = 'invalid';
                }
            });
        }
    }

    onFidelityScanned($event: string) {
        if ($event) customersFormReference?.patchValue({generalForm: {fidelity: $event}});
        return $event;
    }

    getShippingAddress(slot: number) {
        let slotSuffix;
        let source: CustomersFields;
        let result = '';

        if (slot === this.currentShippingAddress?.value) {
            source = <CustomersFields>this.addressesShippingForm(slot)?.value;
            slotSuffix = '';
        } else {
            source = this.customer || {};
            slotSuffix = slot === 1 ? '' : '_' + (slot - 1);
        }

        if (!_.isEmpty(source[('shipping_street' + slotSuffix) as keyof CustomersFields])) {
            result += ' - ' + source[('shipping_street' + slotSuffix) as keyof CustomersFields] + ' ';
            result += (source[('shipping_number' + slotSuffix) as keyof CustomersFields] || '') + ' ';
            result += (source[('shipping_zip' + slotSuffix) as keyof CustomersFields] || '') + ' ';
            result += (source[('shipping_city' + slotSuffix) as keyof CustomersFields] || '') + ' ';
            result += (source[('shipping_prov' + slotSuffix) as keyof CustomersFields] ? '(' + source[('shipping_prov' + slotSuffix) as keyof CustomersFields] + ')' : '') + ' ';
        }

        return result;
    }

    submitForm() {
        this.customerFormRef?.markAllAsTouched();
        this.submit.emit(this.customersForm);
    }
}
