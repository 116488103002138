import {
    Component,
    EventEmitter,
    inject,
    input,
    Input,
    Output,
    signal
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CashregisterKeypadComponent} from "./cashregister-keypad";
import {DevLogger} from 'src/app/shared/dev-logger';
import {RootScope, util} from "app/ajs-upgraded-providers";
import {ConfigurationManagerService} from "src/app/core";
import {CashregisterStateService} from "../../services/cashregister.state.service";
import {TilbyCurrencyPipe} from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import {
    CustomForm,
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import {Validators} from "@angular/forms";
import {BaseInputButton, GeneralInputButton, LongPressInputButton, MenuItem} from "@tilby/tilby-ui-lib/models";
import {
    Departments,
    Sales
} from 'tilby-models';
import {TilbySaleKeypadComponent, TotalFormValue} from './tilby-sale-keypad';
import { OpenDialogsService } from 'src/app/dialogs';
import {OverridesType} from '../cashregister-showcase';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ActiveSaleService} from 'src/app/features/cashregister/services';
import { subscribeInComponent } from '@tilby/tilby-ui-lib/utilities';
import {TilbyKeypadDisplayComponent} from "./tilby-keypad-display";
import {TilbyGeneralInputButtonComponent} from "@tilby/tilby-ui-lib/components/tilby-buttons";
import {MatButton} from "@angular/material/button";
import {CashregisterSideKeyboardComponent} from "./cashregister-side-keyboard/cashregister-side-keyboard.component";
import { TipDialogService, TIPS_DEFAULT_VALUES } from 'src/app/dialogs/cashregister/tip-dialog';

export type NewItemType = 'deposit_cancellation'|'coupon';
type GiftSaleItemType = 'as_art_15'|'as_gift';
export type KeypadItemModifiers = {
    rows: number,
    price: number,
    quantity: number
};

@Component({
    selector: 'app-cashregister-keyboard',
    standalone: true,
    imports: [CommonModule, CashregisterKeypadComponent, TilbySaleKeypadComponent, TranslateModule, TilbyKeypadDisplayComponent, TilbyGeneralInputButtonComponent, MatButton, CashregisterSideKeyboardComponent],
    templateUrl: './cashregister-keyboard.component.html',
    styleUrls: ['./cashregister-keyboard.component.scss'],
    host:{
        '[class.tw-grid]' :'!sideKeyboard()',
        '[class.tw-grid-cols-5]':'!sideKeyboard()',
        '[class.tw-gap-1]':'!sideKeyboard()',
    },
})
export class CashregisterKeyboardComponent {
    private readonly activeSaleService=inject(ActiveSaleService);
    private readonly configurationManagerService=inject(ConfigurationManagerService);
    private readonly rootScope=inject(RootScope);
    private readonly cashregisterStateService=inject(CashregisterStateService);
    private readonly tilbyCurrencyPipe=inject(TilbyCurrencyPipe);
    private readonly utilService=inject(util);
    private readonly openDialogsService=inject(OpenDialogsService);
    private readonly tipDialogService=inject(TipDialogService);
    private readonly translate=inject(TranslateService);

    @Input() principalActionButton?: BaseInputButton;
    @Input() fastPaymentsButtons: LongPressInputButton[] = [];
    @Input() sale: Sales | undefined;

    isMobile=input(false);
    @Output() addNewItemToSaleEmitter = new EventEmitter<NewItemType>();
    @Output() activateSideKeyboard= new EventEmitter<boolean>();

    protected toggleMainKeyboardButton?:GeneralInputButton;

    public keypadItemModifiers = this.setQuantityModifiers();

    protected form = new CustomFormGroup<CustomForm<TotalFormValue>>({
        cashValue: new CustomFormControl('', {
            validators: Validators.pattern(/^\d+(,\d{1,2})?$/)
        }, {
            ...new CustomFormControlProps(),
            label: 'Total',
            matElementClass: "tw-align-sub tw-text-right",
            readonly: true
        })
    });

    protected buttons: GeneralInputButton[] = [];
    protected sideKeyboard = this.cashregisterStateService.sideKeyboard;

    get cashValueController(){
        return this.form.controls.cashValue;
    }
    get cashValue(){
        return this.cashValueController.value;
    }

    private art15ButtonString = this.configurationManagerService.getPreference("cashregister.art_15_button_string") || 'CASHREGISTER.SHOWCASE.DISCOUNT_ART_15';
    private canChangePrice = this.configurationManagerService.isUserPermitted("cashregister.use_price_changes");//TODO
    private currentCountry = this.configurationManagerService.getShopCountry();
    private canSurcharge = !['FR'].includes(this.currentCountry);
    private giftButtonString = this.configurationManagerService.getPreference("cashregister.gift_button_string") || 'CASHREGISTER.SHOWCASE.GIFT';
    private keypadSetsQuantity = !!this.configurationManagerService.getPreference('cashregister.keyboard.set_quantity');
    private rechargeDepartmentId = parseInt((this.configurationManagerService.getPreference('prepaid.recharge_department_id') || '').toString());
    private showArt15Button = this.currentCountry === 'IT';
    private tipEnabled = signal<boolean>(!!this.configurationManagerService.getPreference('cashregister.tip.enable'));
    protected integerMultiply = !!this.configurationManagerService.getPreference('cashregister.keyboard.integer_multiply');

    private departments = this.cashregisterStateService.departments;

    private saleDepartments: Departments[] = [];
    private itemDepartments: Departments[] = [];

    private destroyRootScopeArray: any[] = [];

    constructor() {
        this.subscriptions();
    }
    //START - LIFECYCLE
    ngOnInit(){
        this.setDepartments();
        this.setButtons();
        this.resetKeypadValue();
    }
    ngOnDestroy() {
        this.destroyRootScopeArray.forEach(onUnsubscribe=>onUnsubscribe?.());
    }
    //END - LIFECYCLE

    private subscriptions() {
        this.destroyRootScopeArray=[
            ...this.destroyRootScopeArray,
            this.rootScope.$on('cashregister-showcase:update-departments', () => this.setDepartments()),
        ];

        subscribeInComponent(ActiveSaleService.activeSaleEvents$, (event) => {
            switch(event.event) {
                case 'sale-opened':
                    this.resetKeypadValue();
                    this.resetQuantityModifiers();
                break;
            }
        });
    }

    protected onFieldChange(totalChanged: string) {
        this.cashValueController.setValue(totalChanged);
        this.cashregisterStateService.keyboardTotalValue.set(this.getKeypadValue());
    }
    private initDepartments() {
        const saleDepartments = this.departments.filter((department) => department.display !== false);
        const itemDepartments = saleDepartments.filter((department) => department.id !== this.rechargeDepartmentId);
        return{
            saleDepartments,
            itemDepartments,
        };
    };
    private setDepartments(){
        ({itemDepartments:this.itemDepartments,saleDepartments: this.saleDepartments}=this.initDepartments());
    }

    private setButtons(){
        this.buttons=this.buttonsCreation();
    }
    private log(...args:any[]){
        DevLogger.log('CashregisterKeyboardComponent',...args);
    }
    //START - BUTTONS
    private buttonsCreation():GeneralInputButton[]{
        const departments = this.enableSaleItemAction()?this.itemDepartments : this.saleDepartments;
        const keyboardButtons = this.sideKeyboard()
            ?[...this.buttonsModifiersColumnsForSideKeyboard(departments)]
            :[...this.buttonsModifiersColumns(),...this.buttonsDepartmentColumns(departments)];
        if(this.sideKeyboard()) {
            this.toggleMainKeyboardButton =keyboardButtons.splice(-1)[0];
        }
        return keyboardButtons.map(btn=>({class:'tw-w-full tw-p-0 tw-rounded-none',...btn}));
    }

    private buttonsModifiersColumnsForSideKeyboard(departments: Departments[]) {
        const menu: GeneralInputButton = this.createDepartmentsMenuButton(departments, 0);

        return [
            this.keypadSetsQuantity ? {
                isIt: signal(true),
                name: '€',
                selected: () => !!this.keypadItemModifiers.price,
                label: signal('€'),
                isDisable: () => (this.enableSaleItemAction() && !this.canChangePrice) || (!this.keypadItemModifiers.price && this.keypadValueIsZero()),
                click: () => this.changeSaleItemPrice()
            } : {
                isIt: signal(true),
                name: 'X',
                selected: () => !!this.keypadItemModifiers.quantity,
                label: signal('X'),
                isDisable: () => !this.keypadItemModifiers.quantity && this.keypadValueIsZero(),
                click: () => this.changeSaleItemQuantity()
            },
            {
                isIt: signal(true),
                name: '- €',
                label: signal('- €'),
                isDisable: () => (!this.canChangePrice || this.keypadValueIsZero()),
                longPress: () => this.addSurchargeFix(),
                click: () => this.addDiscountFix()
            },
            {
                isIt: signal(true),
                name: '- %',
                label: signal('- %'),
                isDisable: () => (!this.canChangePrice || this.keypadValueIsZero()),
                longPress: () => this.addSurchargePerc(),
                click: () => this.addDiscountPerc()
            },
            menu,
            {
                isIt: signal(true),
                name: 'expand_content',
                class: 'material-symbols-outlined tw-flex tw-items-center tw-justify-center tw-text-5xl',
                label: signal('expand_content'),
                click: () => this.activateSideKeyboard.emit(false)
            },
        ]
    }
    private buttonsModifiersColumns(){
        const refundItems:MenuItem<`CASHREGISTER.SHOWCASE.${Uppercase<NewItemType>}`>[] = [
            {index:1,value:'CASHREGISTER.SHOWCASE.DEPOSIT_CANCELLATION'},
            {index:2,value:'CASHREGISTER.SHOWCASE.COUPON'},
        ];

        const giftItems:MenuItem[] = [
            {index:0,value:this.giftButtonString},
            {index:1,value:this.art15ButtonString},
        ];
        const column1: GeneralInputButton[] = [
            {
                isIt: signal(true),
                name: '- %',
                label: signal('- %'),
                isDisable: () => (!this.canChangePrice || this.keypadValueIsZero()),
                longPress: () => this.addSurchargePerc(),
                click: () => this.addDiscountPerc()
            },
            {
                isIt: () => !this.enableSaleItemAction(),
                name: 'refund',
                label: signal('CASHREGISTER.SHOWCASE.REFUND'),
                click: ($event: PointerEvent, menuItem: MenuItem) => menuItem?.value && this.addNewItem(menuItem.value.split('.').pop()?.toLowerCase() as any as NewItemType),
                isMenu: true,
                menuParams: { items: refundItems }
            },
            {
                isIt: () => this.enableSaleItemAction(),
                name: '€',
                label: signal('€'),
                isDisable: () => (!this.canChangePrice || this.keypadValueIsZero()),
                click: () => this.changeSaleItemPrice()
            },
            {
                isIt: signal(true),
                name: 'X',
                selected: () => !!this.keypadItemModifiers.quantity,
                label: signal('X'),
                isDisable: () => !this.keypadItemModifiers.quantity && this.keypadValueIsZero(),
                click: () => this.changeSaleItemQuantity()
            },
        ];
        const column2: GeneralInputButton[] = [
            {
                isIt: signal(true),
                name: '- €',
                label: signal('- €'),
                isDisable: () => (!this.canChangePrice || this.keypadValueIsZero()),
                longPress: () => this.addSurchargeFix(),
                click: () => this.addDiscountFix()
            },
            {
                isIt: () => !this.enableSaleItemAction() && this.tipEnabled(),
                name: 'tip',
                label: signal('CASHREGISTER.SHOWCASE.TIP'),
                click: async () => {
                    try {
                        const tipsValues = JSON.parse(this.configurationManagerService.getPreference('cashregister.tip.predefined_values') || JSON.stringify(TIPS_DEFAULT_VALUES));
                        const tip = await this.tipDialogService.openDialog({ data: { finalAmount: this.activeSaleService.currentSale.final_amount || 0, tipsValues } });
                        if (!tip) return;
                        const department = this.cashregisterStateService.departments.find(department => department.id == tip.department_id);
                        if (!department) return await this.openDialogsService.openAlertDialog({ data: { messageLabel: "DIALOG.TIP.NO_DEPARTMENT" } });
                        await this.activeSaleService.addItemToSale({ ...tip, department }, 1, undefined, undefined, undefined, { notes: tip.notes });
                    }
                    catch (e) {
                        DevLogger.error("ERROR OPENING TIP DIALOG", e);
                    }
                }
            },
            {
                isIt: signal(!this.showArt15Button),
                name: 'gift',
                isDisable: () => (!this.canChangePrice || !this.enableSaleItemAction() || !this.keypadValueIsZero()),
                label: () => this.enableSaleItemAction() ? this.giftButtonString : '',
                click: () => this.makeGiftSaleItem('as_gift'),
            },
            {
                isIt: () => this.showArt15Button && !(this.tipEnabled() && !this.enableSaleItemAction()),
                name: 'gift_menu',
                label: () => this.enableSaleItemAction() ? 'CASHREGISTER.SHOWCASE.GIFT' : '',
                isDisable: () => (!this.canChangePrice || !this.enableSaleItemAction() || !this.keypadValueIsZero()),
                click: ($event: PointerEvent, { index }: MenuItem) => Number.isSafeInteger(index) && this.makeGiftSaleItem(index ? 'as_art_15' : 'as_gift'),
                isMenu: true,
                menuParams: { items: giftItems }
            },
            {
                isIt: signal(true),
                name: 'playlist_remove',
                class: 'tw-w-full tw-rounded-none',
                selected: () => !!this.keypadItemModifiers.rows,
                icon: signal('playlist_remove'),
                isDisable: () => !this.keypadItemModifiers.rows && !this.enableSaleItemAction() && this.keypadValueIsZero(),
                click: () => this.cloneSaleItem()
            },
        ];
        return [...column1,...column2];
    }

    private createDepartmentsMenuButton(departments: Departments[], departmentsAlreadyShown = 5) {
        const departmentsItems: MenuItem[] = departments.slice(departmentsAlreadyShown).map((department, index) => ({ index, value: department.name }));
        const showMenu = departmentsItems.length > 0;

        const menu: GeneralInputButton = {
            isIt: signal(showMenu),
            name: 'other_departments',
            isDisable: () => (this.enableSaleItemAction() !== this.keypadValueIsZero()) || this.isRechargeItemSelected(),
            label: signal('CASHREGISTER.SHOWCASE.OTHER_DEPARTMENTS'),
            click: ($event: PointerEvent, menuItem: MenuItem) => menuItem?.value && this.onDepartmentAction(departments.find(({ name }) => name == menuItem.value)!),
            isMenu: true,
            menuParams: { items: departmentsItems }
        };
        return menu;
    }
    private buttonsDepartmentColumns(departments: Departments[]) {
        const departmentsButtons: GeneralInputButton[] = departments.slice(0, 5).map(department => ({
            isIt: signal(true),
            name: department.name,
            label: signal(department.name),
            isDisable: () => (this.enableSaleItemAction() !== this.keypadValueIsZero()) || this.isRechargeItemSelected(),
            click: () => this.onDepartmentAction(department)
        }));

        const menu: GeneralInputButton = this.createDepartmentsMenuButton(departments);

        return [...departmentsButtons, menu];
    }
    //Button Checks
    private keypadValueIsZero(){
        return !this.getKeypadValue();//TODO
    }
    private enableSaleItemAction() {
        return this.activeSaleService.hasActiveSaleItem()
    }

    private isRechargeItemSelected(){
        return this.activeSaleService.getActiveSaleItem()?.department_id === this.rechargeDepartmentId;
    }
    //Button Handlers
    private addSurchargePerc(){
        if(this.canSurcharge) {
            this.commonPriceChangeFunction(this.activeSaleService.isActiveSaleItem() ? this.activeSaleService.addSaleItemSurchargePerc : this.activeSaleService.addSaleSurchargePerc);
        }
    }
    private addDiscountPerc(){
        this.commonPriceChangeFunction(this.activeSaleService.isActiveSaleItem() ? this.activeSaleService.addSaleItemDiscountPerc : this.activeSaleService.addSaleDiscountPerc);
    }

    private async addNewItem(type: NewItemType) {
        this.addNewItemToSaleEmitter.emit(type);
    }

    private changeSaleItemPrice() {
        const newPrice = this.getKeypadValue();

        if (this.keypadItemModifiers.price && !newPrice) {
            this.resetQuantityModifiers();
        }

        if (newPrice) {
            const saleItem = this.activeSaleService.getActiveSaleItem();

            if (saleItem) {
                this.activeSaleService.editSaleItem(saleItem, { price: this.utilService.round(newPrice / saleItem.quantity) });
            } else {
                this.keypadItemModifiers = this.setQuantityModifiers({ price: newPrice });
            }
        }

        this.resetKeypadValue();
    }
    private changeSaleItemQuantity(){
        let newQuantity = this.getKeypadValue();

        if (this.keypadItemModifiers.quantity && !newQuantity) {
            this.resetQuantityModifiers();
        }

        if(newQuantity > 0) {
            if(this.activeSaleService.isActiveSaleItem()) {
                this.rootScope.$broadcast('active-sale:saleitem-quantity', { quantity: newQuantity });
            } else{
                this.keypadItemModifiers = this.setQuantityModifiers({ quantity: newQuantity });
            }
        } else {
            this.openDialogsService.openAlertDialog({data:{messageLabel:'CASHREGISTER.SHOWCASE.ERROR_QUANTITY_MUST_BE_POSITIVE'}});
        }

        this.resetKeypadValue();
    }
    private addSurchargeFix(){
        if(this.canSurcharge) {
            this.commonPriceChangeFunction(this.activeSaleService.isActiveSaleItem() ? this.activeSaleService.addSaleItemSurchargeFix : this.activeSaleService.addSaleSurchargeFix);
        }
    }
    private addDiscountFix(){
        this.commonPriceChangeFunction(this.activeSaleService.isActiveSaleItem() ? this.activeSaleService.addSaleItemDiscountFix : this.activeSaleService.addSaleDiscountFix);

    }
    private makeGiftSaleItem(type?:GiftSaleItemType){
        try {
            switch(type) {
                case 'as_art_15':
                    const zeroVatDepartment = this.departments.find((department) => (department.vat?.value === 0 && department.vat.code === 'N2' && department.id !== this.rechargeDepartmentId));

                    if(zeroVatDepartment){
                        this.activeSaleService.giftSaleItem(undefined, this.translate.instant('CASHREGISTER.SHOWCASE.DISCOUNT_ART_15_STRING'), zeroVatDepartment);
                    } else {
                        this.openDialogsService.openAlertDialog({data:{messageLabel:'CASHREGISTER.SHOWCASE.ART_15_MISSING_N2_DEPARTMENT'}});
                    }
                    break;
                case 'as_gift':
                    this.activeSaleService.giftSaleItem(undefined, this.translate.instant('CASHREGISTER.SHOWCASE.GIFT'));
                    break;
                default:
                    break;
            }
        } catch(error: any){
            this.openDialogsService.openAlertDialog({ data: { messageLabel: error } });
        }
    }
    private cloneSaleItem(){
        const numClones = this.getKeypadValue();

        if (this.keypadItemModifiers.rows && !numClones) {
            this.resetQuantityModifiers();
        }

        try {
            if(numClones > 100) {
                throw 'ERROR_MULTIPLIER_MUST_BE_LOWER';
            }

            if(!Number.isInteger(numClones)) {
                throw 'ERROR_MULTIPLIER_MUST_BE_INT';
            }

            if(this.activeSaleService.isActiveSaleItem()) {
                this.activeSaleService.cloneSaleItem(undefined, (numClones || 2) - 1);
            } else {
                this.keypadItemModifiers = this.setQuantityModifiers({ rows: numClones });
            }
        } catch(error) {
            switch(error){
                case 'ERROR_MULTIPLIER_MUST_BE_INT':
                case 'ERROR_MULTIPLIER_MUST_BE_LOWER':
                    this.openDialogsService.openAlertDialog({data:{messageLabel:`CASHREGISTER.SHOWCASE.${error}`}});
                    break;
                default:
                    break;
            }
        }

        this.resetKeypadValue();
    }
    private async onDepartmentAction(department: Departments) {
        const saleItem = this.activeSaleService.getActiveSaleItem();

        if(saleItem) {
            this.activeSaleService.editSaleItem(saleItem, {
                department: department
            });
        } else {
            if (!this.keypadValueIsZero()) {
                const quantityModifiers = Object.assign({}, this.keypadItemModifiers);
                const overrides:OverridesType = {};
                const price = this.getKeypadValue();
                const checkPrice = Number.parseInt(this.configurationManagerService.getPreference("cashregister.dynamic_item_price_check")||'');

                if(checkPrice && price >= checkPrice) {
                    const answer = await this.openDialogsService.openConfirmDialog({data:{messageLabel:'CASHREGISTER.ACTIVE_SALE_MODEL.HIGH_VALUE_WARNING', messageParams:{ value: this.tilbyCurrencyPipe.transform(checkPrice) }}});

                    if(!answer) {
                        return;
                    }
                }

                if (quantityModifiers.quantity) {
                    overrides.quantity = quantityModifiers.quantity;
                }

                const saleItem = await this.activeSaleService.addDynamicItemToSale(department, price, overrides);

                if(quantityModifiers.rows) {
                    this.activeSaleService.cloneSaleItem(saleItem, quantityModifiers.rows - 1);
                }

                this.resetKeypadValue();
                this.resetQuantityModifiers();
            }
        }
    }
    //Button Utils
    public getKeypadValue() {
        return parseFloat(this.cashValue.replace(',', '.'));
    }

    public getKeypadValueAndReset() {
        let price = 0;

        if (this.cashValue !== this.tilbyCurrencyPipe.transform(0, '')) {
            price = this.getKeypadValue();
            this.resetKeypadValue();
        }

        return price;
    }

    private setKeypadValue(value: number) {
        this.cashValueController.setValue(this.integerMultiply ? `${value}` : this.tilbyCurrencyPipe.transform(value, ''));
        this.cashregisterStateService.keyboardTotalValue.set(this.getKeypadValue());
    }

    public resetKeypadValue() { this.setKeypadValue(0); }

    private commonPriceChangeFunction(priceChangeFunction: (value: number, description?: string) => void) {
        if (!this.canChangePrice) {
            return;
        }

        if (this.activeSaleService.isActiveSale() && this.cashValue !== this.tilbyCurrencyPipe.transform(0, '')) {
            const pcValue = parseFloat(this.cashValue.replace(',', '.'));

            try {
                priceChangeFunction.apply(this.activeSaleService, [pcValue]);
                this.resetKeypadValue();
            } catch (err) {
                this.openDialogsService.openAlertDialog({ data: { messageLabel: `CASHREGISTER.ACTIVE_SALE_MODEL.${err}` } });
            }
        }
    };
    //END - BUTTONS

    public resetQuantityModifiers():void {
        this.keypadItemModifiers = this.setQuantityModifiers();
    }
    private setQuantityModifiers(modifiers?: Partial<KeypadItemModifiers>): KeypadItemModifiers {
        return {
            rows: modifiers?.rows || 0,
            quantity: modifiers?.quantity || 0,
            price: modifiers?.price || 0
        };
    }
}
