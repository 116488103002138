/**
 *  Tilby Custom Driver for Kube II F
 *
 *  Usage:
 *  CustomRTDriver.setup() and after call public methods:
 *
 *  - autoConfigurePrinter
 *  - printFiscalReceipt
 *  - printCourtesyReceipt
 *  - printNonFiscal
 *  - printOrder
 *  - openCashDrawer
 *  - dailyClosing
 *  - dailyRead
 *
 *  - printFreeNonFiscal
 *
 */

import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { filter, firstValueFrom, map, mergeMap, timeout, TimeoutError } from 'rxjs';
import {
	ConfigurationManagerService,
	EntityManagerService
} from 'src/app/core';
import { ItalanFiscalPrinterDriver, PrintFreeOptions } from 'src/app/shared/model/it-fiscal-printer.model';
import { TilbyTcpSocket, TilbyTcpSocketUtils } from 'src/app/shared/net/tilby-tcp-socket';
import { stringToLines } from 'src/app/shared/string-utils';
import { Printers, Sales } from 'tilby-models';

type printerCommands = Array<string[]>;
type printerResponses = Array<Uint8Array>;

type vatTableEntry = {
	id: number,
	exemption: number,
	name: string,
	value: string
}

type customPrinterSocket = undefined | {
	printer: any,
	messageIndex: number,
	printerGen?: number,
	tcpSocket: TilbyTcpSocket,
};

const defaultVatsTable: Record<number | string, vatTableEntry> = {
	22: { id: 1, value: '2200', exemption: 1, name: "IVA 22%" },
	10: { id: 2, value: '1000', exemption: 1, name: "IVA 10%" },
	4: { id: 3, value: '0400', exemption: 1, name: "IVA 4%" },
	'N4': { id: 6, value: '0000', exemption: 4, name: "IVA Esente ES N4" },
	'N1': { id: 7, value: '0000', exemption: 1, name: "IVA Esente EE N1" },
	'N2': { id: 8, value: '0000', exemption: 2, name: "IVA Esente NS N2" },
	'N3': { id: 9, value: '0000', exemption: 3, name: "IVA Esente NI N3" },
	'N5': { id: 10, value: '0000', exemption: 5, name: "IVA Esente RM N5" },
	'N6': { id: 11, value: '0000', exemption: 6, name: "IVA Esente AL N6" }
};

const errorsTable: Record<number, string> ={
	54: 'FISCAL_CLOSING_NEEDED'
};

const barcodeTypeCodes: Record<string, string> = {
	'EAN13': '01',
	'EAN8': '02',
	'CODE39': '03',
	'EAN128': '04',
	'QRCODE': '06',
	'UPC': '09'
};

const byteArrayToString = (byteArray?: Uint8Array) => byteArray ? Array.from(byteArray).map((val) => String.fromCharCode(val)).join('') : '';

const getLineLength = (line: string, paddingSize: number = 2): string => _.chain(line).size().padStart(paddingSize, '0').value();

const cleanUpSpecialChars = (str: string) => str.replace("€", "E").replace(/[^\x00-\x7F]/g, "*");

const dateToString = (date: string) => moment(date).format('DD/MM/YYYY HH:mm');

export class CustomRTDriver implements ItalanFiscalPrinterDriver {
	private printer: any = null;
	private options: any = null;

	constructor(
		private fiscalUtils: any,
		private util: any,
		private configurationManagerService: ConfigurationManagerService,
		private entityManagerService: EntityManagerService
	) {
	}

	private logEvent(...message: any[]) {
		console.debug('[ CustomRTDriver ]', ...message);
	};

	private getPacket(command: string, index: number) {
		let packetData = [];

		//Index
		packetData.push(_.floor(index / 10) + 0x30, (index % 10) + 0x30);

		//Ident
		packetData.push(0x30);

		//DATA
		for (let i = 0; i < command.length; i++) {
			packetData.push(command.charCodeAt(i));
		}

		//Checksum
		let dataSum = _.sum(packetData) % 100;
		let checkSum = [_.floor(dataSum / 10) + 0x30, dataSum % 10 + 0x30];

		return new Uint8Array([0x02, ...packetData, ...checkSum, 0x03]);
	}

	private async closeSocket(socket: customPrinterSocket): Promise<void> {
		if (socket) {
			await socket.tcpSocket.disconnect();
		}
	}

	private getMaxDepartment (printerInfo: customPrinterSocket): number {
		return 30;
	};

	private async sendCommands(printerSocket: customPrinterSocket, commands: printerCommands): Promise<printerResponses> {
		if (!printerSocket) {
			throw 'INVALID_SOCKET';
		}

		let currentCommand: string;

		const responseData = printerSocket.tcpSocket.onData.pipe(
			//Slice piggy-backed responses in two packets
			map((packet: Uint8Array) => {
				//Happens when ack and data are in the same packet. In this case split the ack and the packet data in two separate packets
				if (packet[0] === 0x06 && packet.length > 1) {
					return [packet.slice(0, 1), packet.slice(1)];
				}

				return [packet];
			}),
			mergeMap((packets: Uint8Array[]) => packets),
			//Ignore ack packets
			filter((packet: Uint8Array) => (packet[0] != 0x06)),
			//Figure out responses to commands
			mergeMap((packet: Uint8Array) => {
				let response = [];

				switch (packet[0]) {
					case 0x02: //Actual command response
						let responseData = packet.slice(4, packet.length - 3);
						let command = byteArrayToString(responseData.slice(0, 4));

						//Throw printer errors
						if (byteArrayToString(responseData.slice(0, 3)) === 'ERR') {
							let errorCode = _.toInteger(byteArrayToString(responseData.slice(3, 5)));

							throw (errorsTable[errorCode] || `CUSTOM.ERROR_${errorCode}`);
						}

						if (command !== currentCommand) {
							break;
						}

						//Throw specific "Already void" error message
						if (command === '7101' && byteArrayToString(responseData.slice(4)) !== '1') {
							throw 'RT_ALREADY_VOID';
						}

						//Otherwise we have a response
						response.push(responseData.slice(4));
						break;
					case 0x15: //NACK: something went wrong, abort
					default: //Everything else (unexpected)
						throw 'COMMUNICATION_ERROR';
						break;
				}

				return response;
			}),
			timeout({ each: 10000 })
		);

		let responses: printerResponses = [];

		try {
			for (let command of commands) {
				let printerCommand = command.join('');
				currentCommand = printerCommand.slice(0, 4);

				//Prepare listening to response and send the command
				let commandResponse = await new Promise<Uint8Array>((resolve, reject) => {
					firstValueFrom(responseData).then(resolve, reject);

					printerSocket.tcpSocket.send(this.getPacket(printerCommand, printerSocket.messageIndex++)).catch(reject);
				});

				responses.push(commandResponse);
			}
		} catch (error) {
			if (error instanceof TimeoutError) {
				throw 'REQ_TIMEOUT';
			}

			throw error
		}

		return responses;
	}

	private async isAvailable(printer: any): Promise<customPrinterSocket> {
		let tcpSocket = TilbyTcpSocketUtils.getSocket();

		if (!tcpSocket) {
			throw 'UNSUPPORTED_ENVIRONMENT';
		}

		try {
			await tcpSocket.connect(printer.ip_address, printer.port || 9100, { timeout: 10000 });
		} catch (error) {
			throw 'CONNECTION_ERROR';
		}

		const printerSocket: customPrinterSocket = {
			printer: printer,
			tcpSocket: tcpSocket,
			messageIndex: 0
		}

		try {
			let printerGenInfo = await this.sendCommands(printerSocket, [['1214']]);
			printerSocket.printerGen = _.toInteger(byteArrayToString(printerGenInfo.shift()));
		} catch (error) {
			this.closeSocket(printerSocket);
			throw error;
		}

		return printerSocket;
	}

	private async connectAndInitializePrinter(printer: any): Promise<customPrinterSocket> {
		let printerSocket: customPrinterSocket;

		try {
			printerSocket = await this.isAvailable(printer);
			await this.clearPendingDocuments(printerSocket);
		} catch (error) {
			this.closeSocket(printerSocket);
			throw error;
		}

		return printerSocket;
	}

	private async clearPendingDocuments(printerSocket: customPrinterSocket): Promise<number> {
		let commandResponses = await this.sendCommands(printerSocket, [['1012']]);
		let pendingDocumentStep = commandResponses.pop()![0] - 0x30;

		const unlockCommands: printerCommands = [];

		if (pendingDocumentStep === 8) {
			//Pending non-fiscal document
			unlockCommands.push(['4004']);
		} else if (pendingDocumentStep > 0) {
			//Pending fiscal document
			if (pendingDocumentStep < 5) {
				unlockCommands.push(
					['3001', '8', '17', 'Annullo scontrino', '000000000'],
					['3011']
				);
			}

			unlockCommands.push(['3013']);
		}

		if (unlockCommands.length) {
			await this.sendCommands(printerSocket, unlockCommands);
		}

		return pendingDocumentStep;
	}

	/**
	 * linesToCommands
	 */
	private linesToCommands(lines: string | string[], options?: PrintFreeOptions): printerCommands {
		if (!Array.isArray(lines)) {
			if (typeof lines === 'string') {
				lines = lines.split("\n");
			}
		}

		// Send CL
		let commands: printerCommands = [['4001', '1', '0', '000000000']];

		for (let line of lines) {
			line = _.truncate(cleanUpSpecialChars(line), { length: 42, omission: '' });
			var lineLength = getLineLength(line);

			commands.push(['4003', '3', lineLength, line]);
		}

		if (options?.barcode) {
			let barcodeType = barcodeTypeCodes[options.barcode.type] || '03';
			commands.push(['3023', barcodeType, '2', '4', getLineLength(options.barcode.value, 3), options.barcode.value])
		}

		commands.push(['4004']);

		return commands;
	}

	private getPrinterVatIdFromDepartment = (department: any): string => {
		if (!department) {
			throw 'OUT_OF_RANGE_VAT';
		}

		let vatId;

		if (department.vat.value === 0) { //In case of exemptions, use the defaultVatsTable
			let vatToUse = defaultVatsTable[department.vat.code || 'N4'];

			if (!vatToUse) {
				throw 'OUT_OF_RANGE_VAT'
			}

			vatId = vatToUse.id;
		} else {
			if (!_.inRange(department.vat.id, 1, 6)) {
				throw 'OUT_OF_RANGE_VAT';
			}

			vatId = department.vat.id
		}

		return _.padStart(vatId, 2, '0');
	}

	private getRowTypeFromSaleItem(saleItem: any, isRefunding: boolean): string {
		switch (saleItem.type) {
			case 'sale':
				return '1';
			case 'refund':
				return isRefunding ? '1' : '4';
			case 'gift':
				return 'G';
			case 'deposit_cancellation':
				return 'F';
			default:
				return '1';
		}
	}

	/**
	 *  saleToReceiptCommands
	 */
	private saleToReceiptCommands(printerInfo: customPrinterSocket, sale: Sales, options: any, departments: any): printerCommands {
		const printerMaxDepartments = this.getMaxDepartment(printerInfo);

		if(sale.sale_items?.some((saleItem) => (saleItem.department_id || 0) > printerMaxDepartments)) {
			throw "NOT_ENOUGH_DEPARTMENTS";
		}

		const printNotes = (this.options.print_notes === false) ? false : true;
		const printBarcodeId = (this.options.print_barcode_id === false) ? false : true;
		const ticketChange = (this.options.ticket_change === false) ? false : true;
		const printCustomerDetail = (this.options.print_customer_detail === false) ? false : true;
		const printSeller = (this.options.print_seller === false) ? false : true;

		const commands: printerCommands = [['3016', '1']]; //TODO: clear printer before sending sale commands
		const departmentsById = _.keyBy(departments, 'id');

		let isRefunding = false;

		const addTextLine = (text: string) => {
			let textToAdd = _.truncate(text, { length: 32, omission: '' });
			commands.push(['3002', '1', getLineLength(textToAdd), textToAdd]);
		};

		if (sale.final_amount! < 0) {
			const docDataItem = sale.sale_items?.find((saleItem: any) => (saleItem.reference_sequential_number && saleItem.reference_date));

			if (!docDataItem) {
				throw 'MISSING_REFERENCE_DOCUMENT';
			}

			let docData = {
				reference_sequential_number: docDataItem.reference_sequential_number,
				reference_date: docDataItem.reference_date
			};

			if (!_.every(sale.sale_items, docData)) { //If not every item is from the same document
				throw 'ITEMS_FROM_DIFFERENT_DOCUMENTS'
			}

			//Check if we are doing a void or a refund
			let hasVoid = false;
			let hasRefund = false;

			for (let saleItem of (sale.sale_items || [])) {
				if (saleItem.refund_cause_id === 6) {
					hasVoid = true;
				} else {
					hasRefund = true;
				}
			}

			if (hasRefund && hasVoid) {
				throw 'MIXED_REFUND_CAUSES';
			}

			let docSeqNum = this.fiscalUtils.parseRTDocumentSequentialNumber(docData.reference_sequential_number);
			let documentDate = moment(docData.reference_date).format('DDMMYY');

			if (hasVoid) {
				commands.push(['7101', 'A', _.padStart(docSeqNum.daily_closing_num, 4, '0'), _.padStart(docSeqNum.document_sequential_number, 4, '0'), documentDate, '0']);
				commands.push(['7101', 'A', _.padStart(docSeqNum.daily_closing_num, 4, '0'), _.padStart(docSeqNum.document_sequential_number, 4, '0'), documentDate, '1']);

				return commands;
			} else {
				commands.push(['7101', 'R', _.padStart(docSeqNum.daily_closing_num, 4, '0'), _.padStart(docSeqNum.document_sequential_number, 4, '0'), documentDate, '0']);
				commands.push(['7101', 'R', _.padStart(docSeqNum.daily_closing_num, 4, '0'), _.padStart(docSeqNum.document_sequential_number, 4, '0'), documentDate, '1']);
				isRefunding = true;
			}
		} else {
			// Sale Name
			const printName = (this.options.print_name === false) ? false : true;

			if(printName) {
				for(let row of this.fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
					addTextLine(`# ${row}`);
				}
			}
		}

		const printDetails = (options.print_details === false && sale.final_amount! >= 0) ? false : true;

		// Sale items
		if (printDetails || isRefunding) {
			for (let saleItem of this.fiscalUtils.extractSaleItems(sale)) {
				const siName = _.truncate(saleItem.name || saleItem.department_name, { length: 44 });
				const department = departmentsById[saleItem.department_id];
				const departmentCode = _.padStart(saleItem.department_id, 3, '0');
				const vatId = this.getPrinterVatIdFromDepartment(department);

				commands.push([
					'3301',
					this.getRowTypeFromSaleItem(saleItem, isRefunding),
					_.padStart(Math.abs(Math.round(saleItem.quantity * 1000)).toString(), 9, '0'),
					departmentCode,
					getLineLength(siName),
					siName,
					_.padStart(Math.round(saleItem.price * 100).toString(), 9, '0'),
					vatId
				]);

				if (!isRefunding) {
					// Item barcode (if print_notes)
					if (printNotes && saleItem.barcode) {
						if (saleItem.barcode.toLowerCase().indexOf('p') < 0 && saleItem.barcode.toLowerCase().indexOf('q') < 0) {
							addTextLine(`# ${saleItem.barcode.trim()}`);
						}
					}

					// Notes
					if (printNotes && saleItem.notes) {
						for (let noteLine of saleItem.notes.split("\n")) {
							let nl = noteLine.trim();
							if (nl) {
								addTextLine(`# ${cleanUpSpecialChars(nl)}`);
							}
						}
					}

					// Refund cause
					if (saleItem.quantity < 0 && saleItem.refund_cause_description) {
						addTextLine(`# ${cleanUpSpecialChars(saleItem.refund_cause_description)}`);
					}

					// Discount / Surcharges
					// - Sort discount/surcharges by index
					if (saleItem.quantity > 0) {
						// Discount/Surcharges
						let partialPrice = this.fiscalUtils.roundDecimals(saleItem.price * saleItem.quantity);

						for (let priceChange of _.sortBy(saleItem.price_changes, 'index')) {
							let pcAmount = this.fiscalUtils.getPriceChangeAmount(priceChange, partialPrice);

							if (!_.isNil(pcAmount)) {
								partialPrice = this.fiscalUtils.roundDecimals(partialPrice + pcAmount);

								switch (priceChange.type) {
									case 'gift':
										commands.push(['3001', 'G', '00', '000000000']);
										break;
									default:
										let adjustmentType = pcAmount < 0 ? '3' : '2';
										let pcDesc = _.truncate(priceChange.description, { length: 44 });

										commands.push([
											'3301',
											adjustmentType,
											'000000000',
											departmentCode,
											getLineLength(pcDesc),
											pcDesc,
											_.padStart(Math.round(Math.abs(pcAmount) * 100).toString(), 9, '0'),
											vatId
										]);
										break;
								}
							}
						}

						if (saleItem.type === 'gift') {
							addTextLine("# Omaggio");
						}
					}
				}
			}
		} else { // Hide details
			const departmentTotals = this.fiscalUtils.extractDepartments(sale);

			for (let index in departmentTotals) {
				const depTotal = departmentTotals[index];

				const department = departmentsById[depTotal.id];
				const departmentCode = _.padStart(depTotal.id, 3, '0');
				const depName = _.truncate(depTotal.name, { length: 44, omission: '' });

				commands.push([
					'3301',
					'1',
					'000001000',
					departmentCode,
					getLineLength(depName),
					depName,
					_.padStart(Math.round(depTotal.amount * 100).toString(), 9, '0'),
					this.getPrinterVatIdFromDepartment(department)
				]);
			}
		}

		if (!isRefunding) {
			// Print subtotal
			commands.push(['3003']);

			// Apply discount/surcharges on subtotal
			if (sale.final_amount! >= 0 && printDetails) {
				let partialPrice = this.fiscalUtils.roundDecimals(sale.amount);
				let hasAddedPriceChange;

				for (let priceChange of _.sortBy(sale.price_changes, 'index')) {
					let pcAmount = this.fiscalUtils.getPriceChangeAmount(priceChange, partialPrice);

					if (!_.isNil(pcAmount)) {
						partialPrice = this.fiscalUtils.roundDecimals(partialPrice + pcAmount);
						let adjustmentType = pcAmount < 0 ? '3' : '2';
						let pcDesc = _.truncate(priceChange.description, { length: 44 });
						commands.push(['3001', adjustmentType, getLineLength(pcDesc), pcDesc, _.padStart(Math.round(Math.abs(pcAmount) * 100).toString(), 9, '0')]);
						hasAddedPriceChange = true;
					}
				}

				if (hasAddedPriceChange) {
					commands.push(['3003']);
				}
			}

			// Payments
			if (sale.final_amount! >= 0) {
				//Add lottery code if available
				if (sale.lottery_code) {
					commands.push(['3019', _.chain(sale.lottery_code).size().padStart(2, '0').value(), sale.lottery_code]);
				}

				const payments = this.fiscalUtils.extractPayments(sale);

				const itemsBySalesType = _.groupBy(sale.sale_items, (saleItem) => departmentsById[saleItem.department_id]?.sales_type);
				//Use unclaimed (goods) if the sale only contains goods, otherwise use unclaimed (services)
				const unclaimedType = (itemsBySalesType.goods?.length && !itemsBySalesType.services?.length) ? '07' : '50';

				for (let payment of payments) {
					const paymentName = _.truncate(cleanUpSpecialChars(payment.method_name), { length: 22, omission: '' });
					let paymentCode;

					//Assign payment type
					switch (payment.method_type_id) {
						case 1: case 19: case 21: case 32: case 38: case 39: case 40:
							paymentCode = '01'; //Cash
							break;
						case 3:
							paymentCode = '02'; //Cheque
							break;
						case 4: case 5: case 8: case 11: case 13: case 14: case 15: case 17: case 18: case 27: case 30: case 31: case 35: case 37:
							paymentCode = '03'; //Credit card
							break;
						case 6: case 34:
							paymentCode = '05'; //Ticket
							break;
						case 2:
							paymentCode = unclaimedType;
							break;
						case 22: case 23: case 24: case 28: case 29: case 36: case 41:
							paymentCode = '51';
							break;
						case 25:
							paymentCode = '52';
							break;
						case 10: case 26: case 33:
							paymentCode = '53'; //Discount on payment
							break;
						case 16:
							paymentCode = payment.unclaimed ? unclaimedType : '08';
							break;
						default:
							paymentCode = '01';
							break;
					}

					commands.push([
						'3007',
						paymentCode,
						'01',
						getLineLength(paymentName),
						paymentName,
						_.padStart(Math.round(payment.amount * 100).toString(), 9, '0')
					]);
				}
			}

			// Barcode
			if (printBarcodeId && Number.isInteger(sale.id)) {
				const saleIdBarcode = sale.id!.toString();
				commands.push(['3021', '3', '2', '4', '0', getLineLength(saleIdBarcode), cleanUpSpecialChars(saleIdBarcode)]);
			}

			const customerTaxCode: string | undefined = sale.sale_customer?.tax_code || sale.customer_tax_code;

			if (customerTaxCode && this.fiscalUtils.checkTaxCode(customerTaxCode)) {
				commands.push(['3010', 'B', getLineLength(customerTaxCode), customerTaxCode]);
			}

			//Close fiscal document
			commands.push(['3011']);

			const addTextLine = (text: string) => {
				let textToAdd = cleanUpSpecialChars(_.truncate(text, { length: 32, omission: '' })) || _.repeat(' ', 32);

				commands.push(['3012', '1', getLineLength(textToAdd), textToAdd]);
			};

			// Ticket change
			if (ticketChange && sale.change! > 0 && sale.change_type === 'ticket') {
				const ticketChangeAmount = this.util.round(sale.change);

				addTextLine(`######### RESTO TICKET #########`);
				addTextLine(`                                `);
				addTextLine(` QUESTO COUPON VALE: ${ticketChangeAmount.toFixed(2)} EURO`);
				addTextLine(`                                `);
				addTextLine(` Presenta alla cassa prima del  `);
				addTextLine(` pagamento questo coupon per    `);
				addTextLine(` avere riconosciuto il credito. `);
				addTextLine(` Valido 30 giorni dalla data    `);
				addTextLine(` di emissione.                  `);
				addTextLine(` Non convertibile in denaro.    `);
				addTextLine(`                                `);

				const ticketBarcode = _.toString(1212000000000 + Math.round(ticketChangeAmount * 100));
				commands.push(['3023', '01', '2', '4', getLineLength(ticketBarcode, 3), cleanUpSpecialChars(ticketBarcode)]);

				addTextLine(`                                `);
				addTextLine(`################################`);
				addTextLine(`                                `);
			}

			// Add Tail (tail for this sale + general tail)
			let tail = options.tail || '';

			if (this.options.tail) {
				tail += "\n" + this.options.tail;
			}

			if (tail) {
				const tails = tail.split('\n');

				for (let tRow of tails) {
					addTextLine(tRow);
				}
			}

			// Customer
			if (printCustomerDetail && sale.sale_customer) {
				for (let line of this.fiscalUtils.getCustomerInfo(sale.sale_customer)) {
					addTextLine(line);
				}
			}

			// Print Seller
			if (printSeller && sale.seller_name) {
				addTextLine(`${this.options.seller_prefix || "Ti ha servito"} ${sale.seller_name.trim().split(" ")[0]}`);
			}

			// Payment tail */
			if (options.tail && options.tail.indexOf('FIRMA') > -1) {
				// Add Payment Tail
				for (let tRow of options.tail.split('\n')) {
					addTextLine(tRow);
				}
			}

			commands.push(['3013']);
		} else {
			commands.push(['3011'], ['3013']);
		}

		return commands;
	}

	/**
	 * @description saleToNonFiscalCommands
	 */
	private saleToNonFiscalCommands(sale: any, options: any) {
		const commands: printerCommands = [];
		const isRefundVoid = this.fiscalUtils.isRefundVoidSale(sale);

		const savePaper = !!this.configurationManagerService.getPreference('cashregister.save_paper_on_prebill');
		const printerColumns = this.printer.columns || 42;
		const printName = (savePaper || this.options.print_name === false) ? false : true;
		const openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;
		const printDetails = (options.print_details === false) ? false : true;
		const printNotes = (this.options.print_notes === false) ? false : true;

		commands.push(['4001', '1', '0', '000000000']);

		const addLine = (line: string) => {
			let l = _.truncate(cleanUpSpecialChars(line), { length: 42, omission: '' });
			let lineLength = getLineLength(l);

			commands.push(['4003', '3', lineLength, l]);
		};

		if(printName) {
			for(let row of this.fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
				addLine(row);
			}
		}

		// Sale items
		if (printDetails) {
			for (let saleItem of this.fiscalUtils.extractSaleItems(sale)) {
				let rowDescription = saleItem.quantity + "x " + (saleItem.name || saleItem.department_name);
				let rowPrice = this.fiscalUtils.decimalToString(saleItem.price * saleItem.quantity);
				let row = _.padEnd(_.truncate(rowDescription, { length: (printerColumns - rowPrice.length - 2) }), printerColumns - rowPrice.length, " ") + rowPrice;

				addLine(row);

				// Notes
				if (printNotes && saleItem.notes) {
					let notesLines = stringToLines(saleItem.notes, printerColumns);

					for (let noteLine of notesLines) {
						let nl = noteLine.trim();

						if (nl) {
							addLine(nl);
						}
					}
				}

				// Discount/Surcharges
				let partialPrice = this.fiscalUtils.roundDecimals(saleItem.price * saleItem.quantity);

				for (let priceChange of _.sortBy(saleItem.price_changes, 'index')) {
					let pcAmount = this.fiscalUtils.getPriceChangeAmount(priceChange, partialPrice);
					partialPrice = this.fiscalUtils.roundDecimals(partialPrice + pcAmount);

					if (!_.isNil(pcAmount)) {
						let rowDescription = priceChange.description;
						let rowAmount = (_.startsWith(priceChange.type, 'surcharge') ? '+' : '') + pcAmount.toFixed(2).replace(".", ",");
						let row = _.padEnd(_.truncate(rowDescription, { length: (printerColumns - rowPrice.length - 2) }), printerColumns - rowAmount.length, " ") + rowAmount;

						addLine(row);
					}
				}
			}
		}

		// Sub-total and his discount/surcharges
		let subTotDescr = "SUBTOTALE";
		let subTotAmount = this.fiscalUtils.decimalToString(sale.amount);

		addLine(_.padEnd(subTotDescr, printerColumns - subTotAmount.length, " ") + subTotAmount);

		// Apply discount/surcharges on subtotal
		if (!isRefundVoid && printDetails) {
			let partialPrice = this.fiscalUtils.roundDecimals(sale.amount);

			for (let priceChange of _.sortBy(sale.price_changes, 'index')) {
				let pcAmount = this.fiscalUtils.getPriceChangeAmount(priceChange, partialPrice);
				partialPrice = this.fiscalUtils.roundDecimals(partialPrice + pcAmount);

				if (!_.isNil(pcAmount)) {
					let rowDescription = priceChange.description;
					let rowAmount = (_.startsWith(priceChange.type, 'surcharge') ? '+' : '') + pcAmount.toFixed(2).replace(".", ",");
					let row = _.padEnd(rowDescription, printerColumns - rowAmount.length, " ") + rowAmount;

					addLine(row);
				}
			}
		}

		let totDescription = 'TOTALE EURO';
		let totAmount = this.fiscalUtils.decimalToString(sale.final_amount);
		let totRow = _.padEnd(totDescription, printerColumns - totAmount.length, " ") + totAmount;

		addLine(totRow);

		/*
			No data about:
			- payments
			- customerDetail
		*/

		// Add Tail (tail for this sale + general tail)
		const tailRows = [];

		if (_.isString(options.tail)) {
			tailRows.push('');

			for (let line of stringToLines(options.tail, printerColumns)) {
				tailRows.push(line);
			}
		}

		if (_.isString(this.options.tail)) {
			tailRows.push('');

			for (let line of stringToLines(this.options.tail, printerColumns)) {
				tailRows.push(line);
			}

			tailRows.push('');
		}

		for (let row of tailRows) {
			addLine(row);
		}

		if(!savePaper) {
			addLine("");
			addLine(_.pad("RITIRARE LO SCONTRINO", printerColumns, ' '));
			addLine(_.pad("FISCALE ALLA CASSA", printerColumns, ' '));
			addLine("");
		}

		if (this.options.print_sale_qr_code) {
			const qrCode = `s_uid=${sale.uuid}`;
			commands.push(['3023', barcodeTypeCodes.QRCODE, '0', '4', getLineLength(qrCode, 3), qrCode]);
		}

		commands.push(['4004']);

		// Open cash drawer
		if (openCashdrawer) {
			commands.push(['7008', '1']);
		}

		return commands;
	}

	/**
	 * orderToCommands
	 */
	private orderToCommands(order: any) {
		const printerColumns = this.printer.columns || 42;
		const lines = [];

		const border = (car = '='): string => new Array(printerColumns).fill(car).join('');

		// Border
		lines.push(border());

		// Alert
		lines.push(`ATTENZIONE: Si e' verificato un`);
		lines.push(`problema di connettivita' al`);
		lines.push(`cam: ${order.operator_name}`);
		lines.push(`Si prega di inserire manualmente`);
		lines.push(`i dati della comanda sulla `);
		lines.push(`schermata di cassa.`);

		// Border
		lines.push(border());

		// Ordername
		if (order.name) {
			lines.push(order.name);
		}

		// Date/Time and #
		let dateNumber = (order.open_at ? dateToString(order.open_at) : "") + (order.order_number ? (` #${order.order_number}`) : "");
		lines.push(dateNumber);

		// Delivery Date/Time
		if (order.deliver_at) {
			lines.push(`Da consegnare: ${dateToString(order.deliver_at)}`);
		}

		// Room name & Table name
		if (order.room_name && order.table_name) {
			lines.push(`${order.room_name} ${order.table_name}`);
		}

		// Covers
		if (order.covers) {
			lines.push(`Numero di coperti: ${order.covers}`);
		}

		// Customer
		if (order.order_customer) {
			let customerName = this.util.getCustomerCaption(order.order_customer);

			if (customerName) {
				lines.push(`Cliente: ${customerName}`);
			}
		}

		// Border
		lines.push(border());

		//lines.push("Q.TA' / DESCRIZIONE         EURO"));
		var itemsHeader = "Q.TA' / DESCRIZIONE";

		//console.log("Ciao:"+(printerColumns - itemsHeader.length - 4));

		var max = printerColumns - itemsHeader.length - 4;

		for (var i = 0; i < max; i++) {
			itemsHeader += " ";
		}
		itemsHeader += 'EURO';

		lines.push(itemsHeader);
		lines.push('');

		// Order_items
		for (let orderItem of order.order_items) {
			var itemLine = orderItem.quantity + "x " + orderItem.name;
			var rowPrice = (Math.round((orderItem.price * orderItem.quantity) * 100) / 100).toFixed(2).replace(".", ",");

			for (let k = itemLine.length; k < (printerColumns - rowPrice.length); k++) {
				itemLine += " ";
			}

			itemLine += rowPrice;
			lines.push(itemLine);

			// Ingredients
			if (Array.isArray(orderItem.ingredients)) {
				for (let ingredient of orderItem.ingredients) {
					let ingredientRow = '';

					if (ingredient.type === 'added') {
						ingredientRow += "  + ";
					} else if (ingredient.type === 'removed') {
						ingredientRow += "  - ";
					}

					ingredientRow += cleanUpSpecialChars(ingredient.name);

					if (ingredient.price_difference) {
						let iPrice = (Math.round((ingredient.price_difference * orderItem.quantity) * 100) / 100).toFixed(2).replace(".", ",");

						for (let k = ingredientRow.length; k < (printerColumns - iPrice.length); k++) {
							ingredientRow += " ";
						}

						ingredientRow += iPrice;
					}

					lines.push(cleanUpSpecialChars(ingredientRow));
				}
			}

			// Variations
			if (Array.isArray(orderItem.variations)) {
				for (let variation of orderItem.variations) {
					let variationRow = `  ${variation.name}: ${variation.value}`;

					if (variation.price_difference) {
						let iPrice = (Math.round((variation.price_difference * orderItem.quantity) * 100) / 100).toFixed(2).replace(".", ",");

						for (let k = variationRow.length; k < (printerColumns - iPrice.length); k++) {
							variationRow += " ";
						}

						variationRow += iPrice;
					}
					lines.push(cleanUpSpecialChars(variationRow));
				}
			}
		}

		// Border
		lines.push(border("-"));

		// Amount
		const totAmount = (Math.round(order.amount * 100) / 100).toFixed(2).replace(".", ",");
		let totString = 'T0TALE EURO';

		for (let k = totString.length; k < (printerColumns - (totAmount.length)); k++) {
			totString += ' ';
		}

		totString += totAmount;
		lines.push(totString);

		return this.linesToCommands(lines);
	}

	/**
	 * saleToCourtesyReceiptCommands
	 */
	private saleToCourtesyReceiptCommands(sale: any, options: any) {
		const lines = [];
		let courtesyReceiptOptions: any = {};

		lines.push("SCONTRINO DI CORTESIA");
		lines.push("");
		lines.push(sale.name);
		lines.push("");

		// Items
		if (Array.isArray(sale.sale_items)) {
			for (let saleItem of sale.sale_items) {
				lines.push(saleItem.quantity + "x " + cleanUpSpecialChars(saleItem.name || saleItem.department_name));
			}
		}

		if (Number.isInteger(sale.id)) {
			courtesyReceiptOptions.barcode = {
				type: 'CODE39',
				value: sale.id.toString()
			}
		}

		return this.linesToCommands(lines, courtesyReceiptOptions);
	}

	/**
	 *  configurationToCommands
	 *  @param like autoConfigurePrinter parameters
	 */
	private async configurationToCommands(printerSocket: customPrinterSocket, options: any) {
		const resources = await this.fiscalUtils.getPrinterConfigurationResources();
		let commands: printerCommands = [];

		// Configure headers
		for (let i = 1; i <= 9; i++) {
			const headerRow = _.chain(options[`header${i}`] || '').thru(cleanUpSpecialChars).truncate({ length: 44, omission: '' }).value();
			commands.push(['6302', i.toString(), '1', getLineLength(headerRow), headerRow]);
		}

		// Vat setup (only non-exemptions, limited to vats from id 1 to 5 since the printer can only save 5 VATs)
		const vatsToConfigure = resources.vats.filter((vat: any) => (vat.value != 0 && vat.code == null));
		const vatsById: any = {};

		for (let vat of vatsToConfigure) {
			if (_.inRange(vat.id, 1, 6) && _.isEmpty(vat.code)) {
				//keep VAT in dictionary to use it on the departments configuration
				vatsById[vat.id] = vat;

				const vatName = `IVA ${vat.value.toString()}%`;
				commands.push(['7113', _.padStart(vat.id, 2, '0'), _.padStart(Math.round(vat.value * 100).toString(), 4, '0'), '1', getLineLength(vatName), vatName]);
			}
		}

		const maxDepartments = this.getMaxDepartment(printerSocket);

		// Departments setup
		for (let department of resources.departments) {
			if(department.printer_code > maxDepartments) {
				this.logEvent(`Department with code ${department.printer_code} is out of range`);
				continue;
			}

			const depName = _.chain(department.name).thru(cleanUpSpecialChars).truncate({ length: 16, omission: '' }).value();
			let vatValue;

			switch (printerSocket!.printerGen) {
				case 2:
				case 4:
					vatValue = department.vat.value;
					break;
				case 3:
					const vatIndex = (department.vat.value === 0) ? (department.vat.code || 'N2') : department.vat.id;
					const vatTable = (department.vat.value === 0) ? defaultVatsTable : vatsById
					vatValue = vatTable[vatIndex]?.id;
					break;
			}

			if (vatValue == null) {
				throw 'OUT_OF_RANGE_VAT';
			}

			commands.push(['7009', _.padStart(department.printer_code, 2, '0'), getLineLength(depName), depName, '00010', _.padStart(vatValue, 2, '0')]);
		}

		// Display message
		if (options.display_message) {
			const displayMessage = _.truncate(cleanUpSpecialChars(options.display_message), { length: 48, omission: '' });

			commands.push(['7010', '0', getLineLength(displayMessage), displayMessage]);
		}

		return commands;
	}

	private async lastReceiptInfo(printerSocket: customPrinterSocket) {
		let result = {};

		//Obtain metadata about last emitted receipt
		let receiptsData = await this.sendCommands(printerSocket, [['1001'], ['1008'], ['1003'], ['1104']]);
		const date = moment(byteArrayToString(receiptsData.shift()), 'DDMMYYHHmm');
		const serialResponse = receiptsData.shift();
		const receiptNumber = parseInt(byteArrayToString(receiptsData.shift()?.slice(56, 60)));
		const dailyClosingNumber = parseInt(byteArrayToString(receiptsData.shift()?.slice(0, 4))) + 1;

		//Find last emitted receipt in the DGFE
		let receiptLines: string[];

		try {
			const receiptNumberString = receiptNumber.toString().padStart(4, '0');
			let receiptDgfeLocationResp = await this.sendCommands(printerSocket, [['8006', '0000', receiptNumberString, receiptNumberString]]);
			let receiptDgfeLocation = receiptDgfeLocationResp.shift();
			receiptLines = await this.readDgfeLines(printerSocket, parseInt(byteArrayToString(receiptDgfeLocation?.slice(0, 9))), parseInt(byteArrayToString(receiptDgfeLocation?.slice(9, 18))) - 1);
		} catch (err) {
			receiptLines = [];
		}

		Object.assign(result, {
			printer_serial: `${byteArrayToString(serialResponse?.slice(0, 2))} ${byteArrayToString(serialResponse?.slice(2))}`,
			sequential_number: _.toInteger((dailyClosingNumber * 10000) + receiptNumber),
			document_content: receiptLines.join('\n') || null,
			date: date.toISOString()
		});

		return result;
	}

	private async readDgfeLines(printerSocket: customPrinterSocket, lineFrom: number, lineTo: number): Promise<string[]> {
		const result: string[] = [];

		if (Number.isInteger(lineFrom) && Number.isInteger(lineTo) && lineTo != 0 && lineFrom <= lineTo) {
			for (let i = lineFrom; i <= lineTo; i++) {
				let response = await this.sendCommands(printerSocket, [['8004', i.toString().padStart(9, '0')]]);
				let row = byteArrayToString(response[0]);

				result.push(row);
			}
		}

		return result;
	}

	/**
	 *  Public methods
	 */
	/**
	 * @desc setup RCH Print!F printer
	 *
	 * @param printer, the tilby fiscal printer (must be an RCH Print!F obviously)
	 * @param options.print_notes: boolean (default true), if true prints the non-fiscal lines above each sale_item (for ex. variations, or combinations)
	 * @param options.print_name: boolean (default true), if true prints the sale.name on top
	 * @param options.print_barcode_id: boolean (default true), if true, prints a barcode on bottom that contains the sale.id
	 * @param options.ticket_change: boolean (default true), if true enables ticket change
	 * @param options.print_customer_detail: boolean (default true), if true print TAX_CODE, FIRST_NAME, LAST_NAME, COMPANY_NAME, VAT_CODE, FIDELITY
	 * @param options.print_seller: boolean (default true), if true print seller
	 * @param options.seller_prefix: string, customize seller phrase
	 * @param options.tail: string, general tail for the receipts
	 *
	 * @param printer.id: integer, the printer id
	 * @param printer.name: string, the printer name
	 * @param printer.driver: string, must be 'rch in this case'
	 * @param printer.connection_type: string, can be 'ws' (for webservice mode) or 'bt' (for bluetooth mode)
	 * @param printer.ip_address: string, printer IP (if using webservice mode)
	 * @param printer.mac_address_bt: string, printer MAC ADDRESS BLUETOOTH (if using bluetooth mode)
	 */
	public setup(printer: any, options: any) {
		if (!printer) {
			throw "Printer is undefined";
		} else if (printer.driver !== 'custom') {
			throw "Wrong driver";
		} else if (!printer.connection_type) {
			throw "Missing connection_type";
		} else if (!printer.ip_address) {
			throw "Missing ip_address";
		}

		this.printer = printer;
		this.options = options;
	}

	public async getPrinterStatus() {
		let result = {};
		let printerSocket: customPrinterSocket;

		try {
			printerSocket = await this.isAvailable(this.printer);

			//TODO: add periodic check and inactive period
			const commands: printerCommands = [
				['1008'], //Printer Serial
				['1209'], //Printer Status [CoverOpen, PaperEnd, PaperAlmostEnd, DgfeEnd, DgfeAlmostEnd, PaperCutError]
				['1511'], //RT Status
				['15140'], //RT Daily Closing Status
				['1013'] //Printer release
			];

			let responses = await this.sendCommands(printerSocket, commands);
			let serialResponse = responses.shift();
			let mainPrinterStatus = byteArrayToString(responses.shift());
			let rtLogicStatus = responses.shift();
			let rtDCStatus = responses.shift();
			let printerFw = responses.shift();

			let fpStatus = Array(5).fill('0');

			//Cover/Paper status
			fpStatus[0] = (mainPrinterStatus[0] === '1' || mainPrinterStatus[1] === '1' || mainPrinterStatus[2] === '1') ? '3' : '0';

			//Dgfe Status
			if (mainPrinterStatus[4] === '1') {
				fpStatus[1] = '1';
			}

			if (mainPrinterStatus[4] === '1') {
				fpStatus[1] = '5';
			}

			//RT Status
			let rtStatus = byteArrayToString(rtLogicStatus?.slice(22, 25));

			if (rtStatus[0] === '0') {
				Object.assign(result, { rtMainStatus: '01', rtSubStatus: '05' });
			} else if (rtStatus[1] === '0') {
				Object.assign(result, { rtMainStatus: '01', rtSubStatus: '06' });
			} else if (rtStatus[2] === '0') {
				Object.assign(result, { rtMainStatus: '01', rtSubStatus: '07' });
			} else {
				Object.assign(result, { rtMainStatus: '02', rtSubStatus: '08' });
			}

			Object.assign(result, {
				fpStatus: fpStatus.join(''),
				cpuRel: _.trim(byteArrayToString(printerFw)),
				rtType: byteArrayToString(rtLogicStatus?.slice(0, 1)) === '1' ? 'I' : 'M',
				rtFileToSend: byteArrayToString(rtDCStatus?.slice(5, 9)),
				rtFileRejected: byteArrayToString(rtDCStatus?.slice(1, 5)),
				printer_serial: `${byteArrayToString(serialResponse?.slice(0, 2))} ${byteArrayToString(serialResponse?.slice(2))}`
			});

			return result;
		} finally {
			this.closeSocket(printerSocket);
		}
	}

	/**
	 *  @desc automagically configure RCH Print!F
	 *
	 *  ( /printers )
	 *  @param printer, the printer you want to configure
	 *  @param printer.invoice_prefix (from printer)
	 *  @param printer.printer_number (from printer)
	 *
	 *  ( /shop_preferences )
	 *
	 *  @param options.header1 (max 48 chars - receipt + invoice)
	 *  @param options.header2 (max 48 chars - receipt + invoice))
	 *  @param options.header3 (max 48 chars - receipt + invoice))
	 *  @param options.header4 (max 48 chars - receipt + invoice))
	 *  @param options.header5 (max 48 chars - receipt + invoice))
	 *  @param options.header6 (max 48 chars - receipt + invoice))
	 *
	 *  @param options.header7 (max 48 chars - invoice only)
	 *  @param options.header8 (max 48 chars - invoice only)
	 *  @param options.header9 (max 48 chars - invoice only)
	 *  @param options.header10 (max 48 chars - invoice only)
	 *  @param options.header11 (max 48 chars - invoice only)
	 *  @param options.header12 (max 48 chars - invoice only)
	 *  @param options.header13 (max 48 chars - invoice only)
	 *
	 *  @param options.invoice_footer1 (max 48 chars)
	 *  @param options.invoice_footer2 (max 48 chars)
	 *  @param options.invoice_footer3 (max 48 chars)
	 *  @param options.invoice_footer4 (max 48 chars)
	 *  @param options.invoice_footer5 (max 48 chars)
	 *  @param options.invoice_footer6 (max 48 chars)
	 *
	 *  @param options.display_message
	 *
	 */
	public async autoConfigurePrinter(printer: any, options: any): Promise<void> {
		// Configuring via TCP SOCKET
		let printerSocket: customPrinterSocket;

		try {
			printerSocket = await this.connectAndInitializePrinter(printer);

			this.logEvent(`Autoconfiguring ${this.printer.name} ${this.printer.ip_address} via tcp...`);
			let commands = await this.configurationToCommands(printerSocket, options);

			if (!Array.isArray(commands)) {
				throw commands;
			}

			await this.sendCommands(printerSocket, commands);
		} catch (error) {
			if (error === 'CUSTOM.ERROR_5') {
				throw 'CUSTOM.CONFIGURATION_FAILED';
			} else {
				throw error;
			}
		} finally {
			this.closeSocket(printerSocket);
		}
	}

	/**
	 * @desc print a commercial document (also known as 'documento commerciale', for italians)
	 *
	 * @param sale - the tilby sale you want to print
	 * @param options.can_open_cash_drawer - true if user has permission, false otherwise
	 * @param options.tail: string, print this string at the end of receipt
	 * @param options.print_details: if false, print only department totals and hide single items and discount details
	 *
	 * @return successFunction with printed sale or errorFunction with errors
	 */
	public async printFiscalReceipt(sale: any, options: any, successFunction: Function, errorFunction: Function) {
		const departments = await this.entityManagerService.departments.fetchCollectionOffline();

		// Build protocol commands
		let printerSocket: customPrinterSocket;

		try {
			printerSocket = await this.connectAndInitializePrinter(this.printer);
			const isRefundVoid = this.fiscalUtils.isRefundVoidSale(sale);

			let commands = this.saleToReceiptCommands(printerSocket, sale, options, departments);

			if (!Array.isArray(commands)) {
				throw commands;
			}

			// Open cash drawer
			let openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

			if (openCashdrawer) {
				commands.push(['7008', '1']);
			}

			let documentType = 'commercial_doc';

			if (isRefundVoid) {
				//We only need to check a single sale item, since the cause check has been done in the saleToReceiptCommands function
				let saleItemToCheck = sale.sale_items[0];

				switch (saleItemToCheck.refund_cause_id) {
					case 6:
						documentType = "void_doc";
						break;
					default:
						documentType = "refund_doc";
				}
			}

			this.logEvent(`Print fiscal receipt on a ${this.printer.name} ${this.printer.ip_address} via tcp...`);

			// Print Fiscal Receipt
			try {
				await this.sendCommands(printerSocket, commands);
			} catch (error) {
				let lockStatus = await this.clearPendingDocuments(printerSocket);

				/*
				Document recovery fails if the printer
				- didn't have an open document (case 0)
				- had an open fiscal document whose transaction wasn't closed (case 1 through 4)
				- had an open non-fiscal document (case 8)
				*/
				if ([0, 1, 2, 3, 4, 8].includes(lockStatus)) {
					throw error;
				}
			}

			let documentData: any = {};

			try {
				documentData = await this.lastReceiptInfo(printerSocket);
				documentData.document_type = documentType;
			} catch (error) {
				documentData = null;
			}

			successFunction(documentData ? [documentData] : []);
		} catch (error) {
			errorFunction(error);
		} finally {
			this.closeSocket(printerSocket);
		}
	}

	/**
	 * @desc print a courtesy receipt (also known as 'scontrino di cortesia', for italians)
	 *
	 * @param sale - the tilby sale you want to print
	 *
	 * @return successFunction with printed sale or errorFunction with errors
	 */
	public async printCourtesyReceipt(sale: any, options: any, successFunction: Function, errorFunction: Function) {
		// Build protocol commands
		let commands = this.saleToCourtesyReceiptCommands(sale, options);
		let printerSocket: customPrinterSocket;

		try {
			printerSocket = await this.connectAndInitializePrinter(this.printer);

			this.logEvent(`Print courtesy receipt on a ${this.printer.name} ${this.printer.ip_address} via tcp...`);
			await this.sendCommands(printerSocket, commands);

			successFunction('OK');
		} catch (error) {
			errorFunction(error);
		} finally {
			this.closeSocket(printerSocket);
		}
	}

	/**
	 * @desc print a non fiscal receipt (also known as 'preconto', for italians)
	 *
	 * @param sale - the tilby sale you want to print
	 *
	 * @return successFunction with printed sale or errorFunction with errors
	 */
	public async printNonFiscal(sale: any, options: any, successFunction: Function, errorFunction: Function) {
		// Build protocol commands
		let commands = this.saleToNonFiscalCommands(sale, options);
		let printerSocket: customPrinterSocket;

		try {
			printerSocket = await this.connectAndInitializePrinter(this.printer);

			this.logEvent(`Print non fiscal receipt on a ${this.printer.name} ${this.printer.ip_address} via tcp...`);
			await this.sendCommands(printerSocket, commands);

			successFunction('OK');
		} catch (error) {
			errorFunction(error);
		} finally {
			this.closeSocket(printerSocket);
		}
	}

	/**
	 * @desc print an order (also known as 'comanda', for italians)
	 *
	 * @param order - the tilby sale you want to print
	 *
	 * @return successFunction with printed sale or errorFunction with errors
	 */
	public async printOrder(order: any, successFunction: Function, errorFunction: Function) {
		// Build protocol commands
		let commands = this.orderToCommands(order);
		let printerSocket: customPrinterSocket;

		try {
			printerSocket = await this.connectAndInitializePrinter(this.printer);

			this.logEvent(`Printing order on a ${this.printer.name} ${this.printer.ip_address} via tcp...`);
			await this.sendCommands(printerSocket, commands);

			successFunction('OK')
		} catch (error) {
			errorFunction(error);
		} finally {
			this.closeSocket(printerSocket);
		}
	}

	/**
	 * @desc open cash drawer
	 *
	 * @return successFunction if drawer is correctly opened or errorFunction with errors
	 */
	public async openCashDrawer(successFunction: Function, errorFunction: Function) {
		const commands: printerCommands = [['7008', '1']];
		let printerSocket: customPrinterSocket;

		try {
			printerSocket = await this.isAvailable(this.printer);

			this.logEvent(`Opening cash drawer on a ${this.printer.name} ${this.printer.ip_address} via tcp...`);
			await this.sendCommands(printerSocket, commands);

			successFunction('OK');
		} catch (error) {
			errorFunction(error);
		} finally {
			this.closeSocket(printerSocket);
		}
	}

	/**
	 * @desc do a daily closing
	 *
	 * @return successFunction  (with the closing receipt in ws mode, or 'OK' in bt mode) if close is correctly done or errorFunction with errors
	 */
	public async dailyClosing(successFunction: Function, errorFunction: Function) {
		//TOOD: save closing info and vat
		const commands: printerCommands = [['2002']];
		let printerSocket: customPrinterSocket;

		try {
			printerSocket = await this.connectAndInitializePrinter(this.printer);

			this.logEvent(`Daily closing on a ${this.printer.name} ${this.printer.ip_address} via tcp...`);

			await this.sendCommands(printerSocket, commands);
			successFunction({});
		} catch (error) {
			errorFunction(error);
		} finally {
			this.closeSocket(printerSocket);
		}
	}

	public async readDgfeBetween(mode: ('read' | 'print'), from: string, to: string): Promise<string> {
		if (!['print'].includes(mode)) {
			throw 'METHOD_DOES_NOT_EXIST';
		}

		const commands: printerCommands = [['8001', from, to, '0']];
		let printerSocket: customPrinterSocket;

		try {
			printerSocket = await this.connectAndInitializePrinter(this.printer);

			this.logEvent(`${mode}ing DFGE on a ${this.printer.name} ${this.printer.ip_address} via tcp...`);

			await this.sendCommands(printerSocket, commands);
		} finally {
			this.closeSocket(printerSocket);
		}

		return '';
	}

	/**
	 * @desc do a daily read
	 *
	 * @return promise
	 */
	public async dailyRead(): Promise<any> {
		const commands: printerCommands = [['2003']];

		let printerSocket = await this.connectAndInitializePrinter(this.printer);
		this.logEvent(`Daily read on a ${this.printer.name} ${this.printer.ip_address} via tcp...`);

		try {
			await this.sendCommands(printerSocket, commands);
			return {};
		} finally {
			this.closeSocket(printerSocket);
		}
	}

	/**
	 * @description printFreeNonFiscal
	 */
	public async printFreeNonFiscal(lines: string[], options?: PrintFreeOptions): Promise<void> {
		let freeCommands = this.linesToCommands(lines, options);
		let printerSocket: customPrinterSocket;

		try {
			printerSocket = await this.connectAndInitializePrinter(this.printer);

			this.logEvent(`Print free non-fiscal on a ${this.printer.name} ${this.printer.ip_address} via tcp...`);
			await this.sendCommands(printerSocket, freeCommands);
		} finally {
			this.closeSocket(printerSocket);
		}
	}

	public async displayText(printer: Printers, textLines: string[]) {
		const dispLineLength = 20;
		let dataToSend = '';

		for (let i = 0; i < 2; i++) {
			dataToSend += _.chain(textLines[i]).truncate({ length: dispLineLength, omission: '' }).padEnd(dispLineLength).value();
		}

		const displayCommands = [['7007', dataToSend]];

		let printerSocket: customPrinterSocket;

		try {
			printerSocket = await this.isAvailable(printer);

			this.logEvent(`Sending display message on a ${this.printer.name} ${this.printer.ip_address} via tcp...`);
			await this.sendCommands(printerSocket, displayCommands);
		} finally {
			this.closeSocket(printerSocket);
		}
	}

	/**
	 * isReachable
	 */
	public async isReachable(successFunction: Function, errorFunction: Function) {
		let printerSocket: customPrinterSocket;

		try {
			printerSocket = await this.isAvailable(this.printer);
			successFunction();
		} catch (error) {
			errorFunction(error);
		} finally {
			this.closeSocket(printerSocket);
		}
	}
}

angular.module('printers').factory('CustomRTDriver', CustomRTDriver);

CustomRTDriver.$inject = ["fiscalUtils", "util", "checkManager", "entityManager"];
