@if(isLoading()) {
    <div class="tw-w-full tw-flex tw-justify-center tw-py-3">
        <mat-spinner id="white-spinner" class="tw-h-100"></mat-spinner>
    </div>
} @else {
    <div class="tw-grid tw-grid-cols-5 tw-grid-rows-6 tw-gap-1">
        <div class="tw-col-span-2">
            <div class="tw-w-full tw-flex">
                <tilby-general-input-button class="sale-keypad-button tw-bg-transparent tw-truncate" [btn]="toggleMainKeyboardButton"/>
                <app-tilby-keypad-display [form]="form" class="tw-flex-1" inputClass="tw-flex the-dense-zone pointer-events-none-for-all-children tw-text-xl tw-w-full side-keyboard-input-width"/>
            </div>
        </div>
        <div class="tw-col-span-3 tw-flex tw-flex-row">
            <div id="infoKeypadSide" class="tw-w-full">
                @if(lastSaleTotal) {
                    <div class="tw-flex tw-flex-row tw-justify-center tw-items-center tw-font-bold">
                        <div class="tw-flex tw-flex-col tw-text-base tw-pr-4">
                            <div>{{ "CASHREGISTER.ACTIVE_SALE.KEYBOARD_SIDE_TOTAL" | translate }}</div>
                            <div>{{ "CASHREGISTER.ACTIVE_SALE.KEYBOARD_SIDE_CHANGE" | translate }}</div>
                        </div>
                        <div class="tw-flex tw-flex-col tw-text-right tw-text-base">
                            <div>{{ lastSaleTotal.final_amount | tilbyCurrency }}</div>
                            <div>{{ lastSaleTotal.change | tilbyCurrency }}</div>
                        </div>
                    </div>
                } @else {
                    <div id="finalAmountAndQuantityId" class="tw-flex tw-flex-row">
                        <span id="finalAmountId" class="tw-py-1 tw-px-[10%] tw-font-bold tw-w-full tw-text-right tw-text-[34px]">{{ (finalAmount || 0) | tilbyCurrency }}</span>
                        @if(sale) {
                            <span class="tw-p-1 tw-h-fit tw-rounded-md tw-bg-red-500 tw-font-bold tw-text-white">{{ totalQuantity }}</span>
                        }
                    </div>
                }
            </div>
        </div>
        <app-cashregister-keypad class="tw-col-span-3 tw-row-span-4" [field]="cashValue" (fieldChange)="onFieldChange.emit($event)" [integerMultiply]="integerMultiply"/>
        <div class="tw-grid tw-col-span-2 tw-row-span-4 tw-gap-1">
            @for(btn of fastPaymentsButtons; track $index) {
                <button mat-button
                    class="tw-h-full tilby-body-background-primary-color sale-keypad-button sale-keypad-button__dark tw-text-base tw-truncate"
                    [ngClass]="btn.isDisable?.() ? ['tw-opacity-25', 'tw-pointer-events-none'] : []"
                    tilbyGestures
                    (longPress)="btn.longPress()"
                    (click)="btn.click()"
                    [disabled]="btn.isDisable?.()">
                    {{btn.name | translate}}
                </button>
            }
            @if(!fastPaymentsButtons.length && principalActionButton) {
                <tilby-general-input-button
                    class="sale-keypad-button sale-keypad-button__dark tw-text-base tw-truncate"
                    [ngClass]="principalActionButton.isDisable?.() ? ['tw-opacity-25', 'tw-pointer-events-none'] : []"
                    [btn]="principalActionButton"
                />
            }
        </div>
        <div class="tw-grid tw-col-span-5 tw-grid-rows-1 tw-gap-1" [ngClass]="fastPaymentsButtons.length ? 'tw-grid-cols-5' : 'tw-grid-cols-4'">
            @for (btn of buttons; track $index) {
                @if (btn.isIt()) {
                    <tilby-general-input-button
                        [ngClass]="btn.isDisable?.() ? ['tw-opacity-25', 'tw-pointer-events-none'] : []"
                        class="sale-keypad-button sale-keypad-button__dark tw-truncate"
                        [class.tw-bg-green-500]="btn.selected?.()"
                        [btn]="btn"
                    />
                }
            }
            @if (fastPaymentsButtons.length && principalActionButton) {
                <tilby-general-input-button
                    class="sale-keypad-button sale-keypad-button__dark tw-text-base tw-truncate"
                    [ngClass]="principalActionButton.isDisable?.() ? ['tw-opacity-25', 'tw-pointer-events-none'] : []"
                    [btn]="principalActionButton"
                />
            }
        </div>
    </div>
}

