import * as angular from 'angular';
import * as async from 'async';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { stringToLines } from 'src/app/shared/string-utils';

angular.module('printers').factory('EpsonRTDriver', ["$q", "$rootScope", "epsonUtils", "fiscalUtils", "checkManager", "errorsLogger", "util", function($q, $rootScope, epsonUtils, fiscalUtils, checkManager, errorsLogger, util) {
	/**
	 *  Tilby Epson Driver for FP 81-RT and FP 90-RT
	 *
	 *  Usage:
	 *  EpsonRTDriver.setup() and after call public methods:
	 *  - getPrinterStatus
	 *  - autoConfigurePrinter
	 *  - printFiscalReceipt
	 *  - printInvoice
	 *  - printCourtesyReceipt
	 *  - printNonFiscal
	 *  - printOrder
	 *  - openCashDrawer
	 *  - dailyClosing
	 *  - dailyRead
	 *  - deposit
	 *  - withdrawal
	 *  - readDgfe (read/print) (won't implement)
	 *  - readDgfeBetween (read/print)
	 *  - printFiscalMemory
	 *  - printFiscalMemoryBetween
	 *
	 *  - printFreeNonFiscal
	 *
	 */

	var scope = {};

	var checkQueueType = function(type) {
		if(_.includes(['auto', 'manual'], checkManager.getPreference('cashregister.queue.mode'))) {
			var queueType = [];
	
			try {
				queueType = JSON.parse(checkManager.getPreference('cashregister.queue.print_type'));
			} catch(e) {}
	
			return _.includes(queueType, type);
		} else {
			return false;
		}
    };

	var isNativeRT = function(printerInfo) {
		var printerModel = _.toString(_.get(printerInfo, 'printer_serial')).slice(0,2);

		return !_.includes(['EX', 'EY'], printerModel);
	};

	var supportsLotteryCode = function(printerInfo) {
		var requiredFirmware = isNativeRT(printerInfo) ? 6.01 : 10.01;
		
		return _.toNumber(_.get(printerInfo, 'cpuRel')) >= requiredFirmware;
	};

	var isXML7Firmware = function(printerInfo) {
		var requiredFirmware = isNativeRT(printerInfo) ? 7.01 : 11.01;
		
		return _.toNumber(_.get(printerInfo, 'cpuRel')) >= requiredFirmware;
	};

	var supportsEReceipt = function(printerInfo) {
		return isNativeRT(printerInfo) && _.toNumber(_.get(printerInfo, 'cpuRel')) >= 8.00;
	};

	const getMaxDepartment = function(printerInfo) {
		return 99;
	};

	/**
	 * dateToString
	 */
	function dateToString(date) {
		return moment(date).format('DD/MM/YYYY HH:mm');
	}

	/**
	 * orderToLines
	 */
	function orderToLines(order) {
		var commands = [];

		var printerColumns = scope.printer.columns || 46;

		function border(car) {
			if (!car) {
				car = "=";
			}

			return _.repeat(car, printerColumns);
		}

		// Border
		commands.push(border());

		// Alert
		if (!scope.printer.columns || scope.printer.columns === 48) {
			commands.push("ATTENZIONE: Si e' verificato un problema di");
			commands.push("connettivita' al cameriere: " + order.operator_name);
			commands.push("Si prega di inserire manualmente i dati della");
			commands.push("comanda sulla schermata di cassa.");
		} else if (scope.printer.columns === 32) {
			commands.push("ATTENZIONE: Si e' verificato un");
			commands.push("problema di connettivita' al");
			commands.push("cam: " + order.operator_name);
			commands.push("Si prega di inserire manualmente");
			commands.push("i dati della comanda sulla ");
			commands.push("schermata di cassa.");
		}
		// Border
		commands.push(border());

		// Ordername
		if (order.name) {
			commands.push(order.name);
		}

		// Date/Time and #
		var dateNumber = (order.open_at ? dateToString(order.open_at) : "") + (order.order_number ? (" #" + order.order_number) : "");
		commands.push(dateNumber);

		// Delivery Date/Time
		if (order.deliver_at) {
			commands.push("Da consegnare: " + dateToString(order.deliver_at));
		}

		// Room name & Table name
		if (order.room_name && order.table_name) {
			commands.push(order.room_name + " " + order.table_name);
		}

		// Covers
		if (order.covers) {
			commands.push("Numero di coperti: " + order.covers);
		}

		// Customer
		if (order.order_customer) {
            var customerName = util.getCustomerCaption(order.order_customer);
            
            if(customerName) {
                commands.push("Cliente: " + customerName);
            }
        }

		// Border
		commands.push(border());

		var itemsHeader = _.padEnd("Q.TA' / DESCRIZIONE", printerColumns - 4, ' ') + "EURO";

		commands.push(itemsHeader);
		commands.push('');
		
		// Order_items
		_.forEach(order.order_items, function(oi) {
			var rowPrice = fiscalUtils.roundDecimalsToString(oi.price * oi.quantity);
			var itemLine = _.padEnd(oi.quantity + "x " + oi.name, (printerColumns - rowPrice.length), ' ') + rowPrice;
			commands.push(itemLine);
			// Ingredients
			_.forEach(oi.ingredients, function(i) {
				var ingredientRow = '';
				if (i.type === 'added') {
					ingredientRow += "  + ";
				} else if (i.type === 'removed') {
					ingredientRow += "  - ";
				}
				ingredientRow += i.name;
				if (i.price_difference) {
					var iPrice = fiscalUtils.roundDecimalsToString(i.price_difference * oi.quantity);
					ingredientRow += _.padStart(iPrice, printerColumns - ingredientRow.length, ' ');
				}
				commands.push(ingredientRow);
			});
			// Variations
			_.forEach(oi.variations, function(i) {
				var variationRow = '';
				variationRow += '  ' + i.name + ": " + i.value;
				if (i.price_difference) {
					var iPrice = fiscalUtils.roundDecimalsToString(i.price_difference * oi.quantity);
					variationRow += _.padStart(iPrice, printerColumns - variationRow.length, ' ');
				}
				commands.push(variationRow);
			});
		});

		// Border
		commands.push(border("-"));

		// Amount
		var totString = 'T0TALE EURO';
		var totAmount = fiscalUtils.roundDecimalsToString(order.amount);
		totString += _.padStart(totAmount, printerColumns - totString.length, ' ');
		commands.push(totString);

		return commands;
	}

	/**
	 * linesToCommands
	 */
	function linesToCommands(lines, options) {
		if (!_.isArray(lines)) {
			if (_.isString(lines)) {
				lines = lines.split("\n");
			}
		}

		let root = util.createXMLNode();
		let nfXml = util.createXMLNode("printerNonFiscal");

		root.appendChild(nfXml);

		// Opening fiscal receipt
		nfXml.appendChild(util.createXMLNode("beginNonFiscal"));

		_.forEach(lines, function (l) {
			nfXml.appendChild(util.createXMLNode("printNormal", {
				data: l.replace(/[<>]/g, ' ').replace(String.fromCharCode(160), ' ')
			}));
		});

		if(_.isObject(options?.barcode)) {
			const codeType = options.barcode.type || "CODE39";

			let barcodePayload;

			if(codeType === "QRCODE") {
				barcodePayload = {
					qRCodeAlignment: 1,
					qRCodeSize: 4,
					codeType: "QRCODE2",
					code: options.barcode.value
				};
			} else {
				barcodePayload = {
					position: 180,
					width: 2,
					height: 66,
					hRIPosition: "2",
					hRIFont: "B",
					codeType: options.barcode.type || "CODE39",
					code: options.barcode.value
				};
			}

			if(barcodePayload) {
				nfXml.appendChild(util.createXMLNode("printBarCode", barcodePayload));
			}
		}

		nfXml.appendChild(util.createXMLNode("endNonFiscal"));

		return root.toString();
	}

	/**
	 * @description configurationToCommands
	 */
	function configurationToCommands(printerInfo, options) {
		return fiscalUtils.getPrinterConfigurationResources().then(function(resources) {
			/* Configurazione fatture
			Numero Fattura     4 byte
			N.Righe            2 byte (99)
			N.R.bianche testa  2 byte (0)
			Si/No Stampa intes 1 byte (1)
			Esenzione          14 byte
			PREF/SUF           10 byte
			POS                1 byte (0, solo numero - 1 pref.sx, 2 suff.dx)     */

			// Read Currenct Invoice Number
			return getNextInvoiceNumber(scope.printer.ip_address).then(function(invoiceNumber) {
				var commands = [];

				// Set Invoice Number
				var invoiceNumberConf = _.padStart(invoiceNumber, 4, '0');
				// Max invoice rows = 99
				var invoiceRows = '99';
				// Header rows
				var headerRows = '06';
				// printHeader
				var printHeader = '1';
				// Text 'Esenzione'
				var esenzione = "              ";
				// Prefix/Suffix
				var invoicePrefix = _.get(scope, "printer.invoice_prefix") ? _.padStart(scope.printer.invoice_prefix, 10, '0') : _.repeat('0', 10);
				// Pos
				var pos = '1';

				var invoiceSetupData = _.join([invoiceNumberConf, invoiceRows, headerRows, printHeader, esenzione, invoicePrefix, pos], '');
				commands.push(util.createXMLNode('directIO', { command: 4025, data: invoiceSetupData, comment: "Configuring invoice parameters" }).toString());

				var maxHeadersLines = 0;
				var headerColumns = 40;

				//Setup Headers
				_.times(13, function(i) {
					var lineNumber = (i + 1);
					var finalLine = _.chain(options['header' + (i + 1)]).trim().truncate({ length: headerColumns, omission: '' }).pad(headerColumns, ' ').value();

					commands.push(util.createXMLNode('directIO', { command: 3016, data: _.padStart(i + 1, 2, '0') + finalLine + "rr", comment: "Configuring header line " + lineNumber }).toString());

					if(!_.chain(finalLine).trim().isEmpty().value()) {
						maxHeadersLines = lineNumber;
					}
				});

				//Setup invoice footer
				_.times(2, function(i) {
					var lineNumber = _.toString(i + 1);
					var finalLine = _.chain(options['invoice_footer' + (i + 1)]).trim().truncate({ length: headerColumns, omission: '' }).pad(headerColumns, ' ').value();

					commands.push(util.createXMLNode('directIO', { command: 4027, data: lineNumber + finalLine + "rr", comment: "Configuring invoice footer line" + lineNumber }).toString());
				});

				// Setup invoice header lines number
				commands.push(util.createXMLNode('directIO', { command: 4015, data: "170" + _.padStart(maxHeadersLines, 2, '0') + "rr", comment: "Configuring max invoice header lines" }).toString());

				commands.push(util.createXMLNode('directIO', { command: 3016, data: "99aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaarr" }).toString());
				commands.push(util.createXMLNode('directIO', { command: 3016, data: "98aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaarr" }).toString());

				if(isXML7Firmware(printerInfo)) {
					//Disable Printer Rounding
					var roundingValue = checkManager.getPreference('cashregister.enable_payment_rounding') ? '001' : '000';
					commands.push(util.createXMLNode('directIO', { command: 4015, data: '27' + roundingValue, comment: "Set rounding" }).toString());

					//Reset current vat table
					_.times(9, function(n) {
						var vatId = _.padStart(n + 1, 2, '0');
						commands.push(util.createXMLNode('directIO', { command: 4005, data: vatId + '0000', comment: "Resetting VAT id: " + vatId }).toString());
					});
					
					//Activity Codes (ATECO) setup (from xml7 firmwares onwards)
					var atecoCodesById = _.keyBy(resources.activityCodes, 'id');

					_.times(3, function(n) {
						var atecoId = n + 1;
						var id = _.padStart(atecoId, 2, '0');
						var atecoCode = atecoCodesById[atecoId];
						var atecoValue = '000000';
						
						if(atecoCode) {
							atecoValue = _(atecoCode.ateco_code).split('.').map(function(section) { return _.padStart(section, 2, 0); }).join('');
						}

						commands.push(util.createXMLNode('directIO', { command: 4037, data: id + atecoValue + _.repeat('0', 10), comment: "Configuring ATECO id: " + atecoId }).toString());
					});
				}

				//Insert new vat table
				_.forEach(resources.vats, function (v) {
					if (_.inRange(v.id, 1, 10)) {
						var id = _.padStart(v.id, 2, '0');
						var value = _.padEnd(_.padStart(v.value, 2, '0'), 4, '0');

						commands.push(util.createXMLNode('directIO', { command: 4005, data: id + value, comment: "Configuring VAT id: " + id }).toString());
					}
				});

				const maxDepartments = getMaxDepartment(printerInfo);

				// Departments setup
				_.forEach(resources.departments, function(d) {
					if(d.printer_code > maxDepartments) {
						errorsLogger.warn(`[ EpsonRTDriver ] Department is out of printer allowed range: ${d.printer_code} > ${maxDepartments}`);
						return;
					}

					if (_.inRange(d.vat.id, 0, 20)) {
						// Default price | vat.id | name | maximum price=0 | minimum price=0 | auto_close=0 | group_code =1
						// Department code and name
						var depId = _.padStart(d.printer_code, 2, '0');
						var depName = d.name.slice(0, 20).padEnd(20, ' ');

						var p1 = "000000000";
						var p2 = "000000000";
						var p3 = "000000000";

						var data = depId + depName + p1 + p2 + p3;

						// Single Item NO
						data += "0";

						// Tax
						data += _.padStart(d.vat.id, 2, '0');

						// Price max (no minimum limit)
						var pMax = "999999999";
						//var pMax = "000000000";
						data += pMax;

						/*
						N.REP        2 byte
						DESC        20 byte
						P1           9 byte
						P2           9 byte
						P3           9 byte
						Single item  1 byte (0)
						TAX          2 byte
						Limite       9 byte
						Gruppo stamp 2 byte
						Sup. Gruppo  2 byte
						UM fattura   2 byte
						NU           2 byte (random) */

						// Department group
						data += "00";

						// Super group
						data += "00";

						// UM fattura
						data += "  ";

						if(isXML7Firmware(printerInfo)) {
							switch(d.sales_type) {
								case 'goods':
									data += '0';
								break;
								case 'services':
								default:
									data += '1';
								break;
							}

							data += d.not_discountable ? '01' : '00';

							if(d.activity_code_id) {
								data += _.padStart(d.activity_code_id, 2, '0');
							} else {
								data += '00';
							}
						} else {
							// 2 byte random
							data += "OK";
						}

						commands.push(util.createXMLNode('directIO', { command: 4002, data: data, comment: "Configuring Department id: " + d.printer_code }).toString());
					} else {
						errorsLogger.debug("WARNING: Backend has DEPARTMENT with associated IVA id:" + d.vat.id + ", must be maximum 19");
					}
				});

				// Printer identifier
				if (scope.printer.printer_number) {
					var printerNumber = _.padStart(scope.printer.printer_number, 3, '0');
					commands.push(util.createXMLNode('directIO', { command: 4015, data: "11" + printerNumber + "RR", comment: "Configuring Printer identifier:" + printerNumber }).toString());
				}
				// Set cashdrawer off
				commands.push(util.createXMLNode('directIO', { command: 4014, data: "26000", comment: "Setting cashdrawer off" }).toString());

				// Set Ora Legale on
				commands.push(util.createXMLNode('directIO', { command: 4014, data: "34100", comment: "Setting ora legale on" }).toString());

				// Set display message
				if (!_.isEmpty(options.display_message)) {
					var displayMessage = _.padEnd(options.display_message.substring(0, 40), 40, ' ');
					commands.push(util.createXMLNode('directIO', { command: 1062, data: "013" + displayMessage + "00" }).toString());
					commands.push(util.createXMLNode('directIO', { command: 4015, data: "13997RR" }).toString());
					commands.push(util.createXMLNode('directIO', { command: 4015, data: "13005RR" }).toString());
				} else {
					//Erases messages and disables displaying
					commands.push(util.createXMLNode('directIO', { command: 1062, data: "013" + _.repeat(' ', 40) + "00" }).toString());
					commands.push(util.createXMLNode('directIO', { command: 4015, data: "13000RR" }).toString());
				}

				// Set line spacing
				commands.push(util.createXMLNode('directIO', { command: 4015, data: "18001RR" }).toString());

				//Set e-receipt server
				if(supportsEReceipt(printerInfo)) {
					commands.push(util.createXMLNode('directIO', {
						command: 9022,
						data: '08' + Array(256).fill(String.fromCharCode(0x30)).join('')
					}));
				}

				return commands;
			});
		});
	}

	/**
	 * @description getNextInvoiceNumber
	 */
	function getNextInvoiceNumber(ip_address) {
		var d = $q.defer();
		var requestXml = "<printerCommand><directIO command=\"4225\"/></printerCommand>";
		var printerEndpoint = epsonUtils.getPrinterEndpoint(ip_address, scope.printer.ssl);

		epsonUtils.eposSend(printerEndpoint, requestXml).then(function(res) {
			if (res.result.status === 2) {
				// Get first 4 bytes (receipt number) and strip leading zeros
				var lastNumber = parseInt(res.addInfo.responseData.substring(0, 4).replace(/^0+/, ''));

				if (!_.isNaN(lastNumber)) {
					d.resolve(lastNumber);
				}
				else {
					d.reject("ERROR_NOT_A_NUMBER");
				}
			} else {
				d.reject("ERROR");
			}
		}, function(error) {
			d.reject("CONNECTION_ERROR");
		});

		return d.promise;
	}

	async function searchPrinterWSForFile(ipAddress, mainPath, searchPaths, searchString) {
		let hrefRegex = new RegExp(`<a href=\\"([\\S]*${searchString})\\">`);
		let baseUrl = `http://${ipAddress}${mainPath}`;

		if(!_.endsWith(baseUrl, '/')) {
			baseUrl += '/';
		}

		let urlsToScan = _.map(searchPaths, (path) => (baseUrl + path));

		for(let url of urlsToScan) {
			try {
				let response = await fiscalUtils.sendRequest(`${url}?test=${moment().valueOf()}`, null);
				let matches = response.data.match(hrefRegex);

				if(matches) {
					return [url, matches[1] ].join('/');
				}
			} catch(err) {}
		}
	}

	async function getAEXml(ipAddress, lastClosure, numTries) {
		let responseText;

		if(!numTries) {
			numTries = 20;
		}

		for(let i = 0; i < numTries; i++) { //Try to retrieve the xml for numTries times at most
			responseText = null;
			errorsLogger.sendReport({ type: 'dailyClosingAEXmlAttempt', content: "" });

			//We keep the file path search inside the loop in case the file gets moved in the meantime
			let path = await searchPrinterWSForFile(ipAddress, '/www/dati-rt/', [moment().format("YYYYMMDD"), 'rifiutati', 'da-inviare'], `${lastClosure}-CORRISP.xml`);

			if(path) {
				try {
					let response = await fiscalUtils.sendRequest(`${path}?test=${moment().valueOf()}` , null);
					responseText = response.data;

					if(_.includes(responseText, '/r:DatiCorrispettivi')) {
						let parser = new DOMParser();
						let xml = parser.parseFromString(responseText, 'text/xml');
						let RTData = xml.getElementsByTagName("DatiRT");
						let parsedDate = _.get(xml.getElementsByTagName("DataOraRilevazione"), [0, 'textContent']);
						let date = new Date(parsedDate);
	
						if(!_.isEmpty(RTData)) {
							return {
								document_content: responseText,
								sequential_number: lastClosure,
								date: _.isDate(date) ? date.toISOString() : new Date().toISOString()
							};
						}
					}
				} catch(error) {
					//Nothing to handle, this only occours in case of missed request calls				
				}
			}

			errorsLogger.sendReport({ type: 'dailyClosingAEXmlMissed', content: responseText || null });
			//Wait 5 seconds before retrying (if it's not the last try)
			if(i < numTries - 1) {
				await new Promise(resolve => setTimeout(resolve, 5000));
			}
		}

		return "CANNOT_PARSE_FILE";
	}


	/**
	 *  parseReceiptDate
	 */
	function parseReceiptDate(fiscalReceiptDate, fiscalReceiptTime) {
		var datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{1,4})/;
		var timePattern = /(\d{1,2}):(\d{1,2})/;

		var rd = fiscalReceiptDate.match(datePattern);
		var day = parseInt(rd[1]);
		var month = parseInt(rd[2]) - 1;
		var year = parseInt(rd[3]);

		var rt = fiscalReceiptTime.match(timePattern);
		var hours = parseInt(rt[1]);
		var mins = parseInt(rt[2]);

		var date = new Date(year, month, day, hours, mins, 0);
		if (_.isNaN(date.getTime())) {
			return undefined;
		} else {
			return date;
		}
	}

	/**
	 *  parseInvoiceDate
	 */
	function parseInvoiceDate(invoice) {

		var resDate = invoice.match(/DATA(\s+)\d{2}\-\d{2}\-\d{2}\s+ORA\s+(\d{2}):(\d{2})/g);
		var datePattern = /DATA\s+(\d{2})\-(\d{2})\-(\d{2})\s+ORA\s+(\d{2}):(\d{2})/;
		if (resDate) {
			var rd = invoice.match(datePattern);

			var day = parseInt(rd[1]);
			var month = parseInt(rd[2]) - 1;
			var year = parseInt(rd[3]) + 2000;

			var hours = parseInt(rd[4]);
			var mins = parseInt(rd[5]);

			return new Date(year, month, day, hours, mins, 0);
		}
	}

	/**
	 * getFiscalReadAuthorizazion
	**/
	async function getFiscalReadAuthorizazion(printer, printerInfo) {
		if(isXML7Firmware(printerInfo)) {
			const password = _.padEnd(printer.password || '12345', 40, ' ');
			const loginCommand = util.createXMLNode('printerCommand').appendChild(util.createXMLNode('directIO', { command: '4038', data: '02' + password })).toString();

			await sendRequest(printer.ip_address, loginCommand);
		}
	}

	/**
	 * @description getReceiptByNumber
	 * @param  {object} printer
	 * @param  {object} printerInfo
	 * @param  {string} date in format GGMMAA
	 * @param  {integer} type: 0 All, 1 Fiscal Receipt (credit note and fiscal closing), 2 Invoice, 3 'Titoli di accesso', 4 Credit Note, 5 Fiscal Closing
	 * @param  {integer} number, document number
	 * @return {function} promise with content
	 */
	async function getDocumentByDateNumber(printer, printerInfo, date, type, number) {
		await getFiscalReadAuthorizazion(printer, printerInfo);
		
		const queryData = {
			fromNumber: number,
			toNumber: number,
			dataType: type,
			fromDay: date.getDate(),
			fromMonth: (date.getMonth() + 1),
			fromYear: (date.getFullYear() - 2000),
			toDay: date.getDate(),
			toMonth: (date.getMonth() + 1),
			toYear: (date.getFullYear() - 2000)
		};

		const requestXml = util.createXMLNode('printerCommand').appendChild(util.createXMLNode('queryContentByNumbers', queryData)).toString();

		let resultDocument;

		for(let i = 0; i < 10 && !resultDocument; i++) {
			try {
				resultDocument = await sendRequest(printer.ip_address, requestXml);
				let lineCountTag;

				try {
					const xmlDoc = new DOMParser().parseFromString(resultDocument, 'text/xml');

					//Extract lineNumber lines from xml
					lineCountTag = xmlDoc.getElementsByTagName("lineCount")[0];
				} catch(err) {
					throw 'PARSE_ERROR';
				}

				if(!lineCountTag) {
					throw "RECEIPT_NOT_FOUND";
				}

				resultDocument = Array.from(lineCountTag.children).map((row) => row.textContent).join("\n");
			} catch(err) {
				switch(err) {
					case 'CONNECTION_ERROR':
						//Wait 5 seconds and try again
						await new Promise(resolve => setTimeout(resolve, 5000));
						break;
					case 'PARSE_ERROR':
						if(typeof resultDocument !== 'string') {
							throw "RECEIPT_NOT_FOUND";
						}
						break;
					default:
						//Throw everything else
						throw err;
				}
			}
		}

		if(!resultDocument) {
			throw "RECEIPT_NOT_FOUND";
		}

		return resultDocument;
	}

	/**
	 * @description saleToCourtesyReceiptXml
	 */
	function saleToCourtesyReceiptXml(sale, options) {

		var root = util.createXMLNode();
		var courtesyXml = util.createXMLNode("printerNonFiscal");

		root.appendChild(courtesyXml);

		var addLine = function (line) {
			courtesyXml.appendChild(util.createXMLNode("printNormal", {
				data: line
			}));
		};

		// Opening fiscal receipt
		courtesyXml.appendChild(util.createXMLNode("beginNonFiscal"));

		// Insert header
		addLine("SCONTRINO DI CORTESIA");
		addLine("");

		if (sale.name) {
			addLine(sale.name);
		}
		addLine("");

		// Items
		_.forEach(sale.sale_items, function (si) {
			var name = si.name || si.department_name;
			var data = si.quantity + "x " + name;

			addLine(data);
		});

		if (sale.id) {
			addLine("");
			courtesyXml.appendChild(util.createXMLNode("printBarCode", {
				position: 180,
				width: 2,
				height: 66,
				hRIPosition: "2",
				hRIFont: "B",
				codeType: "CODE39",
				code: sale.id
			}));
		}
		// Closing fiscal receipt
		courtesyXml.appendChild(util.createXMLNode("endNonFiscal"));

		return root.toString();
	}

	/**
	 * @description isAvailable
	 * Checks the printer status. The printer must also be in RT mode
	 */
	async function isAvailable(printer, options) {
		/*
			Epson Serial Format (substrings)
			(2, 8): number
			(8, 10): model
			(10, 12): manufacturer (it should always be '99' for EPSON)
		*/

		const printerEndpoint = epsonUtils.getPrinterEndpoint(printer.ip_address, printer.ssl);
		const command = epsonUtils.addSoapEnvelope(`<printerCommand><queryPrinterStatus/></printerCommand>`);
		const rtCommand = epsonUtils.addSoapEnvelope(`<printerCommand><queryPrinterStatus statusType="1"/></printerCommand>`);
		const serialCommand = `<printerCommand><directIO command="3217" data="01"/></printerCommand>`;

		let answer = {};
		let responseRT;

		try {
			responseRT = await fiscalUtils.sendRequest(printerEndpoint, { timeout: 3000, data: rtCommand, method: 'POST', headers: { 'Content-Type': 'application/xml' } });
		} catch(err) {
			throw 'CONNECTION_ERROR';
		}

		responseRT = epsonUtils.parseEpsonXml(responseRT);

		if(responseRT.error) {
			throw responseRT.error;
		}

		if(responseRT.addInfo?.rtMainStatus != '02') {
			throw 'RT_MODE_DISABLED';
		} 

		Object.assign(answer, responseRT.addInfo);

		let [res, add_info] = await epsonUtils.sendCommands(printer, serialCommand);

		const serialString = add_info.responseData;

		Object.assign(answer, {
			printer_serial: `${serialString.substring(8, 10)} ${serialString.substring(10, 12)}${serialString.substring(2, 8)}`
		});

		let response;

		try {
			response = await fiscalUtils.sendRequest(printerEndpoint, { timeout: 3000, data: command, method: 'POST', headers: { 'Content-Type': 'application/xml' } });
		} catch(err) {
			throw 'CONNECTION_ERROR';
		}

		response = epsonUtils.parseEpsonXml(response);

		if(response?.addInfo) {
			Object.assign(answer, response.addInfo);
		}

		if(answer.rtNoWorkingPeriod == "1" && !options?.ignoreDailyClosingRequest) {
			throw 'FISCAL_CLOSING_NEEDED';
		}

		return answer;
	}

	/**
	 * @description saleToReceiptXml
	 */
	function saleToReceiptXml(sale, options, printerInfo) {
		var printerColumns = scope.printer.columns || 46;

		var root = util.createXMLNode();
		var saleXml = util.createXMLNode('printerFiscalReceipt');

		root.appendChild(saleXml);

		/*  printRecMessage

			messageType defines the row type to be printed
			1 = Additional header
			2 = Additional row (before MF logotype)
			3 = Additional promo (after MF logotype)
			4 = Additional description (in the body of the receipt or direct invoice).
			5 = Additional invoice header. The font is always normal so the attribute can be omitted.
			6 = Invoice client lines. The font is always normal so the attribute can be omitted
			7 = Customer Id. Sets CustomerId field in www/json_files/rec.json file (see Fiscal Printer Intelligent Features Guide for details). The font has no relevance so the attribute can be omitted. Requires firmware version >= 4.01.

			index indicates the line number:
			Range 1 - 9 for additional header (type 1).
			Range 1 - 99 for Additionalonal promo and post total descriptions (types 2 and 3).
			Range 1 - 20 (type 5)
			Range 1 - 5 (type 6)
			No meaning for additional row and Customer Id (types 4 and 7). The attribute can be omitted.

			message represents the text to be printed. The maximum lengths are as follows:
			38 – Message type 4
			46 – All other message types

		*/

		if(supportsEReceipt(printerInfo) && options.e_receipt) {
			saleXml.appendChild(util.createXMLNode('directIO', {
				command: 1133,
				data: '01' + (options.e_receipt ? '01' : '00') + '02'
			}));

			saleXml.appendChild(util.createXMLNode('directIO', {
				command: 1132,
				data: '0101'+ $rootScope.userActiveSession.shop.name + '-' + sale.uuid
			}));
		}

		// Sale Name (additional header)
		var printName = (scope.options.print_name === false) ? false : true;

		if(printName) {
			const headerRows = fiscalUtils.getFiscalReceiptHeaderLines(sale);
	
			for(let i = 0; i < headerRows.length; i++) {
				saleXml.appendChild(util.createXMLNode("printRecMessage", {
					message: headerRows[i],
					index: i + 1,
					messageType: 1
				}));
			}
		}

		//Add tax code if available and there is not a lottery code
		var customerTaxCode = _.get(sale, "sale_customer.tax_code") || sale.customer_tax_code;

		if (customerTaxCode && fiscalUtils.checkTaxCode(customerTaxCode) && _.isEmpty(sale.lottery_code)) {
			saleXml.appendChild(util.createXMLNode("directIO", {
				command: 1061,
				data: "01" + _.toUpper(customerTaxCode)
			}));
		}

		const isRefundVoid = fiscalUtils.isRefundVoidSale(sale);

		// Credit note header
		if (isRefundVoid) {
			var cmessage;

			var docDataItem = _.find(sale.sale_items, function (si) {
				return si.reference_sequential_number && si.reference_date;
			});

			var docData = {};

			if (docDataItem) {
				docData = {
					reference_sequential_number: docDataItem.reference_sequential_number,
					reference_date: docDataItem.reference_date
				};
			} else {
				return -1; //MISSING_REFERENCE_DOCUMENT
			}

			if (_.every(sale.sale_items, docData)) { //If all of the items are from the same document
				//Check if we are doing a void or a refund
				var hasVoid = false;
				var hasRefund = false;
				_.forEach(sale.sale_items, function (si) {
					if (si.refund_cause_id === 6) {
						hasVoid = true;
					} else {
						hasRefund = true;
					}
				});

				if (hasRefund && hasVoid) {
					return -3; //MIXED_REFUND_CAUSES
				} else {
					var seqNumStr = fiscalUtils.parseRTDocumentSequentialNumber(docData.reference_sequential_number).sequential_number_string;
					var documentDate = moment(docData.reference_date).format('DD-MM-YYYY');
					if (hasVoid) {
						cmessage = util.createXMLNode("printRecMessage", {
							message: "ANNULLAMENTO N." + seqNumStr + " del " + documentDate,
							messageType: 4
						});
					} else {
						cmessage = util.createXMLNode("printRecMessage", {
							message: "RESO MERCE N." + seqNumStr + " del " + documentDate,
							messageType: 4
						});
					}
				}
			} else {
				return -2; //ITEMS_FROM_DIFFERENT_DOCUMENTS
			}
			saleXml.appendChild(cmessage);
		} else {
			// Opening fiscal receipt
			saleXml.appendChild(util.createXMLNode('beginFiscalReceipt'));

			//Add lottery code if available and supported by the printer firmware (requires version 10.01 or higher)
			if(sale.lottery_code) {
				if(supportsLotteryCode(printerInfo)) {
					saleXml.appendChild(util.createXMLNode("directIO", {
						command: 1135,
						data: "01" + _.padEnd(sale.lottery_code, 16, ' ') + "0000"
					}));
				} else {
					return -4; //LOTTERY_FW_UPGRADE_REQUIRED
				}
			}
		}

		var printDetails = (options.print_details === false) ? false : true;

		// Credit note
		if (isRefundVoid) {
			printDetails = true;
		}

		// Sale items
		if (printDetails) {
			// Check Notes
			var printNotes = (scope.options.print_notes === false) ? false : true;
			var printDescription = (scope.options.print_description === true) ? true : false;

			_.forEach(fiscalUtils.extractSaleItems(sale), function(si) {
				const originalItem = si.item_id ? _.get(options, ['itemsMap', si.item_id]) || {} : {};
				
				epsonUtils.saleItemToCommands(saleXml, si, {
					xml7Mode: isXML7Firmware(printerInfo),
					description: printDescription ? originalItem.description : null,
					printNotes: printNotes,
					printerColumns: printerColumns
				});

				// Discount/Surcharges
				epsonUtils.applyPriceChanges(si.price_changes, saleXml, fiscalUtils.roundDecimals(si.price * si.quantity), si.department_id, { xml7Mode: isXML7Firmware(printerInfo) });
			});
		} else { // Department aggregation
			var departmentTotals = fiscalUtils.extractDepartments(sale);

			_.forEach(departmentTotals, function (depTotal) {
				saleXml.appendChild(util.createXMLNode("printRecItem", {
					description: depTotal.name,
					quantity: 1,
					unitPrice: depTotal.amount.toString().replace(".", ","),
					department: depTotal.id
				}));
			});
		}

		// Sub-total and his discount/surcharges
		saleXml.appendChild(util.createXMLNode("printRecSubtotal", {
			option: 1
		}));

		/*
			option can be
			0 = Print on the receipt and show on the display
			1 = Only print on the receipt
			2 = Only show on the display 	*/

		// Apply discount/surcharges on subtotal

		if (!isRefundVoid && printDetails) {
			epsonUtils.applyPriceChanges(sale.price_changes, saleXml, fiscalUtils.roundDecimals(sale.amount), null, { xml7Mode: isXML7Firmware(printerInfo) });
		}

		var upperTailRows = 1;

		if (!isRefundVoid) {
			//Check and add payments
			_(epsonUtils.extractPayments(sale, scope.options.resources, { xml7Mode: isXML7Firmware(printerInfo) })).forEach(function(p) {
				saleXml.appendChild(util.createXMLNode("printRecTotal", {
					description: p.method_name,
					payment: p.amount,
					index: p.index,
					paymentType: p.payment_type
				}));
			});
		} else { // if credit note
			saleXml.appendChild(util.createXMLNode("printRecTotal", {
				amount: "0"
			}));
		}

		var printCustomerDetail = (scope.options.print_customer_detail === false) ? false : true;

		// Customer
		if (printCustomerDetail && sale.sale_customer) {
			var saleCustomer = sale.sale_customer;

			var addCustomerLine = function(line) {
				saleXml.appendChild(util.createXMLNode("printRecMessage", {
					message: line,
					messageType: 2,
					index: upperTailRows++
				}));
			};

			var customerInfo = fiscalUtils.getCustomerInfo(saleCustomer);

			if(!_.isEmpty(customerInfo)) {
				addCustomerLine("");
				_.forEach(customerInfo, addCustomerLine);
			}
		}

		// Sale ID Barcode
		var printBarcodeId = ((scope.options.print_barcode_id === false) ? false : true) && _.isInteger(sale.id);
		var printQRCode = !printBarcodeId && (options.qr_code_url);

		if (printBarcodeId) {
			saleXml.appendChild(util.createXMLNode('printBarCode', {
				position: 0,
				width: 2,
				height: 66,
				hRIPosition: 2,
				hRIFont: "B",
				codeType: "CODE39",
				code: sale.id.toString()
			}));
		}

		// Print Seller
		var printSeller = (scope.options.print_seller === false) ? false : true;
		var bottomTailRows = 0;

		if (printSeller && sale.seller_name) {
			var sellerPrefix = scope.options.seller_prefix || "Ti ha servito";

			saleXml.appendChild(util.createXMLNode("printRecMessage", {
				messageType: 3,
				message: sellerPrefix + " " + sale.seller_name.trim().split(" ")[0],
				index: ++bottomTailRows
			}));
		}

		var sanitizeTailRow = function(row) {
			var sanitizedRow = row.replace(String.fromCharCode(8364), "EUR0")
				.replace("IMPORTO", "IMPORT0")
				.replace(String.fromCharCode(160), ' ')
				.replace('\u001B', '');
			return sanitizedRow;
		};

		// Add Tail (tail for this sale + general tail)
		if(_.isString(options.tail)) {
			var saleTail = options.tail.split('\n');
			saleTail.push(" ");
			_.forEach(saleTail, function(row) {
				if(bottomTailRows < 100) {
					saleXml.appendChild(util.createXMLNode('printRecMessage', {
						messageType: 3,
						message: sanitizeTailRow(row),
						index: ++bottomTailRows
					}));
				}
			});
		}

		var printerTail = [];

		if (_.isString(scope.options.tail)) {
			_.forEach(stringToLines(scope.options.tail, printerColumns), function(line) {
				printerTail.push({ text: line, font: 1 });
			});
		}

		if (printQRCode && _.isString(options.qr_code_message)) {
			printerTail.push({ text: ' ', font: 1 });

			_.forEach(stringToLines(options.qr_code_message, printerColumns), function(line) {
				printerTail.push({ text: ' ', font: 1 });
			});
		}

		if(!isRefundVoid && checkQueueType('tail')) {
			_.forEach(fiscalUtils.getQueueCouponRows(sale, printerColumns), function(row) {
				printerTail.push({ text: row.text, font: row.doubleHeight ? 4 : 1 });
			});
		}

		_.forEach(printerTail, function(row, idx) {
			if(bottomTailRows < 100) {
				saleXml.appendChild(util.createXMLNode('printRecMessage', {
					messageType: 3,
					message: sanitizeTailRow(row.text),
					font: row.font,
					index: ++bottomTailRows
				}));
			}
		});

		if(printQRCode) {
			saleXml.appendChild(util.createXMLNode('printBarCode', {
				qRCodeAlignment: 1,
				qRCodeSize: 4,
				codeType: "QRCODE2",
				code: options.qr_code_url
			}));
		}

		// Open cash drawer
		var openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

		if (openCashdrawer) {
			saleXml.appendChild(util.createXMLNode("openDrawer"));
		}

		var amountMessage = 'EURO ' + fiscalUtils.decimalToString(sale.final_amount);
		var displayMessage = _.padEnd('TOTALE', 20 - amountMessage.length, ' ');

		saleXml.appendChild(util.createXMLNode("displayText", {
			data: displayMessage + amountMessage
		}));

		saleXml.appendChild(util.createXMLNode("endFiscalReceipt"));

		return root.toString();
	}

	/**
	 * @description saleToNonFiscalXml
	 */
	function saleToNonFiscalXml(sale, options) {
		const savePaper = !!checkManager.getPreference('cashregister.save_paper_on_prebill');
		const isRefundVoid = fiscalUtils.isRefundVoidSale(sale);
		var root = util.createXMLNode();
		var nfXml = util.createXMLNode("printerNonFiscal");

		root.appendChild(nfXml);

		var addLine = function (line) {
			nfXml.appendChild(util.createXMLNode("printNormal", {
				data: line
			}));
		};

		// Opening fiscal receipt
		nfXml.appendChild(util.createXMLNode("beginNonFiscal"));

		// Sale Name (additional header)
		var printName = (savePaper || scope.options.print_name === false) ? false : true;
		var printerColumns = scope.printer.columns || 46;

		if(printName) {
			for(let row of fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
				addLine(row);
			}
		}

		var printDetails = (options.print_details === false) ? false : true;

		// Sale items
		if (printDetails) {
			_.forEach(fiscalUtils.extractSaleItems(sale), function(si) {
				var rowDescription = si.quantity + "x " + (si.name || si.department_name);
				var rowPrice = fiscalUtils.decimalToString(si.price * si.quantity);
				var row = _.padEnd(_.truncate(rowDescription, { length: (printerColumns - rowPrice.length - 2) }), printerColumns - rowPrice.length, " ") + rowPrice;

				addLine(row);

				// Notes
				var printNotes = (scope.options.print_notes === false) ? false : true;

				if (printNotes && si.notes) {
					var notesLines = stringToLines(si.notes, printerColumns);

					_.forEach(notesLines, function (nl) {
						if (nl.trim()) {
							addLine(nl.trim());
						}
					});
				}

				// Discount/Surcharges
				var partialPrice = fiscalUtils.roundDecimals(si.price * si.quantity);

				_(si.price_changes).sortBy('index').forEach(function(pc) {
					var pcAmount = fiscalUtils.getPriceChangeAmount(pc, partialPrice);

					if(!_.isNil(pcAmount)) {
						partialPrice = fiscalUtils.roundDecimals(partialPrice + pcAmount);

						var rowDescription = pc.description;
						var rowAmount = (_.startsWith(pc.type, 'surcharge') ? '+' : '') + pcAmount.toFixed(2).replace(".", ",");
						var row = _.padEnd(_.truncate(rowDescription, { length: (46 - rowPrice.length - 2) }), 46 - rowAmount.length, " ") + rowAmount;

						addLine(row);
					}
				});
			});
		}

		// Sub-total and his discount/surcharges
		var subTotDescr = "SUBTOTALE";
		var subTotAmount = fiscalUtils.decimalToString(sale.amount);

		nfXml.appendChild(util.createXMLNode("printNormal", {
			data: _.padEnd(subTotDescr, printerColumns - subTotAmount.length, " ") + subTotAmount,
			font: 2
		}));

		// Apply discount/surcharges on subtotal
		if (!isRefundVoid && printDetails) {
			var partialPrice = fiscalUtils.roundDecimals(sale.amount);

			_(sale.price_changes).sortBy('index').forEach(function(pc) {
				var pcAmount = fiscalUtils.getPriceChangeAmount(pc, partialPrice);

				if(!_.isNil(pcAmount)) {
					partialPrice = fiscalUtils.roundDecimals(partialPrice + pcAmount);

					var rowDescription = pc.description;
					var rowAmount = (_.startsWith(pc.type, 'surcharge') ? '+' : '') + pcAmount.toFixed(2).replace(".", ",");
					var row = _.padEnd(rowDescription, 46 - rowAmount.length, " ") + rowAmount;

					addLine(row);
				}
			});
		}

		var totDescription = 'TOTALE EURO';
		var totAmount = fiscalUtils.decimalToString(sale.final_amount);
		var totRow = _.padEnd(totDescription, printerColumns - totAmount.length, " ") + totAmount;

		nfXml.appendChild(util.createXMLNode("printNormal", {
			data: totRow,
			font: 3
		}));

		/*
			No data about:
			- payments
			- customerDetail
		*/

		// Add Tail (tail for this sale + general tail)
		var tailRows = [];

		if (_.isString(options.tail)) {
			tailRows.push('');

			_.forEach(stringToLines(options.tail, printerColumns), function(line) {
				tailRows.push(line);
			});
		}

		if (_.isString(scope.options.tail)) {
			tailRows.push('');

			_.forEach(stringToLines(scope.options.tail, printerColumns), function(line) {
				tailRows.push(line);
			});

			tailRows.push('');
		}

		_.forEach(tailRows, function (row) {
			addLine(row);
		});

		if(!savePaper) {
			addLine("");
			addLine(_.pad("RITIRARE LO SCONTRINO", printerColumns, ' '));
			addLine(_.pad("FISCALE ALLA CASSA", printerColumns, ' '));
			addLine("");
		}

		if(scope.options.print_sale_qr_code) {
			nfXml.appendChild(util.createXMLNode('printBarCode', {
				qRCodeAlignment: 1,
				qRCodeSize: 8,
				codeType: "QRCODE2",
				code: 's_uid=' + sale.uuid
			}));
		}

		// Open cash drawer
		var openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

		if (openCashdrawer) {
			nfXml.appendChild(util.createXMLNode("openDrawer"));
		}

		var amountMessage = 'EURO ' + fiscalUtils.decimalToString(sale.final_amount);
		var displayMessage = 'TOTALE';

		nfXml.appendChild(util.createXMLNode("displayText", {
			data: _.padEnd(displayMessage, 20 - amountMessage.length, " ") + amountMessage
		}));

		nfXml.appendChild(util.createXMLNode("endNonFiscal"));
		return root.toString();
	}

	function getTicketChangeXml(sale) {
		var ticketChange = (scope.options.ticket_change === false) ? false : true;

		if (ticketChange && sale.change > 0 && sale.change_type === 'ticket') {
			var root = util.createXMLNode();
			var nfXml = util.createXMLNode("printerNonFiscal");

			root.appendChild(nfXml);

			var addLine = function (line) {
				nfXml.appendChild(util.createXMLNode("printNormal", {
					data: line
				}));
			};

			nfXml.appendChild(util.createXMLNode("beginNonFiscal"));

			var ticketChangeAmount = util.round(sale.change);

			var ticketMessage = ["",
				"################ RESTO TICKET #################",
				"",
				"QUESTO COUPON VALE:               " + fiscalUtils.decimalToString(ticketChangeAmount) + " EURO",
				"",
				"Presenta alla cassa prima del pagamento questo",
				"coupon per avere riconosciuto il credito.    ",
				"Valido 30 giorni dalla data di emissione.    ",
				"Non convertibile in denaro.                  ",
				"",
				"###############################################"
			];

			_.forEach(ticketMessage, function (tm, idx) {
				addLine(tm);
			});

			nfXml.appendChild(util.createXMLNode("printBarCode", {
				width: 2,
				height: 66,
				hRIPosition: 2,
				hRIFont: "B",
				codeType: "EAN13",
				position: 180,
				code: (1212000000000 + _.round(ticketChangeAmount * 100))
			}));

			nfXml.appendChild(util.createXMLNode("endNonFiscal"));
			return root.toString();
		} else {
			return null;
		}
	}

	function getQueueCouponNonFiscal(sale) {
		var root = util.createXMLNode();
		var nfXml = util.createXMLNode("printerNonFiscal");
		var printerColumns = scope.printer.columns || 46;

		root.appendChild(nfXml);
		nfXml.appendChild(util.createXMLNode("beginNonFiscal"));

		_.forEach(fiscalUtils.getQueueCouponRows(sale, printerColumns), function(row) {
			nfXml.appendChild(util.createXMLNode("printNormal", {
				data: row.text,
				font: row.doubleHeight ? 4 : 1
			}));
		});

		nfXml.appendChild(util.createXMLNode("endNonFiscal"));
		return root.toString();
	}

	function getQRCodeXml(url, message) {
		var printerColumns = scope.printer.columns || 46;

		var root = util.createXMLNode();
		var nfXml = util.createXMLNode("printerNonFiscal");

		root.appendChild(nfXml);

		var addLine = function (line) {
			nfXml.appendChild(util.createXMLNode("printNormal", {
				data: line
			}));
		};

		nfXml.appendChild(util.createXMLNode("beginNonFiscal"));

		if(_.isString(message)) {
			_.forEach(stringToLines(message, printerColumns), function(line) {
				addLine(line);
			});
		}

		nfXml.appendChild(util.createXMLNode('printBarCode', {
			qRCodeAlignment: 1,
			qRCodeSize: 4,
			codeType: "QRCODE2",
			code: url
		}));

		nfXml.appendChild(util.createXMLNode("endNonFiscal"));
		return root.toString();
	}

	/**
	 * @description saleToInvoiceXml
	 */
	function saleToInvoiceXml(sale, options, printerInfo) {
		return getNextInvoiceNumber(scope.printer.ip_address).then(function(invoiceNumber) {
			const isRefundVoid = fiscalUtils.isRefundVoidSale(sale);
			var printerColumns = scope.printer.columns || 46;

			var root = util.createXMLNode();
			var invoiceXml = util.createXMLNode("printerFiscalDocument");
			root.appendChild(invoiceXml);
			/*  printRecMessage

			messageType defines the row type to be printed
			1 = Additional header
			2 = Additional row (before MF logotype)
			3 = Additional promo (after MF logotype)
			4 = Additional description (in the body of the receipt or direct invoice).
			5 = Additional invoice header. The font is always normal so the attribute can be omitted.
			6 = Invoice client lines. The font is always normal so the attribute can be omitted
			7 = Customer Id. Sets CustomerId field in www/json_files/rec.json file (see Fiscal Printer Intelligent Features Guide for details). The font has no relevance so the attribute can be omitted. Requires firmware version >= 4.01.

			index indicates the line number:
			Range 1 - 9 for additional header (type 1).
			Range 1 - 99 for Additionalonal promo and post total descriptions (types 2 and 3).
			Range 1 - 20 (type 5)
			Range 1 - 5 (type 6)
			No meaning for additional row and Customer Id (types 4 and 7). The attribute can be omitted.

			message represents the text to be printed. The maximum lengths are as follows:
			38 – Message type 4
			46 – All other message types
			*/
			// Sale Name (additional header)
			var printName = (scope.options.print_name === false) ? false : true;

			if(printName) {
				const headerRows = fiscalUtils.getFiscalReceiptHeaderLines(sale);
	
				for(let i = 0; i < headerRows.length; i++) {
					invoiceXml.appendChild(util.createXMLNode("printRecMessage", {
						message: headerRows[i],
						index: i + 1,
						messageType: 5
					}));
				}
			}

			// Invoice lines for index
			var il = 1;
			// Customer info in fiscal receipt
			if (sale.sale_customer && ((sale.sale_customer.first_name && sale.sale_customer.last_name) || (sale.sale_customer.company_name))) {
				invoiceXml.appendChild(util.createXMLNode("printRecMessage", {
					message: util.getCustomerCaption(sale.saleCustomer),
					messageType: 6,
					index: il++
				}));

				invoiceXml.appendChild(util.createXMLNode("printRecMessage", {
					message: sale.sale_customer.billing_street + " " + sale.sale_customer.billing_number,
					messageType: 6,
					index: il++
				}));

				invoiceXml.appendChild(util.createXMLNode("printRecMessage", {
					message: sale.sale_customer.billing_zip + " " + sale.sale_customer.billing_city + " " + sale.sale_customer.billing_prov,
					messageType: 6,
					index: il++
				}));

				// CF + PIVA
				var fiscalCodes = ((sale.sale_customer.vat_code ? ("P.IVA " + sale.sale_customer.vat_code + " ") : "")) + ((sale.sale_customer.tax_code ? ("C.F. " + sale.sale_customer.tax_code) : ""));

				invoiceXml.appendChild(util.createXMLNode("printRecMessage", {
					message: fiscalCodes,
					messageType: 6,
					index: il++
				}));

				if (sale.sale_customer.fidelity) {
					invoiceXml.appendChild(util.createXMLNode("printRecMessage", {
						message: "FIDELITY " + sale.sale_customer.fidelity,
						messageType: 6,
						index: il++
					}));
				}
			}

			// <beginFiscalDocument operator="1" documentType="directInvoice" documentNumber="0" />
			// Opening direct invoice
			invoiceXml.appendChild(util.createXMLNode("beginFiscalDocument", {
				documentType: "directInvoice",
				documentNumber: invoiceNumber
			}));

			var printDetails = (options.print_details === false) ? false : true;

			// Sale items
			if (printDetails) {
				var printNotes = (scope.options.print_notes === false) ? false : true;

				_.forEach(fiscalUtils.extractSaleItems(sale), function(si) {
					epsonUtils.saleItemToCommands(invoiceXml, si, {
						xml7Mode: isXML7Firmware(printerInfo),
						printNotes: printNotes,
						printerColumns: printerColumns
					});

					// Discount/Surcharges
					epsonUtils.applyPriceChanges(si.price_changes, invoiceXml, fiscalUtils.roundDecimals(si.price * si.quantity), si.department_id, { xml7Mode: isXML7Firmware(printerInfo) });
				});
			} else { // Department aggregation
				var departmentTotals = fiscalUtils.extractDepartments(sale);

				_.forEach(departmentTotals, function (depTotal) {
					invoiceXml.appendChild(util.createXMLNode("printRecItem", {
						description: depTotal.name,
						quantity: 1,
						unitPrice: depTotal.amount.toString().replace(".", ","),
						department: depTotal.id
					}));
				});
			}

			// Sub-total
			invoiceXml.appendChild(util.createXMLNode("printRecSubtotal", {
				option: 1
			}));

			/*
				option can be
				0 = Print on the receipt and show on the display
				1 = Only print on the receipt
				2 = Only show on the display 	*/

			// Apply discount/surcharges on subtotal

			if (!isRefundVoid && printDetails) {
				epsonUtils.applyPriceChanges(sale.price_changes, invoiceXml, fiscalUtils.roundDecimals(sale.amount), null, { xml7Mode: isXML7Firmware(printerInfo) });
			}

			// Check and add payments
			_(epsonUtils.extractPayments(sale, scope.options.resources, { xml7Mode: isXML7Firmware(printerInfo) })).forEach(function(p) {
				invoiceXml.appendChild(util.createXMLNode("printRecTotal", {
					description: p.method_name,
					payment: p.amount,
					index: p.index,
					paymentType: p.payment_type
				}));
			});

			// Open cash drawer
			var openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

			if (openCashdrawer) {
				invoiceXml.appendChild(util.createXMLNode("openDrawer"));
			}

			var amountMessage = 'EURO ' + fiscalUtils.decimalToString(sale.final_amount);
			var displayMessage = _.padEnd('TOTALE', 20 - amountMessage.length, ' ');

			invoiceXml.appendChild(util.createXMLNode("displayText", {
				data: displayMessage + amountMessage
			}));

			invoiceXml.appendChild(util.createXMLNode("endFiscalReceipt"));

			return root.toString();
		}, function (error) {
			return null;
		});
	}

	/**
	 * internalPrintFreeNonFiscal
	 */
	function internalPrintFreeNonFiscal(lines, successFunction, errorFunction) {
		var freeXml = linesToCommands(lines);
		isAvailable(scope.printer).then(function () {
			epsonUtils.sendCommands(scope.printer, freeXml).then(successFunction, errorFunction);
		}, errorFunction);
	}

	// Send WS request
	function sendRequest(ip_address, command) {
		var d = $q.defer();
		var printerEndpoint = epsonUtils.getPrinterEndpoint(ip_address, scope.printer.ssl);
		command = epsonUtils.addSoapEnvelope(command);

		fiscalUtils.sendRequest(printerEndpoint, { data: command, method: 'POST', headers: { 'Content-Type': 'application/xml' } }).then(function(response) {
			d.resolve(response.data.replace(/&luot;/g, "&quot;"));
		}, function(error) {
			d.reject("CONNECTION_ERROR");
		});

		return d.promise;
	}

	/**
	 * Public methods
	 */
	return {
		/**
		 * @desc setup Epson FP90-III or Epson FP81-II printer Intelligent Printer
		 *
		 * @param {boolean} options.print_notes: (default true), if true prints the non-fiscal lines above each sale_item (for ex. variations, or combinations)
		 * @param {boolean} options.print_name: (default true), if true prints the sale.name on top
		 * @param {boolean} options.print_barcode_id: (default true), if true, prints a barcode on bottom that contains the sale.id
		 * @param {boolean} options.ticket_change: (default true), if true enables ticket change
		 * @param {boolean} options.print_customer_detail: boolean (default true), if true print TAX_CODE, FIRST_NAME, LAST_NAME, COMPANY_NAME, VAT_CODE, FIDELITY
		 * @param {boolean} options.print_seller: boolean (default true), if true print seller
		 * @param {string} options.seller_prefix: customize seller phrase
		 * @param {string} options.tail: general tail for the receipts
		 * 
		 * @param {object} printer, the tilby fiscal printer (must be an Epson Intelligent which supports web services)
		 * @param {integer} printer.id: the printer id
		 * @param {string} printer.name: the printer name
		 * @param {string} printer.driver: must be 'epson in this case'
		 * @param {string} printer.connection_type: not used (the driver will use only web service system)
		 * @param {string} printer.ip_address: string, printer IP
		 * @param {string} priner.mac_address_bt: not used
		 */
		setup: function(printer, options) {
			if (!_.isObject(printer)) {
				throw "Wrong or missing printer data";
			} else if (printer.driver !== 'epson') {
				throw "Wrong driver";
			} else if (!printer.ip_address) {
				throw "Missing ip_address";
			}

			_.assign(scope, {
				printer: printer,
				options: options
			});
		},

		getPrinterStatus: async (options) => {
			const genInfoCommand = '<printerCommand><queryPrinterStatus/></printerCommand>';
			const rtInfoCommand = '<printerCommand><queryPrinterStatus statusType=\"1\"/></printerCommand>';
			let answer = {};

			//Obtain printer serial
			let printerInfo = await isAvailable(scope.printer);
			answer.printer_serial = printerInfo.printer_serial;

			if(options?.getFiscalStatus) {
				//Obtain fiscal status if required
				let [res_gen, add_info_gen] = await epsonUtils.sendCommands(scope.printer, genInfoCommand);
				let [res_rt, add_info_rt] = await epsonUtils.sendCommands(scope.printer, rtInfoCommand);

				Object.assign(answer, add_info_gen, add_info_rt);

				//Obtain grand total and daily closures
				let [res_gt, add_info_gt] = await epsonUtils.sendCommands(scope.printer, `<printerCommand><directIO command="2052"/></printerCommand>`);

				if(add_info_gt.responseData) {
					Object.assign(answer, {
						fiscal_gran_total: add_info_gt.responseData.slice(0, 14),
						fiscal_daily_closures: add_info_gt.responseData.slice(14, 18)
					});
				}

				//Obtain DGFE Status
				let [res_ej, add_info_ej] = await epsonUtils.sendCommands(scope.printer, `<printerCommand><directIO command="1077" data="01" /></printerCommand>`);

				if(add_info_ej.responseData) {
					Object.assign(answer, {
						dgfe_status: add_info_ej.responseData.slice(3, 5)
					});
				}

				//Try to obtain the last periodic check date
				try {
					for(let i = 0; i <= 10 && answer.lastPeriodicCheck == null; i++) {
						let [res_lpc, add_info_lpc] = await epsonUtils.sendCommands(scope.printer, `<printerCommand><directIO command="9202" data="08${i.toString().padStart(3, '0')}"/></printerCommand>`);
						const responseData = add_info_lpc.responseData;

						if(responseData) {
							const srvCode = responseData.slice(94, 96);

							if(srvCode === '03') {
								answer.lastPeriodicCheck = moment(responseData.slice(6, 18), 'DDMMYYHHmmss').toISOString();
							}
						}
					}
				} catch(err) {}
			}

			delete answer.lastCommand;

			return answer;
		},

		getLastReceipt: async (printer) => {
			const printerInfo = await isAvailable(printer);

			//Get current document progressive
			const [resDp, addInfoDp] = await epsonUtils.sendCommands(printer, epsonUtils.getDirectIOCommand("2050", "2400"));

			//Get last daily closure progressive
			const [resDc, addInfoDc] = await epsonUtils.sendCommands(printer, epsonUtils.getDirectIOCommand("2050", "2700"));

			if(!addInfoDp.responseData) {
				return null;
			}

			const documentNumber = parseInt(addInfoDp.responseData.split('+')[2]);
			const closureNumber = parseInt(addInfoDc.responseData.split('+')[2]) + 1;

			if(!documentNumber) {
				return null;
			}

			const receiptDocument = await getDocumentByDateNumber(printer, printerInfo, new Date(), 1, documentNumber).then(res => epsonUtils.cleanupSaleDocument(res));

			return {
				date: epsonUtils.parseCommercialDocumentDate(receiptDocument),
				document_content: receiptDocument,
				document_type: "commercial_doc",
				printer_id: printer.id,
				printer_name: printer.name,
				printer_serial: printerInfo.printer_serial,
				sequential_number: (closureNumber * 10000) + documentNumber
			};
		},


		/**
		 * @desc print a fiscal receipt (also known as 'scontrino fiscale', for italians)
		 *
		 * @param sale - the tilby sale you want to print
		 * @param options.can_open_cash_drawer: (default true) - true if user has permission, false otherwise
		 * @param options.tail: string, print this string at the end of receipt
		 * @param options.print_details: if false, print only department totals and hide single items and discount details
		 *
		 * @return successFunction with printed sale or errorFunction with errors
		 */
		printFiscalReceipt: function (sale, options, successFunction, errorFunction) {
			isAvailable(scope.printer).then(function(printerInfo) {
				const isRefundVoid = fiscalUtils.isRefundVoidSale(sale);
				var documentsToReturn = [];
				var printResponse;
				var documentNumber;
				var printDate;

				const printerMaxDepartments = getMaxDepartment(printerInfo);

				if(_.some(sale.sale_items, (saleItem) => (parseInt(saleItem.department_id) || 0) > printerMaxDepartments)) {
					errorFunction("NOT_ENOUGH_DEPARTMENTS");
					return;
				}

				async.waterfall([
					function(mainDocumentDone) { //Print main document
						var saleXml = saleToReceiptXml(sale, options, printerInfo);
						errorsLogger.debug(saleXml);

						if (!_.isString(saleXml)) {
							switch (saleXml) {
								case -1:
									mainDocumentDone("MISSING_REFERENCE_DOCUMENT");
									break;
								case -2:
									mainDocumentDone("ITEMS_FROM_DIFFERENT_DOCUMENTS");
									break;
								case -3:
									mainDocumentDone("MIXED_REFUND_CAUSES");
									break;
								case -4:
									mainDocumentDone("LOTTERY_FW_UPGRADE_REQUIRED");
									break;
								default:
									mainDocumentDone("ERROR");
									break;
							}
						} else {
							epsonUtils.sendCommands(scope.printer, saleXml).then(function([message, response]) {
								printResponse = response;
								errorsLogger.debug(response);

								mainDocumentDone();
							}, mainDocumentDone);
						}
					}, function(documentRetrievalDone) {
						getDocumentByDateNumber(scope.printer, printerInfo, new Date(), 1, printResponse.fiscalReceiptNumber).then(function(receipt) {
							var documentType = 'commercial_doc';
							printDate = parseReceiptDate(printResponse.fiscalReceiptDate, printResponse.fiscalReceiptTime);
							documentNumber = _.toInteger((_.toInteger(printResponse.zRepNumber) * 10000) + _.toInteger(printResponse.fiscalReceiptNumber));

							if (!documentNumber || _.isNaN(documentNumber)) {
								documentNumber = null;
							}

							if (isRefundVoid) {
								//We only need to check a single sale item, since the cause check has been done in the saleToReceiptXml function
								var saleItemToCheck = _.head(sale.sale_items);
								switch(saleItemToCheck.refund_cause_id) {
									case 6:
										documentType = "void_doc";
										break;
									default:
										documentType = "refund_doc";
								}
							}

							documentsToReturn.push({
								document_type: documentType,
								document_content: receipt
							});							
						}, function (error) {
							errorsLogger.debug("[error] ERROR retrieving fiscal receipt from DGFE!!!");
						}).finally(documentRetrievalDone);
					}, function(eReceiptRetrievalDone) {
						if(supportsEReceipt(printerInfo) && options.e_receipt) {
							let searchString = 'Z' + _.chain(printResponse.zRepNumber).toInteger().padStart(4, '0').value() + '-N' + _.chain(printResponse.fiscalReceiptNumber).toInteger().padStart(4, '0').value() + '-E_RECEIPT.pdf';
							
							searchPrinterWSForFile(scope.printer.ip_address, '/www/dati-rt/e-receipt/', [moment().format("YYYYMMDD"), 'rifiutati', 'da-inviare'], searchString).then(function(path) {
								if(path) {
									fiscalUtils.sendRequest(path, { responseType: 'blob' }).then(function(response) {
										util.blobToDataURL(response.data).then(function(data) {
											documentsToReturn.push({
												document_content: util.dataURLToBase64(data),
												document_type: 'commercial_doc_signed',
												meta: { file_name: 'ricevuta.pdf' },
											});

											eReceiptRetrievalDone();
										});
									}, function(error) {
										eReceiptRetrievalDone();
									});
								} else {
									eReceiptRetrievalDone();
								}
							}, function(error) {
								eReceiptRetrievalDone();
							});
						} else {
							eReceiptRetrievalDone();
						}
					}, function(cardReceiptDone) { // Print additional payment receipt for credits cards
						if (options.tail && options.tail.indexOf('FIRMA') > -1) {
							internalPrintFreeNonFiscal(options.tail, function() {
								errorsLogger.debug("Payment receipt successfully printed");
								cardReceiptDone();
							}, function(error) {
								errorsLogger.debug("Error printing Payment receipt: " + error);
								cardReceiptDone();
							});
						} else {
							cardReceiptDone();
						}
					}, function(ticketChangeDone) { //Print ticket change
						var ticketChangeXml = getTicketChangeXml(sale);
	
						if(ticketChangeXml) {
							epsonUtils.sendCommands(scope.printer, ticketChangeXml).then(function() {
								ticketChangeDone();
							}, function() {
								ticketChangeDone();
							});
						} else {
							ticketChangeDone();
						}
					}, function(queueCouponDone) { //Print queue coupon
						if(!isRefundVoid && checkQueueType('non_fiscal')) {
							epsonUtils.sendCommands(scope.printer, getQueueCouponNonFiscal(sale)).then(function() {
								queueCouponDone();
							}, function() {
								queueCouponDone();
							});
						} else {
							queueCouponDone();
						}
					}, function(qrCodeUrlDone) { //Print QRCode Url
						var printBarcodeId = ((scope.options.print_barcode_id === false) ? false : true) && _.isInteger(sale.id);
						var printQRCode = printBarcodeId && (options.qr_code_url);
	
						if(printQRCode) {
							var qrCodeXml = getQRCodeXml(options.qr_code_url, options.qr_code_message);
	
							epsonUtils.sendCommands(scope.printer, qrCodeXml).then(function() {
								qrCodeUrlDone();
							}, function() {
								qrCodeUrlDone();
							});
						} else {
							qrCodeUrlDone();
						}
					}, function(otherDocumentsDone) { //Print Other documents
						if(_.isArray(options.otherDocuments) && !_.isEmpty(options.otherDocuments)) {
							async.eachSeries(options.otherDocuments, function(document, cb) {
								documentsToReturn.push({
									document_type: 'receipt',
									document_content: _.isArray(document) ? document.join('\n') : document
								});

								internalPrintFreeNonFiscal(document, function() {
									cb();
								}, function(error) {
									cb();
								});
							}, function(err) {
								otherDocumentsDone();
							});
						} else {
							otherDocumentsDone();
						}
					}
				], function(err, res) {
					if(err) {
						errorFunction(err);
					} else {
						_.forEach(documentsToReturn, function(printerDoc) {
							_.assign(printerDoc, {
								sequential_number: documentNumber,
								date: (printDate ? printDate.toISOString() : null),
								printer_serial: printerInfo.printer_serial
							});
						});

						successFunction(documentsToReturn);
					}
				});
			}, errorFunction);
		},

		/**
		 * @desc print an Invoice (also known as 'Fattura', for italians)
		 *
		 * @param sale - the tilby sale you want to print
		 * @param options.can_open_cash_drawer - true if user has permission, false otherwise
		 *
		 * @return successFunction with printed sale or errorFunction with errors
		 */
		printInvoice: function(sale, options, successFunction, errorFunction) {
			isAvailable(scope.printer).then(function(printerInfo) {
				var buildInvoiceDocument = function(printerSerial) {
					getNextInvoiceNumber(scope.printer.ip_address).then(function(invoiceNumber) {
						getDocumentByDateNumber(scope.printer, printerInfo, new Date(), 2, (invoiceNumber - 1)).then(function(invoice) {
							successFunction([{
								sequential_number: (invoiceNumber - 1),
								date: (parseInvoiceDate(invoice) ? parseInvoiceDate(invoice).toISOString() : null),
								document_type: 'invoice',
								printer_serial: printerSerial,
								document_content: invoice
							}]);
	
							// Print payment receipt
							if (options.tail) {
								internalPrintFreeNonFiscal(options.tail, function () {
									errorsLogger.debug("Payment receipt successfully printed");
	
									// Print additional payment receipt for credits cards
									if (options.tail.indexOf('FIRMA') > -1) {
										internalPrintFreeNonFiscal(options.tail, function () {
											errorsLogger.debug("Payment additional for cc receipt successfully printed");
										}, function (error) {
											errorsLogger.debug("Error printing Payment receipt: " + error);
										});
									}
								}, function (error) {
									errorsLogger.debug("Error printing Payment receipt: " + error);
								});
							}
						}, function (error) {
							//errorFunction(error);
							errorsLogger.debug("[error] ERROR retrieving fiscal receipt from DGFE!!!");
							successFunction([]);
						});
					}, errorFunction);
				};

				saleToInvoiceXml(sale, options, printerInfo).then(function(invoiceXml) {
					epsonUtils.sendCommands(scope.printer, invoiceXml).then(function () {
						buildInvoiceDocument(printerInfo.printer_serial);
					}, errorFunction);
				});
			}, errorFunction);
		},

		/**
		 * @desc print a courtesy receipt (also known as 'scontrino di cortesia', for italians)
		 *
		 * @param sale - the tilby sale you want to print
		 *
		 * @return successFunction with printed sale or errorFunction with errors
		 */
		printCourtesyReceipt: function(sale, options, successFunction, errorFunction) {
			var courtesyXml = saleToCourtesyReceiptXml(sale, options);
			isAvailable(scope.printer).then(function() {
				epsonUtils.sendCommands(scope.printer, courtesyXml).then(function([message, response]) {
					successFunction(message);
				}, errorFunction);
			}, errorFunction);
		},

		/**
		 * @desc print a non fiscal receipt (also known as 'preconto', for italians)
		 *
		 * @param sale - the tilby sale you want to print
		 *
		 * @return successFunction with printed sale or errorFunction with errors
		 */
		printNonFiscal: function(sale, options, successFunction, errorFunction) {
			var nonFiscalXml = saleToNonFiscalXml(sale, options);

			isAvailable(scope.printer).then(function() {
				epsonUtils.sendCommands(scope.printer, nonFiscalXml).then(successFunction, errorFunction);
			}, errorFunction);
		},

		/**
		 * @desc open cash drawer
		 *
		 * @return successFunction if drawer is correctly opened or errorFunction with errors
		 */
		openCashDrawer: function(successFunction, errorFunction) {
			var openCashDrawerXml = "<printerCommand><openDrawer/></printerCommand>";
			isAvailable(scope.printer).then(function() {
				epsonUtils.sendCommands(scope.printer, openCashDrawerXml).then(successFunction, errorFunction);
			}, errorFunction);
		},

		/**
		 * Send a text to the printer display
		 * @param {*} printer the target printer
		 * @param {*} textLines an array with the textLines to show
		 */
		displayText: async (printer, textLines) => {
			return new Promise((resolve, reject) => {
				if(_.isArray(textLines)) {
					const dispLineLength = 20;
					let dataToSend = '';
				
					for(let i = 0; i < 2; i++) {
						dataToSend += _.chain(textLines[i]).truncate({ length: dispLineLength, omission: '' }).padEnd(dispLineLength).value();
					}
	
					let displayCommand = `<printerCommand><displayText operator="" data="${dataToSend}" /></printerCommand>`;
					epsonUtils.sendCommands(printer, displayCommand).then(resolve, reject);
				}
			});
		},

		/**
		 * @desc do a daily closing
		 *
		 * @return successFunction  (with the closing receipt in ws mode, or 'OK' in bt mode) if close is correctly done or errorFunction with errors
		 */
		dailyClosing: async function(successFunction, errorFunction) {
			// We use direct/io to do fiscal closing in order to get receipt number and date in response
			const dailyClosingXml = `<printerCommand><directIO command="3001" data="00"/></printerCommand>`;
			const dailyClosingXml2 = `<printerFiscalReport><printZReport timeout="" operator=""/></printerFiscalReport>`;
			const lastClosureCommand = `<printerCommand><directIO command="2052" data=""/></printerCommand>`;
			let result = {};

			try {
				//Check if the printer is available and retrieve info
				const printerInfo = await isAvailable(scope.printer, { ignoreDailyClosingRequest: true });
				result.printer_serial = printerInfo.printer_serial;

				//Obtain fiscal read authorization if necessary
				await getFiscalReadAuthorizazion(scope.printer, printerInfo);

				//Perform daily closing
				await epsonUtils.sendCommands(scope.printer, ((parseFloat(printerInfo?.cpuRel) || 0) >= 5.017 ? dailyClosingXml2 : dailyClosingXml));

				//Obtain last closure document
				const [message, response] = await epsonUtils.sendCommands(scope.printer, lastClosureCommand);
				const lastClosure = response.responseData.substring(14, 18);

				try {
					//Try to obtain last closure XML
					const dailyClosingData = await getAEXml(scope.printer.ip_address, lastClosure);

					if(_.isObject(dailyClosingData)) {
						Object.assign(result, dailyClosingData);
					}
				} catch(err) {
					console.warn(err);
				}

				successFunction(result);
			} catch(err) {
				errorFunction(err);
			}
		},

		getDailyClosing: async function(printer, dailyClosingNumber) {
			const printerInfo = await isAvailable(printer, { ignoreDailyClosingRequest: true });

			const result = {
				printer_serial: printerInfo.printer_serial
			};

			const dailyClosingData = await getAEXml(scope.printer.ip_address, dailyClosingNumber, 1);

			if(typeof dailyClosingData === 'object') {
				Object.assign(result, dailyClosingData);
			}

			return result;
		},

		/**
		 * @desc do a daily read
		 *
		 * @return promise
		 */
		dailyRead: function() {
			var d = $q.defer();
			var dailyReadXml = "<printerFiscalReport><printXReport/></printerFiscalReport>";

			isAvailable(scope.printer).then(function() {
				epsonUtils.sendCommands(scope.printer, dailyReadXml).then(d.resolve, d.reject);
			}, d.reject);

			return d.promise;
		},

		/**
		 * @desc do a cash deposit
		 * @param {number} cash to deposit (euro)
		 * @return promise
		 */
		deposit: function(cash) {
			var d = $q.defer();
			var cashAmount = _.round(cash * 100).toString();
			var cashXml = _.padStart(cashAmount, 9, '0');

			// operator 0
			cashXml = "00" + cashXml;
			var depositXml = "<printerCommand><directIO command=\"1031\" data=\"" + cashXml + "\"/></printerCommand>";

			isAvailable(scope.printer).then(function() {
				epsonUtils.sendCommands(scope.printer, depositXml).then(d.resolve, d.reject);
			}, d.reject);

			return d.promise;
		},

		/**
		 * @desc do a cash withdrawal
		 * @param {number} cash to withdrawal (euro)
		 * @return promise
		 */
		withdrawal: function(cash) {
			var d = $q.defer();
			var cashAmount = _.round(cash * 100).toString();
			var cashXml = _.padStart(cashAmount, 9, '0');

			// operator 0
			cashXml = "00" + cashXml;
			var withdrawalXml = "<printerCommand><directIO command=\"1032\" data=\"" + cashXml + "\"/></printerCommand>";

			isAvailable(scope.printer).then(function() {
				epsonUtils.sendCommands(scope.printer, withdrawalXml).then(d.resolve, d.reject);
			}, d.reject);

			return d.promise;
		},

		/**
		 * @desc readDgfe
		 *
		 * @param mode can be 'print' or 'read' ('read' mode works only in webservice mode)
		 * @param from: start date, must be string in format DDMMAA
		 * @param to: end date, must be string in format DDMMAA
		 *
		 * @return successFunction with dgfe value or 'OK' or errorFunction with errors
		 */
		readDgfeBetween: async function(mode, from, to) {
			const commandName = {
				'print': 'printContentByDate',
				'read': 'queryContentByDate',
			}[mode];

			if (!commandName) {
				throw 'MODE_ERROR';
			}

			const printerInfo = await isAvailable(scope.printer);
			await getFiscalReadAuthorizazion(scope.printer, printerInfo);

			const queryData = {
				dataType: 0,
				fromDay: from.substring(0, 2),
				fromMonth: from.substring(2, 4),
				fromYear: from.substring(4, 6),
				toDay: to.substring(0, 2),
				toMonth: to.substring(2, 4),
				toYear: to.substring(4, 6),
			};

			const requestXml = util.createXMLNode('printerCommand').appendChild(util.createXMLNode(commandName, queryData)).toString();
			const responseXml = await sendRequest(scope.printer.ip_address, requestXml);

			// Return raw response in print mode
			if(mode === 'print') {
				return responseXml;
			}

			//Read mode, extract dgfe from xml
			try {
				const xmlDoc = new DOMParser().parseFromString(responseXml, 'text/xml');
				const lineCountTag = xmlDoc.getElementsByTagName("lineCount")[0];

				if (!lineCountTag) {
					return "RECEIPT_NOT_FOUND";	
				}

				return Array.from(lineCountTag.children).map((el) => el.textContent).join('\n');
			} catch(e) {
				return "PARSE_ERROR";
			}
		},


		/**
		 * @desc printFiscalMemory
		 *
		 * @return promise
		 */
		printFiscalMemory: function() {
			var d = $q.defer();
			var fiscalMemoryXml = "<printerCommand><directIO command=\"3015\" data=\"00\"/></printerCommand>";

			isAvailable(scope.printer).then(function() {
				epsonUtils.sendCommands(scope.printer, fiscalMemoryXml).then(d.resolve, d.reject);
			}, d.reject);

			return d.promise;
		},

		/**
		 * @desc printFiscalMemoryBeetween
		 *
		 * @param corrispettivi: boolean, if true prints 'corrispettivi'
		 * @param from: start date, must be string in format DDMMAA
		 * @param to: end date, must be string in format DDMMAA
		 *
		 * @return promise
		 */
		printFiscalMemoryBetween: function(corrispettivi, from, to) {
			var d = $q.defer();
			var fiscalMemoryXml = "<printerCommand><directIO command=\"3013\" data=\"00" + from + to + "\"/></printerCommand>";

			isAvailable(scope.printer).then(function() {
				epsonUtils.sendCommands(scope.printer, fiscalMemoryXml).then(d.resolve, d.reject);
			}, d.reject);

			return d.promise;
		},
		/**
		 *  @desc automagically configure RCH Print!F
		 *
		 *  ( /printers )
		 *  @param printer, the printer you want to configure
		 *  @param printer.invoice_prefix (from printer)
		 *  @param printer.printer_number (from printer)
		 *
		 *  ( /shop_preferences )
		 *
		 *  @param options.header1 (max 40 chars - receipt + invoice)
		 *  @param options.header2 (max 40 chars - receipt + invoice))
		 *  @param options.header3 (max 40 chars - receipt + invoice))
		 *  @param options.header4 (max 40 chars - receipt + invoice))
		 *  @param options.header5 (max 40 chars - receipt + invoice))
		 *  @param options.header6 (max 40 chars - receipt + invoice))
		 *
		 *  @param options.header7 (max 40 chars - invoice only)
		 *  @param options.header8 (max 40 chars - invoice only)
		 *  @param options.header9 (max 40 chars - invoice only)
		 *  @param options.header10 (max 40 chars - invoice only)
		 *  @param options.header11 (max 40 chars - invoice only)
		 *  @param options.header12 (max 40 chars - invoice only)
		 *  @param options.header13 (max 40 chars - invoice only)
		 *
		 *  @param options.invoice_footer1 (max 40 chars)
		 *  @param options.invoice_footer2 (max 40 chars)
		 *  @param options.invoice_footer3 (not available in epson printers)
		 *  @param options.invoice_footer4 (not available in epson printers)
		 *  @param options.invoice_footer5 (not available in epson printers)
		 *  @param options.invoice_footer6 (not available in epson printers)
		 *
		 *  @param options.display_message (not available)
		 *
		 */
		autoConfigurePrinter: function(printer, options) {
			var d = $q.defer();

			isAvailable(printer).then(function(printerInfo) {
				configurationToCommands(printerInfo, options).then(function(commands) {
					var xmlCommands = [];

					_.forEach(commands, function(cmd) {
						xmlCommands.push("<printerCommand>" + cmd + "</printerCommand>");
					});

					var iocmd = function(command, callback) {
						errorsLogger.debug("Executing command:" + command);

						epsonUtils.sendCommands(printer, command).then(function([message]) {
							callback(null, message);
						}, function(error) {
							if (error === 'EPSON.ERROR_17') {
								callback("FISCAL_CLOSING_NEEDED");
							} else {
								callback(error);
							}
						});
					};

					async.eachSeries(xmlCommands, iocmd, function(err) {
						if(!err) {
							errorsLogger.debug("Configuration done!");
							d.resolve("OK");
						} else {
							d.reject(err);
						}
					});
				});
			}, d.reject);

			return d.promise;
		},

		/**
		 * @description set a logo for the printer
		 * @param {base64} image
		 * @return successFunction("OK") or errorFunction(errors)
		 */
		setLogo: (logoData, printer, locations) => new Promise((successFunction, errorFunction) => {
			isAvailable(printer).then(function() {
				var i;
				var logoNode;
				var root = util.createXMLNode();
				var nfXml = util.createXMLNode("printerNonFiscal");

				root.appendChild(nfXml);

				nfXml.appendChild(util.createXMLNode("beginNonFiscal"));
				if(!_.isNil(logoData)) {
					nfXml.appendChild(util.createXMLNode("setLogo", {
						index: "0",
						option: "2",
						location: "0"
					}));

					nfXml.appendChild(util.createXMLNode("setLogo", {
						index: "0",
						option: "2",
						location: "1"
					}));

					nfXml.appendChild(util.createXMLNode("setLogo", {
						index: "0",
						option: "2",
						location: "2"
					}));

					nfXml.appendChild(util.createXMLNode("setLogo", {
						index: "0",
						option: "2",
						location: "3"
					}));

					nfXml.appendChild(util.createXMLNode("setLogo", {
						index: "0",
						option: "2",
						location: "4"
					}));

					logoNode = util.createXMLNode("setLogo", {
						location: locations[0],
						option: "0",
						index: "1",
						graphicFormat: "R"
					}, logoData);

					nfXml.appendChild(logoNode);

					for(i = 1; i < locations.length; i++) {
						nfXml.appendChild(util.createXMLNode("setLogo", {
							index: "1",
							option: "2",
							location: locations[i]
						}));
					}
				} else {
					for(i = 0; i < locations.length; i++) {
						logoNode = util.createXMLNode("setLogo", {
							index: "0",
							option: "2",
							location: locations[i],
						});
						nfXml.appendChild(logoNode);
					}
				}

				nfXml.appendChild(util.createXMLNode("endNonFiscal"));
				var freeXml =  root.toString();
				epsonUtils.sendCommands(printer, freeXml).then(successFunction, errorFunction);
			}, errorFunction);
		}),

		/**
		 * @description print a logo for the printer
		 * @param {base64} image
		 * @return successFunction("OK") or errorFunction(errors)
		 */
		printLogo: (base64Data, printer) => new Promise((successFunction, errorFunction) => {
			isAvailable(printer).then(function() {
				var root = util.createXMLNode();
				var nfXml = util.createXMLNode("printerNonFiscal");

				root.appendChild(nfXml);

				nfXml.appendChild(util.createXMLNode("beginNonFiscal"));

				if(!_.isNil(base64Data)) {
					var logoNode = util.createXMLNode("printGraphicCoupon", {
						operator: "1",
						graphicFormat: "R"
					}, base64Data);

					nfXml.appendChild(logoNode);
				}

				nfXml.appendChild(util.createXMLNode("endNonFiscal"));
				var freeXml =  root.toString();

				epsonUtils.sendCommands(printer, freeXml).then(successFunction, errorFunction);
			}, errorFunction);
		}),

		/**
		 * @description print a free non fiscal receipt (such as a receipt copy)
		 * @param {array of strings} lines, lines you want to print
		 * @return successFunction("OK") or errorFunction(errors)
		 */
		printFreeNonFiscal: (lines, options) => new Promise((resolve, reject) => {
			let freeXml = linesToCommands(lines, options);

			isAvailable(scope.printer).then(function() {
				epsonUtils.sendCommands(scope.printer, freeXml).then(resolve, reject);
			}, reject);
		}),

		/**
		 * @description printOrder
		 * @param  {object} order you want to fail-over print
		 * @return successFunction("OK") or errorFunction(errors)
		 */
		printOrder: function(order, successFunction, errorFunction) {
			var freeXml = linesToCommands(orderToLines(order));
			isAvailable(scope.printer).then(function() {
				epsonUtils.sendCommands(scope.printer, freeXml).then(successFunction, errorFunction);
			}, errorFunction);
		},

		/**
		 *  isReachable
		 */
		isReachable: function(successFunction, errorFunction) {
			return isAvailable(scope.printer).then(successFunction, errorFunction);
		},

		/**
		 * @description sendFWUpgradeRequest
		 * @param  {object} updateData data to send to the printer
		 * @return promise (nothing on resolve, error_code on reject)
		 */
		sendFWUpgradeRequest: function(printer, updateData) {
			var d = $q.defer();

			var commandsToSend = [
				['9010', '0' + updateData.firmware_url_mot + ' '],
				['9010', '1' + updateData.firmware_url_sig + ' '],
				['9011', [_.padEnd(updateData.operator_fiscalcode, 16, ' '), _.padEnd(updateData.operator_vat, 13, ' '), _.padEnd(updateData.printer_user, 10, ' '), _.padEnd(updateData.printer_password, 10, ' '), '0', _.repeat(' ', 3)].join('')]
			];

			isAvailable(printer).then(function(printerInfo) {
				if(printerInfo.rtDailyOpen === '1') {
					d.reject('FISCAL_CLOSING_NEEDED');
				} else if(_.toNumber(_.get(printerInfo, 'cpuRel')) < 6) {
					d.reject('FIRMWARE_NOT_UPGRADABLE_VIA_OTA');
				} else {
					async.eachSeries(commandsToSend, function(command, cb) {
						var serialCommand = "<printerCommand><directIO command=\"" + command[0] + "\" data=\"" + _.escape(command[1]) + "\"/></printerCommand>";
						
						epsonUtils.sendCommands(printer, serialCommand).then(function() {
							cb(null);
						}, function(error) {
							cb(error);
						});
					}, function(err, results) {
						if(err) {
							d.reject(err);
						} else {
							d.resolve();
						}
					});
				}
			}, d.reject);

			return d.promise;
		},
		/**
		 * @description canUseEReceipt
		 * @param  {object} printer the printer to query
		 * @return promise (boolean result on resolve, error_code on reject)
		 */
		canUseEReceipt: async function(printer) {
			const printerInfo = await isAvailable(printer);

			return supportsEReceipt(printerInfo);
		}
	};
}]);